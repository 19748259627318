import { HHourInterval } from '@shared/src/datatypes/HHourInterval';
import { HDate } from '@shared/src/datatypes/HDate';
import { HTags } from '@shared/src/datatypes/HTags';
import { CapacityTypes } from '@shared/src/enums/CapacityTypes';
import { SlotDto } from './slots/SlotDto';

export class AddressTimetableIntervalDto {

    public onDate: HDate;
    public addressTimetableIntervalId: number;
    public addressDaySettingResourceId: number;
    public addressDaySettingId: number;
    public addressSkin: string;

    public slotCount: number;
    public slotOccupied: number;
    public slotOccupiedByMe: number;
    public slotAvailable: number;
    public slotAvailableByMe: number;
    public interval: HHourInterval = new HHourInterval();
    public intervalDock: HHourInterval = new HHourInterval();
    public intervalWheelbarrowDriver: HHourInterval = new HHourInterval();
    public capacitySlots: boolean;
    public absCapacity: number;
    public minPlates: number;
    public maxPlates: number;
    public capacityType: CapacityTypes;
    public backColor: string;

    public additionalInformation: string;

    public canalterelement: boolean;

    public impossibleInterval: boolean;
    public assignable: boolean;
    public inAccessible: boolean;
    public notRecommendable: boolean;

    public canSwap: boolean;
    public canObtain: boolean;
    public slotIdSwap: number;
    public phaseIdSwap: number;
    public routeKeySwap: string;

    public userTags: HTags;
    public reservedPartnerTags: HTags;
    public reservedPartnerNegativeTags: HTags;
    public reservedRouteTags: HTags;
    public reservedRouteNegativeTags: HTags;
    public incompatibleRouteTags: HTags;
    public incompatibleRouteNegativeTags: HTags;
    public docksEnabled: HTags;
    public routeCharacteristics: HTags;

    public duration: string;

    public slots: SlotDto[];

    public showIntervalDockForklift: boolean;

    public static build(source: AddressTimetableIntervalDto) {
        let result = new AddressTimetableIntervalDto();
        result.copyFrom(source);
        return result;
    }

    public copyFrom(source: AddressTimetableIntervalDto) {
        if (source == null)
            return;
        this.addressTimetableIntervalId = source.addressTimetableIntervalId;
        this.addressDaySettingId = source.addressDaySettingId;
        this.slotCount = source.slotCount;
        this.slotOccupied = source.slotOccupied;
        this.slotOccupiedByMe = source.slotOccupiedByMe;
        this.slotAvailable = source.slotAvailable;
        this.slotAvailableByMe = source.slotAvailableByMe;
        this.interval = source.interval;
        this.intervalDock = source.intervalDock;
        this.intervalWheelbarrowDriver = source.intervalWheelbarrowDriver;
        this.canalterelement = source.canalterelement;
        this.capacitySlots = source.capacitySlots;
        this.absCapacity = source.absCapacity;
        this.minPlates = source.minPlates;
        this.maxPlates = source.maxPlates;
        this.capacityType = source.capacityType;
        this.backColor = source.backColor;

        this.canSwap = source.canSwap;
        this.slotIdSwap = source.slotIdSwap;
        this.phaseIdSwap = source.phaseIdSwap;
        this.routeKeySwap = source.routeKeySwap;
        this.impossibleInterval = source.impossibleInterval;
        this.assignable = source.assignable;
        this.inAccessible = source.inAccessible;
        this.notRecommendable = source.notRecommendable;

        this.userTags = source.userTags;
        this.reservedPartnerTags = source.reservedPartnerTags;
        this.reservedPartnerNegativeTags = source.reservedPartnerNegativeTags;
        this.reservedRouteTags = source.reservedRouteTags;
        this.reservedRouteNegativeTags = source.reservedRouteNegativeTags;
        this.incompatibleRouteTags = source.incompatibleRouteTags;
        this.incompatibleRouteNegativeTags = source.incompatibleRouteNegativeTags;
        this.docksEnabled = source.docksEnabled;
        this.routeCharacteristics = source.routeCharacteristics;

        this.slots = source.slots;

        this.duration = source.duration;
        this.index = source.index;
        this.column = source.column;
        this.width = source.width;
        this.height = source.height;
        this.top = source.top;
        this.left = source.left;
    }

    public index: number;
    public column: number;
    public width: number;
    public height: number;
    public top: number;
    public left: number;

    public resetPosition() {
        this.index = undefined;
        this.column = undefined;
        this.width = undefined;
        this.height = undefined;
        this.top = undefined;
        this.left = undefined;

    }

    public static resetPosition(source: AddressTimetableIntervalDto) {
        source.index = undefined;
        source.column = undefined;
        source.width = undefined;
        source.height = undefined;
        source.top = undefined;
        source.left = undefined;

    }
}
