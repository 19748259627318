<span *ngIf="readonly" class="header column datatype columnName ultraslim value nolabel" name="header">{{
    columnData.columnName
    }}</span>
<input-component *ngIf="!readonly" class="column row datatype  value nolabel" name="columnName"
    [ngClass]="{ 'ultraslim': !inline }" [(ngModel)]="columnData.columnName" [readonly]="readonly"
    (ngModelChange)="valueChangedEvent()">
</input-component>
<enum-selector class="datatype row value dataType" [(ngModel)]="dataType" [readonly]="readonly"
    [enumvalues]="enumDataTypes" name="dataType" (valuechangefunction)="valueChangedDataType($event)"
    [translategroup]="'DATATYPES'" [showclosebutton]="false">
</enum-selector>
<boolean-component class="datatype row" [(ngModel)]="columnData.multiplePuntIComa" [readonly]="readonly"
    name="multiplePuntIComa" [showHelpPoint]=false (ngModelChange)="valueChangedEvent()"></boolean-component>

<input-component class="datatype row order" name="order" [(ngModel)]="columnData.order" [min]="0" type="number"
    [readonly]="readonly" (ngModelChange)="valueChangedEvent()"></input-component>

<div class="datatype accions row">
    <i aria-hidden="true" class="cercle fa fa-times-circle todeleteselect" (click)="onDeleteColumnSelected()"></i>
    <i aria-hidden="true" class="cercle fa fa-times-circle tocanceledevent mr3" (click)="onDeleteColumnCanceled()"></i>
    <i aria-hidden="true" class="cercle fa fa-trash todeleteevent" (click)="onDeleteColumn()"></i>
</div>