import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class SlotChangeReasonEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): SlotChangeReasonEntitySelectorDto {
		let result: SlotChangeReasonEntitySelectorDto = new SlotChangeReasonEntitySelectorDto();
		result.loadData(EntitysSelectors.SlotChangeReason, "", activeFilterKeys, 0, false);
		return result;
	}

	public getUrlGoToMaintenance(): string {
		return "system/dataandparameters/slotchangereason";
	}

	public getUrlBack(): string {
		return "/maintenance/slotchangereason";
	}

}