import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { RouteCardItemDto } from '@shared/src/dtos/route/RouteCardItemDto';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { RouteGroupDto } from '@shared/src/dtos/route/RouteGroupDto';
import { HLong } from '@shared/src/datatypes/HLong';
import { DragDropService } from '@shared/src/services/dragdrop/DragDropService';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { FileTypes } from '@shared/src/enums/FileTypes';
import { TranslateService } from '@ngx-translate/core';


@Component({
	selector: 'shared-route-group-panel-maintenance',
	templateUrl: './shared.route.group.panel.maintenance.component.html',
	styleUrls: ['./shared.route.group.panel.maintenance.component.scss']
})
export class SharedRouteGroupPanelMaintenanceComponent implements OnInit {

	public useIonic: boolean = false;
	public iReadonly: boolean = false;

	public searchText: string = "";
	public filterMetadata: any = {};

	@Output() finish = new EventEmitter<RouteGroupDto>();

	constructor(
		public routeController: RouteController,
		public fb: FormBuilder,
		protected translateService: TranslateService,
		@Inject('HomeController') protected homeController: HomeController,
		@Inject("SecurityController") protected securityController: SecurityController,
		@Inject("AlertService") protected alertService: AlertService) {
		this.securityController.getStoredUserDto().subscribe(data => {
			this.iReadonly = data.isReadOnly;
		});

		if (homeController.useIonic)
			this.useIonic = homeController.useIonic();

	}

	public _routeId: number;
	public get routeId(): number {
		return this._routeId;
	}
	public set routeId(value: number) {
		if (this._routeId === value)
			return;
		this._routeId = value;
	}

	public preFinishActive: boolean;

	public _value: RouteGroupDto;
	@Input()
	public get value(): RouteGroupDto {
		return this._value;
	}
	public set value(value: RouteGroupDto) {
		if (this._value === value)
			return;
		this._value = value;
		this.routeId = value != null && value.routeGroup != null ? value.routeGroup.routeId : 0;
		this.preFinishActive = false;
	}

	ngOnInit(): void {
	}

	getActualRoutes(): Array<RouteCardItemDto> {

		if (this.value == null || this.value.routesGroup == null)
			return new Array();

		return this.value.routesGroup;
	}

	getCountActualRoutes(): number {
		if (this.value == null || this.value.routesGroup == null)
			return 0;

		return this.value.routesGroup.length;
	}

	public onRouteAdd(routeToAdd: RouteCardItemDto) {
		if (this.value == null || routeToAdd == null)
			return;

		if (this.value.routesGroup == null)
			this.value.routesGroup = new Array();

		let exists = false;
		this.value.routesGroup.forEach(element => {
			if (element.routeId == routeToAdd.routeId)
				exists = true;
		});

		if (exists)
			return;

		this.value.routesGroup.push(routeToAdd);

		this.routeController.canRouteBeGrouped(routeToAdd.routeId).subscribe(data => {
			if (!data)
				this.onRouteRemove(routeToAdd);
		});
	}

	onRouteRemove(item: RouteCardItemDto) {
		if (this.preFinishActive)
			return;
		if (item == null)
			return;
		let newlist: Array<RouteCardItemDto> = [];
		this.value.routesGroup.forEach(element => {
			if (element.routeId != item.routeId)
				newlist.push(element);
		});
		this.value.routesGroup = newlist;
	}

	onCancelRouteGrouped() {
		this.preFinishActive = false;
	}
	canOnRouteGrouped(): boolean {
		if (this.value == null || this.value.routesGroup == null)
			return false;

		if (this.value.routeGroup == null && this.value.routesGroup.length < 2)
			return false;

		return true;
	}
	onRouteGrouped() {
		if (!this.canOnRouteGrouped())
			return;

		if (!this.preFinishActive) {
			this.preFinishActive = true;
			return;
		}

		if (this.value.routeGroup != null && !HLong.isNullOrNullLong(this.value.routeGroup.routeId)) {
			this.routeController.routeGroupActualized(this.value).subscribe(data => {
				if (data != null) {
					this.finish.next(data);
					this.preFinishActive = false;
				}
			});
		} else {
			this.routeController.routeGroupCreate(this.value).subscribe(data => {
				if (data != null && data.routeGroup != null) {
					this.finish.next(data);
					this.preFinishActive = false;
				}
			});
		}

	}

	getActionButtonToGroupResource(): string {
		if (this.preFinishActive)
			return 'ROUTEGROUP.OPTION.ROUTEGROUP.TOCONFIRM';

		if (this.value != null && this.value.routeGroup != null && this.value.routeGroup.isGrouped)
			return 'ROUTEGROUP.OPTION.ROUTEGROUP.TOSAVE';

		return 'ROUTEGROUP.OPTION.ROUTEGROUP.TOGROUPED';
	}


	//////////////////////////////// DRAG & DROP ///////////////////////////////////////////

	dragover(event: DragEvent) {
		if (this.preFinishActive)
			return;
		event.preventDefault();
		if (DragDropService.isDraggedObjectHardmanType(event)) {
			event.dataTransfer.dropEffect = 'copy';
		} else {
			var dt = event.dataTransfer;
			if (dt.items) {
				for (var i = 0; i < dt.items.length; i++) {
					if (FileUpDownLoadDto.validateFileTransfer(dt.items[i], this.acceptedTypes)) {
						event.dataTransfer.dropEffect = 'copy';
					}
				}
			} else {
				event.dataTransfer.dropEffect = 'none';
			}
		}
	}

	dropAtZone(event: DragEvent) {
		if (this.preFinishActive)
			return;

		if (DragDropService.isDraggedObjectHardmanType(event)) {
			let draggedobject: any = DragDropService.extractDraggedObjectHardmanType(event);
			if (draggedobject != null) {
				this.onRouteAdd(draggedobject);
				return;
			}
		}
		//Si no, ho tractem com si fos un fitxer    
		this.dropFile(event);

	}

	public acceptedTypes: FileTypes[] = [FileTypes.xlsx, FileTypes.xls, FileTypes.ods, , FileTypes.txt, FileTypes.xml];
	dropFile(event: DragEvent) {
		event.preventDefault();
		// If dropped items aren't files, reject them
		var dt = event.dataTransfer;
		var f = null;
		if (dt.items) {
			// Use DataTransferItemList interface to access the file(s)
			for (var i = 0; i < dt.items.length; i++) {
				if (FileUpDownLoadDto.validateFileTransfer(dt.items[i], this.acceptedTypes)) {
					f = dt.items[i].getAsFile();
					if (f != null) {
						FileUpDownLoadDto.buildByFile(f, FileUpDownLoadDto.getFileType(dt.items[i].type)).then(fileDTO => {
							if (fileDTO != null) {
								this.loadFile(fileDTO);
							}
						});
					}
				}
			}
		} else {
			// Use DataTransfer interface to access the file(s)
			for (var j = 0; j < dt.files.length; j++) {
				if (FileUpDownLoadDto.validateFileTransfer(dt.items[j], this.acceptedTypes)) {
					f = dt.items[j];
					if (f != null) {
						FileUpDownLoadDto.buildByFile(f, FileUpDownLoadDto.getFileType(dt.items[j].type)).then(fileDTO => {
							if (fileDTO != null) {
								this.loadFile(fileDTO);
							}
						});
					}
				}
			}
		}
	}

	public loadFile(f: FileUpDownLoadDto) {
		if (!FileUpDownLoadDto.validateSizeFile(f)) {
			this.showMessageMaxSize();
			return;
		}

		this.routeController.loadChildsRouteGroupDTOByFile(f).subscribe(data => {
			this.value = data;
		});

	}

	public showMessageMaxSize() {

		this.translateService.get("FILEUPDOWNLOAD.MAXSIZE.MESSAGE", { value: FileUpDownLoadDto.MAXSIZEFILE }).subscribe(data => {
			this.alertService.error(data);
		});

	}

}
