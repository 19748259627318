import { Inject, Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { BaseController } from '@shared/src/controllers/base.controller';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Observable } from 'rxjs';
import { FilterTruckingShipmentPackageDto } from '@shared/src/dtos/trucking/FilterTruckingShipmentPackageDto';
import { TruckingShipmentPackageCtrlDto } from '@shared/src/dtos/trucking/TruckingShipmentPackageCtrlDto';
import { FilterTruckingRouteDto } from '@shared/src/dtos/trucking/FilterTruckingRouteDto';
import { TruckingRouteCtrlDto } from '@shared/src/dtos/trucking/TruckingRouteCtrlDto';
import { TruckingShipmentExpandDto } from '@shared/src/dtos/trucking/TruckingShipmentExpandDto';
import { TruckingRouteExpandDto } from '@shared/src/dtos/trucking/TruckingRouteExpandDto';
import { TruckingRouteDto } from '@shared/src/dtos/trucking/TruckingRouteDto';
import { TruckingShipmentPackageDto } from '@shared/src/dtos/trucking/TruckingShipmentPackageDto';

@Injectable({
    providedIn: 'root',
})
export class TruckingController extends BaseController {
    private base = '/trucking'

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router);
    }

    public refreshShipmentList$: EventEmitter<boolean> = new EventEmitter();
    public shipmentPackageChanged$: EventEmitter<TruckingShipmentPackageDto> = new EventEmitter();
    public routeChanged$: EventEmitter<number> = new EventEmitter();

    public getTruckingShipments(filter: FilterTruckingShipmentPackageDto): Observable<TruckingShipmentPackageCtrlDto> {
        return this.post(this.base + "/shipments", filter, "getTruckingShipments");
    }

    public getTruckingPackages(filter: FilterTruckingShipmentPackageDto): Observable<TruckingShipmentPackageCtrlDto> {
        return this.post(this.base + "/packages", filter, "getTruckingPackages");
    }

    public getTruckingRoutes(filter: FilterTruckingRouteDto): Observable<TruckingRouteCtrlDto> {
        return this.post(this.base + "/routes", filter, "getTruckingRoutes");
    }

    public getTruckingShipmentsExactKey(filter: FilterTruckingShipmentPackageDto): Observable<TruckingShipmentPackageCtrlDto> {
        return this.post(this.base + "/shipments/exactkey", filter, "getTruckingShipmentsExactKey");
    }
    
    public getTruckingPackagesExactKey(filter: FilterTruckingShipmentPackageDto): Observable<TruckingShipmentPackageCtrlDto> {
        return this.post(this.base + "/packages/exactkey", filter, "getTruckingPackagesExactKey");
    }

    public getTruckingRoutesExactKey(filter: FilterTruckingRouteDto): Observable<TruckingRouteCtrlDto> {
        return this.post(this.base + "/routes/exactkey", filter, "getTruckingRoutes");
    }

    public getTruckingShipmentExpand(shipmentId: number, filterKeys: String[]): Observable<TruckingShipmentExpandDto> {
        return this.post(this.base + "/shipment/" + shipmentId, filterKeys, "getTruckingRoutes");
    }
    
    public truckingShipment(shipmentId: number): Observable<boolean> {
        return this.post(this.base + "/shipment/trucking", shipmentId, "truckingShipment");
    }

    public getTruckingRouteExpand(routeId: number): Observable<TruckingRouteExpandDto> {
        return this.get(this.base + "/route/" + routeId);
    }
    
    public createEmptyRoute(): Observable<TruckingRouteDto> {
        return this.get(this.base + "/createemptyroute");
    }

    public brokenRoute(routeId: number): Observable<TruckingRouteDto> {
        return this.get(this.base + "/route/" + routeId + "/broken");
    }

    public addPackageInRoute(routeId: number, packageId: number): Observable<boolean> {
        return this.post(this.base + "/route/" + routeId + "/addpackage", packageId, "addPackageInRoute");
    }

    public removePackageInRoute(routeId: number, packageId: number): Observable<boolean> {
        return this.post(this.base + "/route/" + routeId + "/removepackage", packageId, "removePackageInRoute");
    }

    public addShipmentInRoute(routeId: number, shipmentId: number): Observable<boolean> {
        return this.post(this.base + "/route/" + routeId + "/addshipment", shipmentId, "addShipmentInRoute");
    }
}
