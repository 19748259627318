import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PhaseController } from '@shared/src/controllers/route/phase/phase.controller';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { PhaseSlotManageDto } from '@shared/src/dtos/phase/slot/manage/PhaseSlotManageDto';
import { DateFilter, GeneralStatus, HDate, HHour, HHourInterval, HTranslateService, PhaseCardDto } from '@shared/src/public-api';

@Component({
  selector: 'shared-phase-slot-manage-info-component',
  templateUrl: './shared.phase.slot.manage.info.component.html',
  styleUrls: ['./shared.phase.slot.manage.info.component.scss'],
})
export class SharedPhaseSlotManageInfoComponent implements OnInit {

  constructor(public phaseController: PhaseController, private translateService: HTranslateService) {
  }

  @Input() public readonly: boolean = false;

  public actions: ActionsStripDto;

  public modificarReserva: boolean = false;

  public mostrar24h: boolean = false;

  public objTranslate: any;


  private _value: PhaseSlotManageDto;
  @Input() set value(value: PhaseSlotManageDto) {
    if (this._value === value)
      return;
    this._value = value;
    this.welcome();

  }
  get value(): PhaseSlotManageDto {
    return this._value;
  }

  loadData() {


  }

  ngOnInit() {

  }

  isCanceled(): boolean{
      if(this.value == null || this.value.phase == null)
        return true;
  
      return GeneralStatus.canceled == this.value.phase.generalStatus || GeneralStatus.obsolete == this.value.phase.generalStatus;
    }

  onClickModify() {
    this.modificarReserva = !this.modificarReserva;
  }

  welcome() {

    if (this.value && this.value.actionsSlot != null)
      this.actions = ActionsStripDto.buildFromActionsStripDto(this.value.actionsSlot);
    else
      this.actions = null;

    if (this.value && this.value.realmentOcupaSlot) {
      this.objTranslate = {};
      this.objTranslate.weekDay = this.translateService.instant(HDate.getDayOfWeek(this.value.phaseSlot.onDateSlot));
      this.objTranslate.date = DateFilter.getTransform(this.value.phaseSlot.onDateSlot);
      this.objTranslate.lapseTime = HHourInterval.toScreenString(this.value.phaseSlot.intervalSlot);

      this.objTranslate.dateOperant = HHour.getHourString(HHourInterval.getStarts(this.value.phaseSlot.intervalSlot));
      this.objTranslate.hourOperant = HHour.getHourString(HHourInterval.getEnds(this.value.phaseSlot.intervalSlot));
    }
  }

  onClickAction(action: ActionDto) {
    if (action == null)
      return;

    if (action.actionId == "CANCELSLOT") {

    }
  }


  @Output()
  slotChanged: EventEmitter<any> = new EventEmitter();
  onSlotChanged() {
    this.onClickModify();
    this.slotChanged.next();
  }

}
