import { Component, Input } from '@angular/core';
import { TotemDto } from '@shared/src/controllers/totem/TotemDto';
import { TotemRouteDto } from '@shared/src/controllers/totem/TotemRouteDto';
import { HColor, HomeController } from '@shared/src/public-api';

@Component({
    selector: 'shared-totem-route',
    templateUrl: './shared.totem.route.component.html',
    styleUrls: ['./shared.totem.route.component.scss']
})
export class SharedTotemRouteComponent {

    constructor(protected homeController: HomeController) {
    }

    @Input()
    value: TotemRouteDto;

    @Input()
    public totem: TotemDto;

    ngAfterViewInit() {
    }

    getTextColor(data: HColor) {
        if (data != null)
            return HColor.getWhiteBlackFromColor(data.web);

        return HColor.getWhiteBlackFromColor(HColor.defaultColor().web);
    }
    getBackColor(data: HColor) {
        return HColor.getColorOrDefaultColor(data, HColor.defaultColor().web);
    }
    getColor(text: string) {
        return HColor.getWhiteBlackFromColor(HColor.getColorFromStringTable(text, this.homeController));
    }
    getBackgroundColor(text: string) {
        return HColor.getColorFromStringTable(text, this.homeController);
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}


