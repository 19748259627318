<!--directiva: shared.simpletable.component.html!-->
<div class="grup" *ngIf="_internalValue">
    <table class="hardman table">
        <tbody>
            <tr *ngIf="showTitle">
                <th [colSpan]="_internalValue.columnNumber + 1" class="table-component-title">{{_internalValue.caption |
                    translate}}</th>
            </tr>
            <tr class="header">
                <th></th>
                <th *ngFor="let column of _internalValue.columns | sortBy:'columnId'; let i = index"
                    [ngClass]="getClassColumn(i)">
                    {{column.caption
                    | translate}}</th>
            </tr>
            <tr *ngFor="let row of _internalValue.rows | filter:searchText  | sortBy:'rowId'; let i = index"
                [ngClass]="row.classes" (click)="onClickRow(row)">
                <th class="th-caption">{{row.caption | translate}}</th>
                <td *ngFor="let value of row.values | sortBy:'columnId'; let j = index" [ngClass]="getClassColumn(j)">
                    <span *ngIf="isTime(j, value)">{{value.valueTime | time}}</span>
                    <span *ngIf="isDouble(j, value)">{{value.valueDouble | price}}</span>
                    <span *ngIf="isBoolean(j, value)">{{value.valueBoolean}}</span>
                    <span *ngIf="isInteger(j, value)">{{value.valueInt | enter}}</span>
                    <span *ngIf="isString(j, value)">{{value.valueString}}</span>
                    <span *ngIf="isHDate(j, value)">{{value.valueHDate | date}}</span>
                    <span *ngIf="isHHour(j, value)">{{value.valueHHour | hour}}</span>
                    <span *ngIf="isHDateHour(j, value)">{{value.valueHDateHour | datehournosecondsnotz}}</span>
                    <span *ngIf="isHHourInterval(j, value)">{{value.valueHHourInterval | interval}}</span>
                    <shared-tags-strip-component *ngIf="isHTags(j, value)"
                        [tags]="value.valueHTags"></shared-tags-strip-component>
                </td>
            </tr>

        </tbody>
    </table>
</div>