<ng-container *ngIf="value">
 
    <actions class="bar baractions" *ngIf="!readonly" [actionstrip]="actions" (clickAction)="onClickAction($event)"></actions>

    <div class="block" *ngIf="!modificarReserva">
        <div class="title">{{'SHARED.PHASESLOTMANAGE.INFO.RESERVA.CAPTION' | translate}}</div>
        <div class="info-slot">
            <div *ngIf="!value.realmentOcupaSlot">{{'ROUTEITEMCOMPONENT.NOSLOT.LABEL' | translate: objTranslate }}</div>
            <div *ngIf="value.realmentOcupaSlot">{{'SHARED.PHASESLOTMANAGE.INFO.RESERVEMESSAGE.CAPTION' | translate: objTranslate }}</div>
            <div *ngIf="value.realmentOcupaSlot && value.mostrarInfoSlot24h" class="negreta">{{'SHARED.PHASESLOTMANAGE.INFO.RESERVEMESSAGE.SUBCAPTION' | translate: objTranslate }}</div>
        

            <button-link *ngIf="!readonly && value.phase && value.phase.requiresSlot && !value.phase.isSlotBlocked && !isCanceled()"
                class="line link" (click)="onClickModify()">{{ 'SHARED.ACTIVE.SLOTS.ITEM.PHASESLOT.FORWARD.CAPTION' | translate}}</button-link>
        </div>

        <div class="title">{{'SHARED.PHASESLOTMANAGE.INFO.RULES.CAPTION' | translate}}</div>
        <div [innerHTML]="value.rules"></div>
    </div>

    <div class="block" *ngIf="modificarReserva">
        <div class="btn-close close" (click)="onClickModify()"><i class="fa fa-arrow-left" aria-hidden="true"></i></div>
        <shared-slot-picker *ngIf="value.phase && value.phase.requiresSlot" class="relative activeslots"
        [phase]="value.phase" [canviSlot]="true" (valueChange)="onSlotChanged()" (callBack)="onSlotChanged()"></shared-slot-picker>
    </div>

</ng-container>