<ng-container *ngIf="totem">
    <div class="page-title">
        {{ 'TOTEMPAGE.EXIT.TITLE' | translateIn:totem.language | async}}
    </div>
    <div class="column-container">
        <div class="column-central">
            <div *ngIf="totem.step==totemSteps.exitBloked" class="block-exit">
                <div class="reason" [innerHTML]="reasonHtml | safe: 'html'"></div>
                <div class="text showBlockedExitSystem">
                    {{'TOTEMPAGE.EXIT.EXITBLOKED.CAPTION'| translateIn:totem.language | async}}
                    <span class="block-exit-subtitle"
                        *ngIf="totem.identificationSelected != null && totem.identificationSelected.isExitBlocked">
                        {{totem.identificationSelected.exitBlockedReason | translateIn:totem.language | async}}
                    </span>
                </div>
            </div>
            <div *ngIf="totem.step==totemSteps.exitGate" class="block-exit">
                <div class="text goto">
                    {{ 'TOTEMPAGE.EXIT.EXITGATE.CAPTION' |
                    translateInParams: {
                    langCode: totem.language,
                    params: { gates: getGatesToExit() }
                    } | async
                    }}
                </div>
            </div>
            <div *ngIf="totem.step==totemSteps.exitGoOut" class="block-exit">
                <div class="text goto">
                    {{'TOTEMPAGE.EXIT.EXITGOOUT.CAPTION'| translateIn:totem.language | async}}
                </div>
            </div>

        </div>
        <div class="column-dreta">
            <div class="confirmacio-ruta">
                <button *ngIf="totem.step!=totemSteps.exitBloked" class="button-action" (click)="buttonOk()">
                    <i aria-hidden="true" class="fa fa-check-circle"></i>
                    <div class="code">{{ getButtonActionCode() | translateIn:totem.language | async}}
                    </div>
                    <div class="name">{{ getButtonActionName() | translateIn:totem.language | async}}
                    </div>
                </button>
                <button
                    *ngIf="totem.step==totemSteps.exitBloked && totem.identificationSelected != null && !totem.identificationSelected.isSignatureBlocked"
                    class="button-action alternate" (click)="buttonGoToSignature()">
                    <i aria-hidden="true" class="fa fa-check-circle"></i>
                    <div class="code">{{ 'TOTEMPAGE.EXIT.EXITBLOKED.SIGNATURE.BUTTON.CODE' | translateIn:totem.language
                        | async}}
                    </div>
                    <div class="name">{{ 'TOTEMPAGE.EXIT.EXITBLOKED.SIGNATURE.BUTTON.NAME' | translateIn:totem.language
                        | async}}
                    </div>
                </button>
            </div>
        </div>
    </div>
</ng-container>