<input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text [(ngModel)]="searchText">

<div class="container-table grup" [ngClass]="{'ionic' : useIonic}">
    <div class="contingut">
        <table class="hardman" style="width:100%">
            <tr class="dark">
                <th class="header id">ID</th>
                <th class="header hhourinterval">{{'PHASESLOTLOG.INTERVAL' | translate}}</th>
                <th class="header desperate">{{'PHASESLOTLOG.DESPERATE' | translate}}</th>
                <th class="header day">{{'PHASESLOTLOG.DAY' | translate}}</th>
                <th class="header datehour">{{'PHASESLOTLOG.MOMENT' | translate}}</th>
            </tr>
            <tr *ngFor="let item of logs | filter:searchText | sortBy:'-modifiedAt' | slice:0:sliceCount"
                [ngClass]="item.action">
                <td class="id">{{ item.phaseId }}</td>
                <td class="datehour">{{ item.intervalSlot | hourinterval}}</td>
                <td class="desperate">{{'PHASESLOTLOG.DESPERATERESULT' | translate: { desperateSlot: item.desperateSlot } }}</td>
                <td class="day">{{ item.daySlot | date}}</td>
                <td class="datehour"><datehour-viewer [value]="item.momentUTC" [showOriginal]="false" [showTimeZone]="false">
                </datehour-viewer></td>
            </tr>
            <button class="slice-button" (click)="onSliceMore()" *ngIf="sliceCount<itemsCount">
                {{'GENERAL.SLICE.BUTTON' | translate:{ sliceCount: sliceCount, itemsCount:itemsCount} }}
            </button>
        </table>
    </div>
</div>