export enum EntitysSelectors {
	AccessControl = "AccessControl",
	Address = "Address",
	Ambit = "Ambit",
	Booking = "Booking",
	Device = "Device",
	Form = "Form",
	Festives = "Festives",
	Gate = "Gate",
	Question = "Question",
	LoadConsole = "LoadConsole",
	ManagementModel = "ManagementModel",
	MaritimeAgency = "MaritimeAgency",
	PermissionGroup = "PermissionGroup",
	Route = "Route",
	Scope = "Scope",
	Shipment = "Shipment",
	Panel = "Panel",
	PanelColumn = "PanelColumn",
	Partner = "Partner",
	Phase = "Phase",
	PhaseByAddress = "PhaseByAddress",
	PortAddress = "PortAddress",
	User = "User",
	Plate = "Plate",
	PlateTypology = "PlateTypology",
	RoleTypes = "RoleTypes",
	TotemSetting = "TotemSetting",
	Typology = "Typology",
	Package = "Package",
	ShipmentDeliveryLine = "ShipmentDeliveryLine",
	SlotChangeReason = "SlotChangeReason",
	None = "None"
}