import { HTags } from "@shared/src/datatypes/HTags";
import { HTime } from "@shared/src/datatypes/HTime";
import { HDate, HHour, HHourInterval } from "@shared/src/public-api";

export class SimpleTableDto {

    public caption: string;
    public columns: Map<String, SimpleTableColumnDto>;
    public rows: Map<String, SimpleTableRowDto>;
}

export class SimpleTableColumnDto {
    public columnId: string;
    public caption: string;
    public typeName: string;
    public defaultValueTime: HTime;
    public defaultValueDouble: number;
    public defaultValueInteger: number;
    public defaultHDate: HDate;
    public defaultHHour: HHour;
    public defaultHHourInterval: HHourInterval;
    public defaultHTags: HTags;

    public resalta: boolean;
    public divideix: boolean;
    public noGraph: boolean;
    public graphGroupId: string;
    public classStyles: string;
}

export class SimpleTableRowDto {
    public rowId: string;
    public caption: string;
    public classes: string;
    public routeId: number;
    public routeKey: string;
    public phaseId: number;
    public values: Map<String, SimpleTableValueDto>;
}

export class SimpleTableValueDto {
    public columnId: string;
    public valueTime: HTime;
    public valueInt: number;
    public valueString: string;
    public valueDouble: number;
    public valueHDate: HDate;
    public valueHHour: HHour;
    public valueHHourInterval: HHourInterval;
    public valueHTags: HTags;
    public isExplicitType: boolean = false;
    public explicitTypeName: string = "";
    public classStyles: string;
}


export class SimpleTableRowFrontDto {
    public caption: string;
    public values: SimpleTableValueDto[];
    public resalta: boolean;
    public divideix: boolean;
    public noGraph: boolean;
    public graphGroupId: string;
    public classes: string;

    public routeId: number;
    public routeKey: string;
    public phaseId: number;
    public rowId: string;

    public static build(value: SimpleTableRowDto): SimpleTableRowFrontDto {
        let result = new SimpleTableRowFrontDto();
        result.caption = value.caption;
        result.values = new Array();
        result.classes = value.classes;
        result.routeId = value.routeId;
        result.routeKey = value.routeKey;
        result.phaseId = value.phaseId;
        result.rowId = value.rowId;

        for (let key in value.values)
            result.values.push(value.values[key]);

        return result;
    }
    public static buildAndFilter(value: SimpleTableRowDto, precolumns: SimpleTableColumnDto[], graphGroupId: string): SimpleTableRowFrontDto {
        let result = new SimpleTableRowFrontDto();
        result.caption = value.caption;
        result.values = new Array();
        result.classes = value.classes;
        result.routeId = value.routeId;
        result.routeKey = value.routeKey;
        result.phaseId = value.phaseId;

        let i = 0;
        for (let key in value.values) {
            let column: SimpleTableColumnDto = precolumns[i];
            if (column.graphGroupId == graphGroupId)
                result.values.push(value.values[key]);
            i++;
        }

        return result;
    }
}

export class SimpleTableFrontDto {

    public caption: string;
    public classes: string;
    public columns: SimpleTableColumnDto[];
    public rows: SimpleTableRowFrontDto[];
    public columnNumber: number;

    public static build(value: SimpleTableDto): SimpleTableFrontDto {

        if (value == null)
            return null;

        let result = new SimpleTableFrontDto();
        result.caption = value.caption;
        result.columns = new Array();
        result.columnNumber = 0;

        result.rows = new Array();

        for (let key in value.columns) {
            result.columns.push(value.columns[key]);
            result.columnNumber++;
        }

        for (let key in value.rows)
            result.rows.push(SimpleTableRowFrontDto.build(value.rows[key]));

        return result;
    }


    public static buildAndFilter(value: SimpleTableDto, graphGroupId: string): SimpleTableFrontDto {

        if (value == null)
            return null;

        if (graphGroupId == null || graphGroupId == "")
            return SimpleTableFrontDto.build(value);

        let result = new SimpleTableFrontDto();
        result.caption = value.caption;
        result.columns = new Array();
        result.columnNumber = 0;

        result.rows = new Array();

        let precolumns = new Array();
        for (let key in value.columns) {
            let column: SimpleTableColumnDto = value.columns[key];

            precolumns.push(column);
            if (column.graphGroupId == graphGroupId) {
                result.columns.push(column);
                result.columnNumber++;
            }
        }

        for (let key in value.rows)
            result.rows.push(SimpleTableRowFrontDto.buildAndFilter(value.rows[key], precolumns, graphGroupId));

        return result;
    }

    public static transpose(value: SimpleTableFrontDto): SimpleTableFrontDto {

        if (value == null)
            return null;

        let result = new SimpleTableFrontDto();


        result.caption = value.caption;
        result.columns = new Array();
        result.columnNumber = 0;
        result.rows = new Array();
        //primer creem les files que son les columnes
        value.columns.forEach((r) => {
            let row = new SimpleTableRowFrontDto();
            row.caption = r.caption;
            row.classes = "";
            row.values = new Array();
            result.rows.push(row);
        });
        //Repasem els rows que son els valors de les noves columnes
        value.rows.forEach((r, i) => {

            let col = new SimpleTableColumnDto();
            col.caption = r.caption;
            col.columnId = i + "";
            result.columns.push(col);

            r.values.forEach((k, i) => {
                let nk = new SimpleTableValueDto();
                nk.columnId = i + "";
                nk.explicitTypeName = k.explicitTypeName;
                nk.isExplicitType = k.isExplicitType;
                nk.valueDouble = k.valueDouble;
                nk.valueInt = k.valueInt;
                nk.valueString = k.valueString;
                nk.valueTime = k.valueTime;
                nk.valueHDate = k.valueHDate;
                nk.valueHHourInterval = k.valueHHourInterval;
                nk.valueHHour = k.valueHHour;
                nk.valueHTags = k.valueHTags;
                nk.classStyles = k.classStyles;
                result.rows[i].values.push(nk);
            });
        });
        //Això és un mal menor. Tot ha de ser del mateix tipus
        result.columns.forEach((r) => {
            if (value.columns[0]) {
                r.divideix = value.columns[0].divideix;
                r.graphGroupId = value.columns[0].graphGroupId;
                r.noGraph = value.columns[0].noGraph;
                r.resalta = value.columns[0].resalta;
                r.typeName = value.columns[0].typeName;
            }
        });
        result.columnNumber = result.columns.length;

        return result;
    }



}