import { Component, EventEmitter, Inject, OnInit, Output, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { TruckingRouteDto } from '@shared/src/dtos/trucking/TruckingRouteDto';
import { TruckingRouteExpandDto } from '@shared/src/dtos/trucking/TruckingRouteExpandDto';
import { TruckingController } from '@shared/src/controllers/trucking/trucking.controller';
import { TruckingShipmentPackageDto } from '@shared/src/dtos/trucking/TruckingShipmentPackageDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { ChatController } from '@shared/src/public-api';


@Component({
	selector: 'shared-trucking-route-panel-load',
	templateUrl: './shared.trucking.route.panel.load.component.html',
	styleUrls: ['./shared.trucking.route.panel.load.component.scss']
})
export class SharedTruckingRoutePanelLoadComponent implements OnInit {

	public useIonic: boolean = false;
	public iReadonly: boolean = false;

	public searchTextGroup: string = "";
	public filterMetadata: any = {};


	@Output() clickBack = new EventEmitter<boolean>();
	@Output() updateRoute = new EventEmitter<TruckingRouteDto>();

	constructor(
		public truckingController: TruckingController,
		public chatController: ChatController,
		public fb: FormBuilder,
		@Inject('HomeController') protected homeController: HomeController,
		@Inject("SecurityController") protected securityController: SecurityController,
		@Inject("AlertService") protected alertService: AlertService) {
		this.securityController.getStoredUserDto().subscribe(data => {
			this.iReadonly = data.isReadOnly;
		});

		if (homeController.useIonic)
			this.useIonic = homeController.useIonic();

	}


	ngOnInit(): void {
		this.options = this.buildOptions();
	}

	public _selectedRouteExpand: TruckingRouteExpandDto;
	@Input()
	public get selectedRouteExpand(): TruckingRouteExpandDto {
		return this._selectedRouteExpand;
	}
	public set selectedRouteExpand(value: TruckingRouteExpandDto) {
		if (this._selectedRouteExpand === value)
			return;
		this._selectedRouteExpand = value;
	}

	public options: OptionsStripDto;
	public actualOptionId: string = "PACKAGE";
	public buildOptions(): OptionsStripDto {
		let result = new OptionsStripDto();
		result.add(OptionDto.build("PACKAGE", "TRUCKING.ROUTE.OPCIONS.PACKAGE.CAPTION", "TRUCKING.ROUTE.OPCIONS.PACKAGE.HELP", "", 1, -1, 1, true, false));
		result.add(OptionDto.build("DELIVERY", "TRUCKING.ROUTE.OPCIONS.DELIVERY.CAPTION", "TRUCKING.ROUTE.OPCIONS.DELIVERY.HELP", "", 1, -1, 1, true, false));
		result.add(OptionDto.build("SHIPMENT", "TRUCKING.ROUTE.OPCIONS.SHIPMENT.CAPTION", "TRUCKING.ROUTE.OPCIONS.SHIPMENT.HELP", "", 1, -1, 1, true, false));
		this.actualOptionId = result.getActualSelected().optionId;
		return result;
	}

	onClickOption(option: OptionDto) {
		this.actualOptionId = option.optionId;
		
	}

	public selectedPackage: TruckingShipmentPackageDto;
    onClickPackage(event, item: TruckingShipmentPackageDto){
        if (event && event.stopPropagation)
			event.stopPropagation();

        this.selectedPackage = item;
    }

	onRefreshShipmentList(value: boolean){
        if(value){
			this.truckingController.refreshShipmentList$.next(true);
        }
    }
}
