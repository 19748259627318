import { Component, ElementRef, EventEmitter, Inject, Input, Output, AfterViewInit, HostListener } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenDocumentController } from '@shared/src/controllers/open/open.document.controller';
import { OpenRouteCMRController } from '@shared/src/controllers/open/openroutecmr.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { DocumentViewerHistoricDto } from '@shared/src/dtos/document/struct/DocumentViewerHistoricDto';
import { DocumentViewerTypeDto } from '@shared/src/dtos/document/struct/DocumentViewerTypeDto';
import { DocumentParentObjectDto } from '@shared/src/dtos/documentaryreview/DocumentParentObjectDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { OpenDocumentDetail } from '@shared/src/dtos/open/OpenDocumentDetail';
import { DocumentParentTypes } from '@shared/src/enums/DocumentParentTypes';
import { RouteDocumentTypes } from '@shared/src/enums/RouteDocumentTypes';
import { HDateHour, SharedAsideFactory } from '@shared/src/public-api';

@Component({
  selector: 'shared-route-part-cmr-digitaldocument-component',
  templateUrl: './shared.route.part.cmr.digitaldocument.component.html',
  styleUrls: ['./shared.route.part.cmr.digitaldocument.component.scss'],
})
export class SharedRoutePartCMRDigitalDocumentComponent implements AfterViewInit {
  constructor(private openRouteCMRController: OpenRouteCMRController,
    private openDocumentController: OpenDocumentController,
    @Inject('HomeController') protected homeController: HomeController,
    @Inject('SharedAsideFactory') protected sharedAsideFactory: SharedAsideFactory,
    private el: ElementRef) {
    if (homeController.useIonic) this.useIonic = homeController.useIonic();
  };

  ngOnInit() {

  }

  public useIonic: boolean = false;

  @Input()
  public showsignatures: boolean = true;

  @Input()
  public readonly: boolean = false;

  @Input()
  public avoidOptionFirma: boolean = false;


  public __showSignature: boolean = true;
  @Input()
  public set showSignature(value: boolean) {
    if (this.__showSignature === value)
      return;
    this.__showSignature = value;
    this.wellcomeDocumentDetails(this.documentDetails);
  }
  public get showSignature(): boolean {
    return this.__showSignature;
  }


  private _routePartCMRId: number;
  @Input()
  public set routePartCMRId(value: number) {
    if (this._routePartCMRId === value)
      return;
    this._routePartCMRId = value;
    this.wellComeCMRId();
  }
  public get routePartCMRId(): number {
    return this._routePartCMRId;
  }

  public wellComeCMRId() {
    this.loadParentObject();
    if(!HLong.isNullOrNullLong(this.routePartCMRId)){
      this.openRouteCMRController.getRoutePartCMR(this.routePartCMRId).subscribe(data => {
        this.isIncompletDocument = data != null ? data.isIncompletDocument : false;
      });
    }
  }
  public isIncompletDocument: boolean;

  public parentObject: DocumentParentObjectDto;
  loadParentObject(){
    this.parentObject = DocumentParentObjectDto.build(DocumentParentTypes.RoutePartCMR, 0, 0, this.routePartCMRId, 0);
    this.loadDocuments();
  }

  //////////////////////  MULTI DOCUMENTS ////////////////////

  public documents: Array<DocumentViewerTypeDto>;
  public multidocument: boolean;
  
  public CMRAsDocument: boolean;
  loadDocuments(){
    if(this.parentObject == null){
      this.documents = null;
      this.multidocument = false;
    }else{
        this.openDocumentController.getAllDocumentsByParentObject(this.parentObject).subscribe(data => {
          this.documents = data;
          this.wellComeDocuments();
      });
    }
  }

  public addOptionMultiFirma: boolean;
  public optionsMultiDocument: OptionsStripDto = new OptionsStripDto();
  public wellComeDocuments() {

    this.optionsMultiDocument = new OptionsStripDto();
 
    this.CMRAsDocument = false;
    //hem de comprovar si dins d'aquesta llista hi ha document de CMR o no
    if(this.documents != null && this.documents.length > 0){
      this.documents.forEach(x => {
        if(x.documentType == RouteDocumentTypes.CMR)
          this.CMRAsDocument = true;
      });
    }

    this.multidocument = this.documents != null && (this.documents.length > 1 || (!this.CMRAsDocument && this.documents.length > 0));
    
    let ampladaComponent = this.getAmpladaComponent();

    let visualitzarMenuBarra = 1;
    if (this.documents != null && ((this.documents.length > 3 && ampladaComponent < 400) || (this.documents.length > 5 && ampladaComponent < 600) || (this.documents.length > 6 && ampladaComponent < 700)))
      visualitzarMenuBarra = -1;

    this.addOptionMultiFirma = this.showSignature && this.multidocument && !this.avoidOptionFirma;
    if (this.addOptionMultiFirma)
      this.optionsMultiDocument.add(OptionDto.build("FIRMA", "FIRMA", "FIRMA", "fa-pencil", 0, 0, visualitzarMenuBarra, false, false));
    
    if (this.documents == null || this.documents.length == 0){
      this.multidocument = false;
      this.CMRAsDocument = false;
      if(this.addOptionMultiFirma)
        this.wellcomeDocumentDetails(new Array());
      return;
    }

    let i = 1;
    if(!this.CMRAsDocument){
      this.optionsMultiDocument.add(OptionDto.build("CMRDigital", "CMR", "CMR", "", i, -1, visualitzarMenuBarra, true, false));
      this.wellcomeDocumentDetails(new Array());
      i++;
    }
   
    this.documents.forEach(x => {
      this.optionsMultiDocument.add(OptionDto.build(x.documentGroup, x.documentTypeResource, x.documentTypeResource, "", i, -1, visualitzarMenuBarra, x.documentType == RouteDocumentTypes.CMR, false));
      i++;
    });
    
    this.wellcomeDocumentViewerType();
  }

  wellcomeDocumentViewerType() {
    if (this.selectedDocumentViewerType) 
      OptionsStripDto.selectById(this.optionsMultiDocument, this.selectedDocumentViewerType.documentGroup);

    this.onClickOptionMultiDocument(this.optionsMultiDocument.getActualSelected());
  }

  @Output() optionMultiDocumentChanged = new EventEmitter<OptionDto>();
  public __optionMultiDocument: OptionDto = null;
  public set optionMultiDocument(value: OptionDto) {
    if (this.__optionMultiDocument != null && value != null && this.__optionMultiDocument.optionId == value.optionId)
      return;
    if (this.__optionMultiDocument === value)
      return;
    this.__optionMultiDocument = value;
    this.onClickOptionMultiDocument(this.__optionMultiDocument);
  }
  public get optionMultiDocument(): OptionDto {
    return this.__optionMultiDocument;
  }

  public onClickOptionMultiDocument(optionMultiDocument: OptionDto) {
    this.optionMultiDocument = optionMultiDocument;
    this.optionMultiDocumentChanged.next(optionMultiDocument);
    this.setDocumentViewerType();
  }

  setDocumentViewerType() {
    if (this.optionMultiDocument == null || this.optionMultiDocument.optionId == ""){
      this.selectedDocumentViewerType = null;
      this.selectedDocument = null;
      this.documentDetail = null;
    } else if(this.optionMultiDocument.optionId == "FIRMA"){
      this.selectedDocumentViewerType = null;
      this.selectedDocument = null;
      this.documentDetail = null;
      this.wellComeDocument();
    } else if(this.optionMultiDocument.optionId == "CMRDigital"){
      this.selectedDocumentViewerType = null;
      this.selectedDocument = null;
      this.wellComeDocument();
    } else {
      this.documents.forEach(x => {
        if (x.documentGroup == this.optionMultiDocument.optionId)
          this.selectedDocumentViewerType = x;
      });
    }
  }

  public __selectedDocumentViewerType: DocumentViewerTypeDto;
  set selectedDocumentViewerType(value: DocumentViewerTypeDto) {
    if (this.__selectedDocumentViewerType === value)
      return;
    this.__selectedDocumentViewerType = value;
    this.setDocument();
  }
  get selectedDocumentViewerType(): DocumentViewerTypeDto {
    return this.__selectedDocumentViewerType;
  }

  setDocument() {
    if (this.selectedDocumentViewerType == null || this.selectedDocumentViewerType.documents == null)
      this.selectedDocument = null;
    else{
      let d: DocumentViewerHistoricDto;
      this.selectedDocumentViewerType.documents.forEach(element => {
        if(d == null || HDateHour.isGreater(element.createdAt, d.createdAt))
          d = element;
      });
      this.selectedDocument = d;
    } 
    
  }

  public __selectedDocument: DocumentViewerHistoricDto;
  set selectedDocument(value: DocumentViewerHistoricDto) {
    if (this.__selectedDocument === value)
      return;
    this.__selectedDocument = value;
    this.wellComeDocument();
  }
  get selectedDocument(): DocumentViewerHistoricDto {
    return this.__selectedDocument;
  }
  
  /////////////////////////////// DETAILS /////////////////////////////////////////////////

  public visible = false;
  ngAfterViewInit(): void {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.visible = true;
          this.wellComeDocuments();
        } else {
          this.visible = false;
        }
      });
    });

    observer.observe(this.el.nativeElement);
  }

  public documentDetails: OpenDocumentDetail[] = null;
  public documentDetailsLength = 0;
  wellComeDocument() {
    if(this.selectedDocument != null){
      this.documentDetails = this.selectedDocument.documentDetailList;
      if (this.documentDetails != null)
        this.documentDetailsLength = this.documentDetails.length;
      else
        this.documentDetailsLength = 0;
    }else{
      this.documentDetails = new Array();
      this.documentDetailsLength = 0;
    }
    this.wellcomeDocumentDetails(this.documentDetails);
  }

  public addOptionDetailFirma: boolean;
  public optionsMultiDetail: OptionsStripDto = new OptionsStripDto();
  public wellcomeDocumentDetails(value: OpenDocumentDetail[]) {

    this.optionsMultiDetail = new OptionsStripDto();
    if (value == null)
      return;

    let ampladaComponent = this.getAmpladaComponent();
    
    let visualitzarMenuBarra = 1;
    if ((value.length > 3 && ampladaComponent < 400) || (value.length > 5 && ampladaComponent < 600) || (value.length > 6 && ampladaComponent < 700))
      visualitzarMenuBarra = -1;

    this.addOptionDetailFirma = this.showSignature && !this.multidocument && !this.avoidOptionFirma;

    if (this.addOptionDetailFirma)
      this.optionsMultiDetail.add(OptionDto.build("FIRMA", "FIRMA", "FIRMA", "fa-pencil", 0, 0, visualitzarMenuBarra, false, false));
    let i = 1;
    if (this.documentDetailsLength > 0)
      value.forEach(x => {
        this.optionsMultiDetail.add(OptionDto.build(x.documentDetailId + "", i.toString(), i.toString(), "fa-file-text", i, 0, visualitzarMenuBarra, i === 1, false));
        i++;
      });
    else if (this.addOptionDetailFirma)
      this.optionsMultiDetail.add(OptionDto.build("1", "1", "1", "", i, -1, visualitzarMenuBarra, true, false));

    this.wellcomeDocumentDetailId();
  }
  
  private _documentDetailId: number;
  public set documentDetailId(value: number) {
    if (this._documentDetailId === value)
      return;
    this._documentDetailId = value;
    this.wellcomeDocumentDetailId();
  }
  public get documentDetailId(): number {
    return this._documentDetailId;
  }


  @Output() optionMultiDetailChanged = new EventEmitter<OptionDto>();
  public __optionMultiDetail: OptionDto = null;
  public set optionMultiDetail(value: OptionDto) {
    if (this.__optionMultiDetail != null && value != null && this.__optionMultiDetail.optionId == value.optionId)
      return;
    if (this.__optionMultiDetail === value)
      return;
    this.__optionMultiDetail = value;
    this.onClickOptionMultiDetail(this.__optionMultiDetail);
  }
  public get optionMultiDetail(): OptionDto {
    return this.__optionMultiDetail;
  }

  public onClickOptionMultiDetail(optionMultiDetail: OptionDto) {
    this.optionMultiDetail = optionMultiDetail;
    this.optionMultiDetailChanged.next(optionMultiDetail);
    this.setDocumentDetail();
  }

  private _documentDetail: OpenDocumentDetail;
  public set documentDetail(value: OpenDocumentDetail) {
    if (this._documentDetail === value)
      return;
    this._documentDetail = value;
    this.documentDetailId = value != null ? value.documentDetailId : null;
  }
  public get documentDetail(): OpenDocumentDetail {
    return this._documentDetail;
  }

  setDocumentDetail() {
    if (this.optionMultiDetail == null || this.optionMultiDetail.optionId == "1")
      this.documentDetail = null;
    else if (this.optionMultiDetail.optionId == "FIRMA")
      this.documentDetail = null;
    else {
      let i = Number(this.optionMultiDetail.optionId);
      this.documentDetails.forEach(x => {
        if (x.documentDetailId == i)
          this.documentDetail = x;
      });
    }
  }

  wellcomeDocumentDetailId() {
    if (this.documentDetailId) 
      OptionsStripDto.selectById(this.optionsMultiDetail, this.documentDetailId + "");
    
    this.onClickOptionMultiDetail(this.optionsMultiDetail.getActualSelected());
    
  }

  getAmpladaComponent(){
    const element = document.querySelector('.base-component');
    let ampladaComponent = 0;
    if (element) {
      const rect = element.getBoundingClientRect();
      ampladaComponent = rect.width; // Amplada del component
      console.log('Amplada del component:', ampladaComponent);

    }
    return ampladaComponent;
  }
  
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.wellComeDocuments();
  }

  pretty(value: any): string {
    return JSON.stringify(value);
  }

}
