<div *ngIf="route != null" class="block">
    <div class="titol" *ngIf="!autoEdit">
        <div class="inline">
            <div class="col-x"
                *ngIf="route.showAskCarrierCode || route.showAskCarrierName || route.showAskCarrierLegalIdentification">
                <div *ngIf="route.showAskCarrierCode" class="box carrierCode">
                    <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.CARRIERCODE' | translateH}}</div><span
                        class="invisible" *ngIf="!route.carrierCode">{{'ACTIVESLOTS.NOCARRIERCODE' |
                        translateH}}</span>
                    {{route.carrierCode}}
                </div>
                <div *ngIf="route.showAskCarrierName" class="box carrierName">
                    <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.CARRIERNAME' | translateH}}</div><span
                        class="invisible" *ngIf="!route.carrierName">{{'ACTIVESLOTS.NOCARRIERNAME' |
                        translateH}}</span>
                    {{route.carrierName}}
                </div>
                <div *ngIf="route.showAskCarrierLegalIdentification" class="box carrierLegalIdentification">
                    <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.CARRIERLEGALIDENTIFICATION' | translateH}}</div><span
                        class="invisible"
                        *ngIf="!route.carrierLegalIdentification">{{'SHARED.EXWORK.NOCARRIERLEGALIDENTIFICATION' |
                        translateH}}</span>
                    {{route.carrierLegalIdentification}}
                </div>
            </div>
            <div class="col-x" *ngIf="route.showAskCarrierAddress">
                <div *ngIf="route.showAskCarrierAddress" class="box carrierAddress">
                    <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.CARRIERADDRESS' | translateH}}</div><span
                        class="invisible" *ngIf="!route.carrierAddress">{{'SHARED.EXWORK.NOCARRIERADDRESS' |
                        translateH}}</span>
                    <div class="carrier-address">{{route.carrierAddress}}</div>
                </div>
            </div>

            <div class="col-x">
                <div class="box driverName">
                    <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.DRIVERNAME' | translateH}}</div><span class="invisible"
                        *ngIf="!route.driverName">{{'SHARED.EXWORK.NODRIVERNAME' |
                        translateH}}</span>
                    {{route.driverName}}
                </div>
                <div class="box driverLegalIdentification">
                    <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.DRIVERLEGALIDENTIFICATION' | translateH}}</div><span
                        class="invisible"
                        *ngIf="!route.driverLegalIdentification">{{'SHARED.EXWORK.NODRIVERLEGALIDENTIFICATION' |
                        translateH}}</span>
                    {{route.driverLegalIdentification}}
                </div>
                <div class="box driverPhone">
                    <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.DRIVERPHONE' | translateH}}</div><span
                        class="invisible" *ngIf="!route.driverPhone">{{'ACTIVESLOTS.NODRIVERPHONE' |
                        translateH}}</span><a href="tel:{{route.driverPhone}}"><i class="fa fa-phone-square"
                            aria-hidden="true" *ngIf="route.driverPhone"></i>
                        {{route.driverPhone}}</a>
                </div>
            </div>

            <div class="col-x">
                <div class="box plateA">
                    <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.PLATEA' | translateH}}</div><span class="invisible"
                        *ngIf="!route.plateA">{{'ACTIVESLOTS.NOPLATEA' | translateH}}</span>
                    {{route.plateA}}
                </div>
                <div class="box plateB">
                    <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.PLATEB' | translateH}}</div><span class="invisible"
                        *ngIf="!route.plateB">{{'ACTIVESLOTS.NOPLATEB' | translateH}}</span>
                    {{route.plateB}}
                </div>
                <div class="box plateB" *ngIf="!route.hidePlateC">
                    <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.PLATEC' | translateH}}</div><span class="invisible"
                        *ngIf="!route.plateC">{{'ACTIVESLOTS.NOPLATEC' | translateH}}</span>
                    {{route.plateC}}
                </div>
            </div>
            <button *ngIf="!readonly && !hideLink" class="link right positionrara"
                (click)="startEditmode()">{{'ACTIVESLOTS.EDIT.DRIVERPLATE.CAPTION' | translateH}}</button>
        </div>
    </div>
    <div class="canvi-dades" *ngIf="!readonly">
        <div class="zona-edit" *ngIf="editMode || autoEdit">
            <div class="help">
                {{'ACTIVESLOTS.EDIT.HELP' | translateH}}
            </div>
            <div class="btn-close" (click)="cancel()">
                <i class="fa fa-close"></i>
            </div>
            <div class="data-block">
                <span class="data-block-title">
                    <i class="fa fa-building" aria-hidden="true"></i>
                    <span class="title">
                        {{ 'SHARED.EXWORK.EDIT.CARRIER.TITLE' | translate}}
                    </span>
                </span>
                <input-component *ngIf="route.showAskCarrierCode" class="col-x" [(ngModel)]="carrierCode"
                    placeholder="{{'ROUTEPAGE.ITEM.EDIT.CARRIERCODE' | translateH}}"></input-component>
                <input-component *ngIf="route.showAskCarrierName" class="col-x" [(ngModel)]="carrierName"
                    placeholder="{{'ROUTEPAGE.ITEM.EDIT.CARRIERNAME' | translateH}}"></input-component>
                <input-component *ngIf="route.showAskCarrierLegalIdentification" class="col-x"
                    [(ngModel)]="carrierLegalIdentification"
                    placeholder="{{'ROUTEPAGE.ITEM.EDIT.CARRIERLEGALIDENTIFICATION' | translateH}}"></input-component>
                <input-component *ngIf="route.showAskCarrierAddress" class="col-x" [(ngModel)]="carrierAddress"
                    placeholder="{{'ROUTEPAGE.ITEM.EDIT.CARRIERADDRESS' | translateH}}"></input-component>
            </div>
            <div class="data-block">
                <span class="data-block-title">
                    <i class="fa fa-male" aria-hidden="true"></i>
                    <span class="title">
                        {{ 'SHARED.EXWORK.EDIT.DRIVER.TITLE' | translate}}
                    </span>
                </span>
                <input-component class="col-x" [(ngModel)]="driverName"
                    placeholder="{{'ROUTEPAGE.ITEM.EDIT.DRIVERNAME' | translateH}}"></input-component>
                <input-component class="col-x" [(ngModel)]="driverLegalIdentification"
                    placeholder="{{'ROUTEPAGE.ITEM.EDIT.DRIVERLEGALIDENTIFICATION' | translateH}}"></input-component>
                <input-component class="col-x" [(ngModel)]="driverPhone"
                    placeholder="{{'ROUTEPAGE.ITEM.EDIT.DRIVERPHONE' | translateH}}"></input-component>
            </div>
            <div class="data-block">
                <span class="data-block-title">
                    <i class="fa fa-truck" aria-hidden="true"></i>
                    <span class="title">
                        {{ 'SHARED.EXWORK.EDIT.PLATES.TITLE' | translate}}
                    </span>
                </span>
                <div class="col-x">
                    <input-component class="col-plate" [(ngModel)]="plateA"
                        placeholder="{{'ROUTEPAGE.ITEM.EDIT.PLATEA' | translateH}}"></input-component>
                    <input-component class="col-plate" [(ngModel)]="plateB"
                        placeholder="{{'ROUTEPAGE.ITEM.EDIT.PLATEB' | translateH}}"></input-component>
                    <input-component *ngIf="!route.hidePlateC" class="col-plate" [(ngModel)]="plateC"
                        placeholder="{{'ROUTEPAGE.ITEM.EDIT.PLATEC' | translateH}}"></input-component>
                </div>
            </div>
            <shared-savebutton class="botodesar" (save)="save($event)">
                {{'ACTIVESLOTS.DRIVER.BUTTON.SAVE' | translateH}}
            </shared-savebutton>
        </div>
    </div>
</div>