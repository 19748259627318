import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SlotChangeReasonMaintenanceController } from '@shared/src/controllers/maintenance/reason.slotchange/SlotChangeReasonMaintenanceController';
import { HLong } from '@shared/src/datatypes/HLong';
import { HString } from '@shared/src/public-api';
import { BookingController } from '@shared/src/controllers/booking/booking.controller';
import { BookingSlotChangeReasonManageDto } from '@shared/src/dtos/booking/slotchangereason/BookingSlotChangeReasonManageDto';
import { BookingSlotChangeReasonDataInfoDto } from '@shared/src/dtos/booking/slotchangereason/BookingSlotChangeReasonDataInfoDto';
import { SlotChangeReasonItemDto } from '@shared/src/dtos/slotchangereason/SlotChangeReasonItemDto';

@Component({
  selector: 'shared-booking-slot-change-reason-manage-component',
  templateUrl: './shared.booking.slot.change.reason.manage.component.html',
  styleUrls: ['./shared.booking.slot.change.reason.manage.component.scss'],
})
export class SharedBookingSlotChangeReasonManageComponent implements OnInit {

  constructor(public bookingController: BookingController, public slotChangeReasonMaintenanceController: SlotChangeReasonMaintenanceController, protected translateService: TranslateService) {
  }

  @Input() public readonly: boolean = false;
  public value: BookingSlotChangeReasonManageDto;
  public objTranslate: any;

  public SLOTCHANGEREASON_FILTERKEYS: Array<string> = ['SLOTCHANGEREASON.NOTHING.FILTER'];

  private _bookingId: number;
  @Input() set bookingId(value: number) {
    if (this._bookingId === value)
      return;
    this._bookingId = value;
    this.loadData();
  }
  get bookingId(): number {
    return this._bookingId;
  }

  private _dataInfo: BookingSlotChangeReasonDataInfoDto;
  @Input() set dataInfo(value: BookingSlotChangeReasonDataInfoDto) {
    if (this._dataInfo === value)
      return;
    this._dataInfo = value;
    this.loadData();
  }
  get dataInfo(): BookingSlotChangeReasonDataInfoDto {
    return this._dataInfo;
  }

  ngOnInit() {

  }

  loadData() {

    if (this.bookingId == null || this.dataInfo == null)
      return;

      this.bookingController.getSlotChangeReasonManage(this.bookingId, this.dataInfo.action).subscribe(data => {
        this.welcome(data);
      });


  }

  welcome(data: BookingSlotChangeReasonManageDto) {
    this.value = data;
   
    this.loadNextSlotChangeReason();
  }

  loadNextSlotChangeReason() {
    if (this.value == null || this.value.slotChangeReasons == null || this.value.slotChangeReasons.length == 0) {
      this.incompletReasons = true;
      this.incompletActualReasons = true;
      this.disabledButton = true;
      this.tenantsIncompletReason = new Array();
      return;
    }

    let reason = null;
    this.value.slotChangeReasons.forEach(element => {
      if (reason == null) {
        if (HLong.isNullOrNullLong(element.slotChangeReasonId))
          reason = element;
        else if (element.isRequiredComment && HString.isNullOrNullString(element.comment))
          reason = element;
      }
    });

    this.bookingSlotChangeReasonItem = reason;

    this.refreshIncompleteReasons();

    this.SLOTCHANGEREASON_FILTERKEYS = this.bookingSlotChangeReasonItem != null ? ['SLOTCHANGEREASON.BYTENANTID.FILTER:' + this.bookingSlotChangeReasonItem.tenantId, 'SLOTCHANGEREASON.FILTER.NOTISSYSTEM'] : ['SLOTCHANGEREASON.NOTHING.FILTER'];
  }

  private _bookingSlotChangeReasonItem: SlotChangeReasonItemDto;
  set bookingSlotChangeReasonItem(value: SlotChangeReasonItemDto) {
    if (this._bookingSlotChangeReasonItem === value)
      return;
    this._bookingSlotChangeReasonItem = value;
  }
  get bookingSlotChangeReasonItem(): SlotChangeReasonItemDto {
    return this._bookingSlotChangeReasonItem;
  }

  public comment: string = "";
  public slotChangeReasonChangedEvent(value: number) {
    if (value != null && this.bookingSlotChangeReasonItem != null) {

      this.slotChangeReasonMaintenanceController.getSlotChangeReasonItemByReasonId(value).subscribe(data  => {
        if (data != null) {
          this.bookingSlotChangeReasonItem.isRequiredComment = data.isRequiredComment;
          this.refreshIncompleteReasons();

        }
      });

    }
  }

  getResourceKeyCaption(){
    if (this.dataInfo == null)
      return "SHARED.BOOKINGSLOTCHANGEREASONMANAGE.CANCELALLSLOT.CAPTION";
    return "SHARED.BOOKINGSLOTCHANGEREASONMANAGE." + this.dataInfo.action.toUpperCase() + ".CAPTION";
  }

  getResourceKeyComments(){

    if (this.bookingSlotChangeReasonItem != null && this.bookingSlotChangeReasonItem.isRequiredComment)
      return "SHARED.SLOTCHANGEREASONMANAGE.COMMENTS.REQUIREPLACEHOLDER";
    return "SHARED.SLOTCHANGEREASONMANAGE.COMMENTS.PLACEHOLDER";

  }

  getResourceButton() {
    var base: string = "SHARED.BOOKINGSLOTCHANGEREASONMANAGE.";
  
    if(this.bookingSlotChangeReasonItem != null){
      if (this.value.slotChangeReasons.length > 1 && this.tenantsIncompletReason.length > 0 && !this.tenantsIncompletReason.includes(this.bookingSlotChangeReasonItem.tenantId)) 
        return base + "NEXT";
    }
     
    return base + this.dataInfo.action.toUpperCase() + ".BUTTON";
  }

  changeComment(value: string) {
    this.comment = value;
    this.refreshIncompleteReasons();
  }

  public disabledButton: boolean = true;
  public incompletReasons: boolean = true;
  public incompletActualReasons: boolean = true;
  public tenantsIncompletReason: number[];
  refreshIncompleteReasons() {
    
    if (this.value == null || this.value.slotChangeReasons == null || this.value.slotChangeReasons.length == 0) {
      this.incompletReasons = true;
      this.incompletActualReasons = true;
      this.disabledButton = true;
      this.tenantsIncompletReason = new Array();
      return;
    }

    //Traspassem a la llista les dades informades
    if(this.bookingSlotChangeReasonItem != null){
      this.value.slotChangeReasons.forEach(element => {
        if (this.bookingSlotChangeReasonItem.tenantId == element.tenantId) {
          element.slotChangeReasonId = this.bookingSlotChangeReasonItem.slotChangeReasonId;
          element.comment = this.bookingSlotChangeReasonItem.comment;
          element.isRequiredComment = this.bookingSlotChangeReasonItem.isRequiredComment;
        }
      });
    }

    let reason: number[] = new Array();

    this.value.slotChangeReasons.forEach(element => {
      if (HLong.isNullOrNullLong(element.slotChangeReasonId))
        reason.push(element.tenantId);
      else if (element.isRequiredComment && HString.isNullOrNullString(element.comment))
        reason.push(element.tenantId);
    });

    this.incompletActualReasons = this.bookingSlotChangeReasonItem != null && (HLong.isNullOrNullLong(this.bookingSlotChangeReasonItem.slotChangeReasonId) || (this.bookingSlotChangeReasonItem.isRequiredComment && HString.isNullOrNullString(this.comment)));
   
    this.disabledButton = this.incompletActualReasons;

    this.incompletReasons = reason.length > 0;
    this.tenantsIncompletReason = reason;

  }


  doPreAction(event) {
    this.loadNextSlotChangeReason();

    if (this.bookingSlotChangeReasonItem == null && !this.incompletReasons) {
      this.doAction();
    }
  }


  @Output()
  public onSaveReason: EventEmitter<any> = new EventEmitter();

  onFinishDoAction(value: Boolean){
    if(value)
      this.onSaveReason.next(true);
    else
      this.loadData();
  }
  
  doAction() {

    if(this.dataInfo == null || this.value == null)
      return;

        this.bookingController.executeActionWithSlotChangeReason(this.bookingId, this.dataInfo.action, this.value.slotChangeReasons).subscribe(data => {
          if (data) {
            this.onFinishDoAction(data);
          }
        });
}

public pretty(value) {
    return JSON.stringify(value);
  }
}
