<div class="container" [ngClass]="{'ionic': useIonic,  'selectedroute' : routeId > 0 }">
    <div class="cards list">
        <div class="heard">
            <div class="title">
                {{'TRUCKING.COLUMN.ROUTES.TITLE' | translate}}
                <span class="count">{{filterMetadata.count}}/{{routesCount}}</span>
            </div>
            <shared-filter class="extrapadding" [filterstrip]="filter" (change)="filterUpdate()"></shared-filter>

            <div class="line">
                <input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text
                    [(ngModel)]="searchText" (ngModelChange)="this.searchTextUpdate.next($event)"
                    (focus)="onSearchFocus($event)" (click)="onSearchClick($event)">

                <actions [actionstrip]="actions" (clickAction)="onClickAction($event)">
                </actions>

            </div>
        </div>

        <div class="page">
            <div class="column">
                <div class="llista">
                    <div class="overflow">

                        <shared-trucking-route-card
                            *ngFor="let item of routesExactKey | filterActiveRoutes:searchText2:filterMetadataExactKey | orderbytruckingroutecarddto:true; let i=index"
                            class="routeItem"
                            [ngClass]="{'selected':selectedRoute!=null && item.routeId==selectedRoute.routeId}"
                            [route]="item"
                            (click)="onClickItem(item)" (refresh)="onRefreshRoute($event)" (clickBack)="onClickBack($event)">
                        </shared-trucking-route-card>

                        <shared-trucking-route-card
                            *ngFor="let item of getRoutes() | filterActiveRoutes:searchText2:filterMetadata | filterRemoveRepeat:routesExactKey:'routeId' | orderbytruckingroutecarddto:true | slice:0:sliceCount; let i=index"
                            class="routeItem"
                            [ngClass]="{'selected':selectedRoute!=null && item.routeId==selectedRoute.routeId}"
                            [route]="item" (click)="onClickItem(item)" (refresh)="onRefreshRoute($event)" (clickBack)="onClickBack($event)">
                        </shared-trucking-route-card>

                        <button class="slice-button" (click)="onSliceMore()" *ngIf="sliceCount<itemsCount">
                            {{'GENERAL.SLICE.BUTTON' | translate:{ sliceCount: sliceCount, itemsCount:itemsCount} }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>