import { Location } from '@angular/common';
import { Component, Inject, OnInit, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { AmbitMaintenanceController } from '@shared/src/controllers/maintenance/ambit/AmbitMaintenanceController';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { SocketController } from '@shared/src/controllers/socket/socket.controller';
import { SharedAsideFactory } from '../aside/shared.aside.factory';
import { TruckingShipmentDto } from '@shared/src/dtos/trucking/TruckingShipmentDto';
import { TruckingRouteDto } from '@shared/src/dtos/trucking/TruckingRouteDto';
import { TruckingRouteExpandDto } from '@shared/src/dtos/trucking/TruckingRouteExpandDto';
import { TruckingController } from '@shared/src/controllers/trucking/trucking.controller';
import { TruckingShipmentExpandDto } from '@shared/src/dtos/trucking/TruckingShipmentExpandDto';
import { TruckingShipmentPackageDto } from '@shared/src/dtos/trucking/TruckingShipmentPackageDto';


@Component({
	selector: 'shared-trucking',
	templateUrl: './shared.trucking.component.html',
	styleUrls: ['./shared.trucking.component.scss']
})
export class SharedTruckingComponent implements OnInit {


	public useIonic: boolean = false;
	constructor(public activatedRoute: ActivatedRoute,
		public router: Router, public location: Location, public truckingController: TruckingController,
		public ambitController: AmbitMaintenanceController,
		@Inject('HomeController') public homeController: HomeController,
		@Inject("SecurityController") public securityController: SecurityController,
		@Inject('SharedAsideFactory') public sharedAsideFactory: SharedAsideFactory,
		public socketController: SocketController,
		public translateService: TranslateService) {

		if (homeController.useIonic)
			this.useIonic = homeController.useIonic();

	}

	ngOnInit(): void {
	
	}

	ngAfterViewChecked(): void {
	}

	public displayFlex: boolean = false;
	onSelectedRoute(route: TruckingRouteDto) {
		this.loadRouteExpand(route);
		this.displayFlex = route != null;
	}

	onRefreshRoute(route: TruckingRouteDto) {
		this.loadRouteExpand(route);
	}

	onClickBack(value: boolean) {
		this.displayFlex = false;
	}

	public _selectedRouteExpand: TruckingRouteExpandDto;
	@Input()
	public get selectedRouteExpand(): TruckingRouteExpandDto {
		return this._selectedRouteExpand;
	}
	public set selectedRouteExpand(value: TruckingRouteExpandDto) {
		if (this._selectedRouteExpand === value)
			return;
		this._selectedRouteExpand = value;
	}

	loadRouteExpand(route: TruckingRouteDto){
		if(route != null){
			this.truckingController.getTruckingRouteExpand(route.routeId).subscribe(data => {
				this.selectedRouteExpand = data;
			});
		}else{
			this._selectedRouteExpand = null;
		}
	}

}
