import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";

export class EntitySelectorItemDto {

    public entitySelector: EntitysSelectors;
    public id: any;
    public key: any;
    public firstColumn: any;
    public secondColumn: any;
    public canDeleteItem: boolean;
    public __order: string;

    public static isNullOrNullId(id: any): boolean {
        if(id == null || id == 0 || id == undefined || id == "")
            return true;
        return false;
    }
}