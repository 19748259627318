<div class="container" *ngIf="isImageType && formatImages">
    <img *ngFor="let formatImage of formatImages; let i=index" class="imatge" [src]="formatImage" />
    <shared-opendocument-component *ngIf="showOpen && document" [document]="document" class="opendocument">
    </shared-opendocument-component>
    <shared-opendocument-component *ngIf="showOpen && documentDetail" [document]="documentDetail" class="opendocument">
    </shared-opendocument-component>
</div>
<div class="container" *ngIf="isPdfType && urlDatas && documentDetail" [ngClass]="documentDetail.fileType">
    <shared-opendocument-component *ngIf="showOpen && document" [document]="document" class="opendocument">
    </shared-opendocument-component>
    <shared-opendocument-component *ngIf="showOpen && documentDetail" [document]="documentDetail"
        class="opendocument"></shared-opendocument-component>
    <div class="pdf-container" *ngFor="let blob of urlDatas.values(); let i=index">
        <pdf-viewer [src]="blob" [render-text]="true" [original-size]="false"
            style="width: 100%; height: 100%"></pdf-viewer>
    </div>
</div>
<div class="container" *ngIf="!isImageType && !isPdfType && urlDatas && documentDetail" [ngClass]="documentDetail.fileType">
    <shared-opendocument-component *ngIf="showOpen && document" [document]="document" class="opendocument">
    </shared-opendocument-component>
    <shared-opendocument-component *ngIf="showOpen && documentDetail" [document]="documentDetail"
        class="opendocument"></shared-opendocument-component>
    <iframe *ngFor="let urlData of urlDatas; let i=index" #iframe class="frame" [src]="urlData | safe:'resourceUrl'"
        allowfullscreen></iframe>
</div>
<!--
<shared-opendocument-component *ngIf="useIonic && !isImageType && !isPdfType && document && !urlDatas"
    [document]="document" [iconclass]="'fa fa-file-text-o'"
    class="action button icon-ionic-file-text-o"></shared-opendocument-component>
<shared-opendocument-component *ngIf="useIonic && !isImageType && !isPdfType && documentDetail && !urlDatas"
    [documentDetail]="documentDetail" [iconclass]="'fa fa-file-text-o'"
    class="action button icon-ionic-file-text-o"></shared-opendocument-component>
    -->