<div class="container" [ngClass]="{'ionic' : useIonic, 'activeroutes':opcio=='activeroutes'}">
    <div class="linia inicial" [ngClass]="opcio">
        <button class="general-link-boto activeroutes"
            (click)="setOpcio('activeroutes')">{{'SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTION.ACTIVEROUTES.PROMPT' |
            translate}}</button>
        <button class="general-link-boto route"
            (click)="setOpcio('route')">{{'SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTION.ROUTE.PROMPT' |
            translate}}</button>
        <button *ngIf="phase && phase.addressOwner" class="general-link-boto CC"
            (click)="setOpcio('CC')">{{'SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTION.CC.PROMPT' | translate}}</button>
        <button class="general-link-boto jump" (click)="setOpcio('AR')"><i class="fa fa-share"
                aria-hidden="true"></i>{{'SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTION.AR.PROMPT' | translate}}</button>
        <button class="general-link-boto jump" (click)="setOpcio('AS')"><i class="fa fa-share"
                aria-hidden="true"></i>{{'SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTION.AS.PROMPT' | translate}}</button>
    </div>
    <ng-container *ngIf="opcio=='activeroutes' && route && phase">
        <div class="linia primera">
            <div class="routeminicard esquerra"
                [ngClass]="{'isSlotBlocked':phase.isSlotBlocked, 'incompatibleSlot':phase.incompatibleSlot}">
                <div class="icona esquerra">
                    <img *ngIf="phase.isUnload" src="/assets/images/unloadCargo.png" alt="unload Cargo">
                    <img *ngIf="phase.isLoad" src="/assets/images/loadCargo.png" alt="unload Cargo">
                    <img *ngIf="phase.isUnloadLoad" src="/assets/images/loadUnloadCargo.png" alt="unload Cargo">
                    <i *ngIf="phase.isInitial || phase.isFinal" class="fa fa-circle-thin"></i>
                </div>
                <div class="esquerra">
                    <div class="title">{{route.title}}</div>
                    <div class="routekey">{{route.routeKey}}</div>
                    <shared-tags-strip-component *ngIf="haveTags(route.manualTags)" class="tagsbottom"
                        [tags]="route.manualTags"></shared-tags-strip-component>
                    <shared-tags-strip-component class="tagsbottom" [tags]="route.tagsBottom">
                    </shared-tags-strip-component>
                    <shared-tags-strip-component *ngIf="haveTags(phase.manualTags)" class="tagsbottom"
                        [tags]="phase.manualTags"></shared-tags-strip-component>
                    <shared-tags-strip-component class="tagsbottom" [tags]="phase.tags"></shared-tags-strip-component>
                </div>
            </div>
            <shared-slot-viewer-component class="relative" *ngIf="phase" [slotInfo]="slotInfo" [explicat]="'true'"
                [phaseId]="phase.phaseId" (callBack)="onRefreshRoute(true)">
            </shared-slot-viewer-component>
        </div>
        <div *ngIf="route && route.styledBannerHtml" class="private-banner-html"
            [innerHTML]="route.styledBannerHtml | safe: 'html'">
        </div>
        <div *ngIf="route && route.styledPrivateBannerHtml" class="private-banner-html"
            [innerHTML]="route.styledPrivateBannerHtml | safe: 'html'">
        </div>
        <div class="linia baractions">
            <actions class="slim" *ngIf="!readonly" [actionstrip]="actions" (clickAction)="onClickAction($event)">
            </actions>
            <shared-state [(value)]="states" (stateChange)="onSelectorChangedState($event)" [readonly]="readonly">
            </shared-state>
        </div>
        <div class="linia bar baroptions">
            <options class="slim mixed-text-icon" [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
        </div>
        <div class="ultima-linia">
            <ng-container *ngIf="actualOptionId==='DRIVER'">
                <shared-driverplate class="block-option" *ngIf="route" [showCarrierCard]="false" [onlydriver]="true" [route]="route" [readonly]="readonly"></shared-driverplate>
            </ng-container>
    
            <ng-container *ngIf="actualOptionId==='SLOT'">  
                <div class="block-option" *ngIf="phase">      
                    <shared-slot-picker class="block hide-bloqueig hide-lastmoments hide-titol"
                        (valueChange)="loadRouteData()" (callBack)="loadRouteData()" [phase]="phase">
                    </shared-slot-picker>
                </div>
            </ng-container>
            <ng-container *ngIf="actualOptionId==='DOCKSSLOTS'">  
                <div class="block-option" *ngIf="phase">      
                    <shared-dock-picker class="hide-bloqueig hide-lastmoments hide-titol"
                        (valueChange)="loadRouteData()" [phase]="phase" [modeByPhase]="true">
                    </shared-dock-picker>
                    <shared-slot-picker class="block hide-bloqueig hide-lastmoments hide-titol"
                        (valueChange)="loadRouteData()" (callBack)="loadRouteData()" [phase]="phase">
                    </shared-slot-picker>
                </div>
            </ng-container>
    
            <ng-container *ngIf="actualOptionId==='INTERNATIONAL'">
                <shared-route-booking class="block-option" *ngIf="route" [routeId]="route.routeId" [readonly]="readonly">
                </shared-route-booking>
            </ng-container>   
    
            <ng-container *ngIf="actualOptionId==='ROUTEHISTORY'">
                <shared-route-item-log-maintenance class="block-option" *ngIf="route" [routeId]="route.routeId">
                </shared-route-item-log-maintenance>
            </ng-container>
        
            <ng-container *ngIf="actualOptionId==='CHAT'">
                <shared-chat class="block-option" [forcedOwnerParticipant]="forcedOwnerParticipant" [notes]="true"
                    (clickParticipant)="clickParticipant($event)">
                </shared-chat>
            </ng-container>
        
            <ng-container *ngIf="actualOptionId==='OBSERVATIONS'">
                <div class="block-option" *ngIf="route">
                    <shared-banners [routeId]="route.routeId" class="aside"></shared-banners>
                    <shared-route-changetags [routeId]="route.routeId" class="aside" [showalltags]="true"></shared-route-changetags>
                </div>
            </ng-container>
        
            <ng-container *ngIf="actualOptionId==='GROUPED'">
                <shared-route-group-child-list-component class="block-option"
                    [phaseId]="phaseId"></shared-route-group-child-list-component>  
            </ng-container>

        </div>
    </ng-container>

</div>
<shared-route-info-component class="aside nolinks" *ngIf="opcio=='route'" [routeId]="routeId">
</shared-route-info-component>
<shared-phase-loadconsole-management class="aside phase nolinks" *ngIf="opcio=='CC'" [phaseId]="phaseId">
</shared-phase-loadconsole-management>