<!-- tristate.component.html!-->
<div class="container" [formGroup]="rFormColor">
    <div class="text preplaceholder" *ngIf="preplaceholder">
        {{ preplaceholder }}
    </div>

    <div class="container-states">
        <div class="states">
            <button class="check isfalse" [ngClass]="{'check-on': isFalse }" (click)="setState(0)">
                <i class="fa fa-close nofridge" aria-hidden="true"></i>
                <i class="fa fa-circle-o sifridge" aria-hidden="true"></i>
                <i class="fa fa-unlock silock" aria-hidden="true"></i>
            </button>
            <button class="check isindeterminate" [ngClass]="{'check-on': isIndeterminate }" (click)="setState(-1)">
                <i class="fa fa-circle nofridge" aria-hidden="true"></i>
                <i class="fa fa-circle silock" aria-hidden="true"></i>
            </button>
            <button class="check istrue" [ngClass]="{'check-on': isTrue }" (click)="setState(1)">
                <i class="fa fa-check nofridge" aria-hidden="true"></i>
                <i class="fa fa-circle sifridge" aria-hidden="true"></i>
                <i class="fa fa-lock silock" aria-hidden="true"></i>
            </button>
            <div class="barraposterior"></div>
        </div>
    </div>
    <div class="text">
        {{ placeholder }}
    </div>
    <input class="hidden" [id]="identifier" [(ngModel)]="value" formControlName="dtpvalidator" ngDefaultControl
        (keydown.enter)="keyEnter($event)">
</div>