<div *ngIf="data" class="route">
    <shared-phase-slot *ngIf="data.phaseSlot != null" [disableinvokephase]="true" [canalterelement]="false"
        [phaseslot]="data.phaseSlot">
    </shared-phase-slot>

    <div *ngIf="route && route.styledBannerHtml" class="private-banner-html"
        [innerHTML]="route.styledBannerHtml | safe: 'html'">
    </div>
    <div *ngIf="route && route.styledPrivateBannerHtml" class="private-banner-html"
        [innerHTML]="route.styledPrivateBannerHtml | safe: 'html'">
    </div>

    <div class="linia bar baroptions">
        <options class="slim mixed-text-icon" [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
    </div>

    <ng-container *ngIf="actualOptionId==='GENERAL'">
        <div class="block-option">
            <div class="container" [ngClass]="{'ionic' : useIonic}" *ngIf="carrier && carrier.title">
                <div class="title d-block">{{ 'SHARED.ACCESSCONTROL.CARRIER.TITLE' | translate }}</div>
                <shared-partner-infocard class="carrier small" [type]="'Carrier'" [partner]="carrier"
                    [caption]="'ROUTE.CARRIER.CAPTION'"></shared-partner-infocard>
            </div>
            <shared-driverplate class="block-option" *ngIf="route" [showCarrierCard]="false" [onlydriver]="true"
                [route]="route"></shared-driverplate>
            <div class="block info-gate">
                <div class="info-gate-tag" [ngClass]="{'exit' : GateOut != null && GateOut.gateKey == 'Out'}"
                    [ngStyle]="getStyleGateTag(GateOut)">{{ getGateName(GateOut) }}
                </div>
                <div class="taula">
                    <div class="columna">
                        <div class="row heading">{{ 'PLATEROUTE.INFO.ENTRYMOMENT' | translate}}</div>
                        <div class="row value hour">{{ data.entryMoment | datehouronlyhournotz }}</div>
                        <div class="row value date">{{ data.entryMoment | datehouronlydatenotz }}</div>
                    </div>
                    <div class="columna">
                        <div class="row heading">{{ 'PLATEROUTE.INFO.TIME' | translate}}</div>
                        <div class="row value">{{ data.time | time }}</div>
                    </div>
                </div>
            </div>
            <div class="block actions-gate">
                <div class="header">
                    <div class="title-log title-gate">
                        {{'SHARED.LOADCONSOLE.PHASE.INFO.COMPONENT.ACTIONS-GATE.TITLE' |
                        translate}}</div>
                    <actions class="gate" [actionstrip]="actionsGates" (clickAction)="onClickAction($event)">
                    </actions>
                </div>

                <button *ngFor="let gate of data.allGates | sortBy:'order'; let i=index" class="button-gate"
                    [ngClass]="{ 'exit' : gate.isInsideGate }" [ngStyle]="getStyleGateTag(gate)"
                    (click)="onGateClick(gate)">

                    <i class="fa " [ngClass]="{ 'fa-sign-out' : gate.isInsideGate, 'fa-sign-in' : !gate.isInsideGate }"
                        aria-hidden="true"></i>
                    <div class="heading">{{getGateHeadingResource(gate) | translate}}</div>
                    <div class="text-action">
                        {{ getGateResource(gate) }}
                    </div>
                </button>

                <button *ngIf="data.showButtonGetOutOfAllGates"
                    matTooltip="{{ 'ACTIONS.GETOUTOFALLGATES.HELP' | translate }}" class="button-gate exit"
                    (click)="onGetOutOfAllGatesClick()">
                    <i class="fa fa-sign-out" aria-hidden="true"></i>
                    <div class="heading">{{ 'GATE.OUT.HEADING' | translate}}</div>
                    <div class="text-action"> {{ 'ACTIONS.GETOUTOFALLGATES.CAPTION' | translate }} </div>
                </button>

            </div>

            <div class="block log">
                <div class="title-log">
                    {{ 'GATE.LOG.MINITITLE' | translate}}
                </div>
                <div class="datos taula-logs grup" *ngIf="data.accessControlLogs != null">
                    <table class="hardman">
                        <tr class="left">
                            <th>{{ "GATE.LOG.MOMENT" | translate }}</th>
                            <th>{{ "GATE.LOG.ACCESSCONTROLTYPE" | translate }}</th>
                            <th>{{ "GATE.LOG.USERNAME" | translate }}</th>
                            <th>{{ "GATE.LOG.GATENAME" | translate }}</th>
                        </tr>
                        <tr class="left"
                            *ngFor="let element of data.accessControlLogs | sortBy:'-moment' ; let i=index">
                            <td class="blank string">{{ element.moment | datehournotz }}</td>
                            <td class="blank string">{{ getResourceAccessControlType(element) | translate }}
                            </td>
                            <td class="blank string">{{element.userName}} </td>
                            <td class="blank string">{{element.gateName}} </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="actualOptionId==='DRIVER'">
        <shared-driverplate class="block-option" *ngIf="route" [showCarrierCard]="false" [onlydriver]="true"
            [route]="route"></shared-driverplate>
        <shared-route-partner-selector class="withoutexwork withoutexternaldriver" *ngIf="route"
            [routeId]="route.routeId" [readonly]="true"></shared-route-partner-selector>
    </ng-container>

    <ng-container *ngIf="actualOptionId==='SLOTS'">
        <shared-dock-picker [modeByPhase]="true" [hideTitle]="false" class="block-option hide-bloqueig hide-lastmoments"
            *ngIf="phase" (valueChange)="loadPlateRouteData()" [phase]="phase">
        </shared-dock-picker>
        <shared-slot-picker class="block-option hide-lastmoments" *ngIf="phase" (valueChange)="loadPlateRouteData()" (callBack)="loadPlateRouteData()"
            [phase]="phase">
        </shared-slot-picker>
    </ng-container>

    <ng-container *ngIf="actualOptionId==='INTERNATIONAL'">
        <shared-containernumber class="block-option" *ngIf="route" [routeId]="route.routeId"
            [showArcDre]="false"></shared-containernumber>
    </ng-container>


    <ng-container *ngIf="actualOptionId==='ROUTEHISTORY'">
        <shared-route-item-log-maintenance class="block-option" *ngIf="route" [routeId]="route.routeId">
        </shared-route-item-log-maintenance>
    </ng-container>

    <ng-container *ngIf="actualOptionId==='CHAT'">
        <shared-chat class="block-option" [forcedOwnerParticipant]="forcedOwnerParticipant" [notes]="true"
            (clickParticipant)="clickParticipant($event)">
        </shared-chat>
    </ng-container>

    <ng-container *ngIf="actualOptionId==='OBSERVATIONS'">
        <div class="block-option" *ngIf="route">
            <shared-banners [routeId]="route.routeId" class="aside"></shared-banners>
            <shared-route-changetags [routeId]="route.routeId" class="aside"
                [showalltags]="true"></shared-route-changetags>
        </div>
    </ng-container>

    <ng-container *ngIf="actualOptionId==='GROUPED'">
        <shared-route-group-child-list-component class="block-option" [routeId]="route.routeId">
        </shared-route-group-child-list-component>
    </ng-container>

</div>