import { Location } from '@angular/common';
import { Component, Inject, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FilterStripDto } from '@shared/src/components/core/maintenance/filter/FilterStripDtos';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { SocketController } from '@shared/src/controllers/socket/socket.controller';
import { HDateHour } from "@shared/src/datatypes/HDateHour";
import { HString } from '@shared/src/datatypes/HString';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SharedAsideFactory } from '../../aside/shared.aside.factory';
import { TruckingController } from '@shared/src/controllers/trucking/trucking.controller';
import { FilterTruckingShipmentPackageDto } from '@shared/src/dtos/trucking/FilterTruckingShipmentPackageDto';
import { SharedActiveRoutes } from '../../components.api';
import { TruckingShipmentDto } from '@shared/src/dtos/trucking/TruckingShipmentDto';
import { FilterDto } from '../../core/maintenance/filter/FilterDtos';
import { TruckingRouteExpandDto } from '@shared/src/dtos/trucking/TruckingRouteExpandDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { TruckingShipmentPackageDto } from '@shared/src/dtos/trucking/TruckingShipmentPackageDto';


@Component({
	selector: 'shared-trucking-shipments',
	templateUrl: './shared.trucking.shipments.html',
	styleUrls: ['./shared.trucking.shipments.scss']
})
export class SharedTruckingShipments implements OnInit {


	searchTextUpdate = new Subject<string>();

	public static SLICECOUNTINITIAL: number = 100;
	public static SLICECOUNTMORE: number = 100;

	public useIonic: boolean = false;
	constructor(public activatedRoute: ActivatedRoute,
		public router: Router, public location: Location, public truckingController: TruckingController,
		@Inject('HomeController') public homeController: HomeController,
		@Inject("SecurityController") public securityController: SecurityController,
		@Inject('SharedAsideFactory') public sharedAsideFactory: SharedAsideFactory,
		public socketController: SocketController,
		public translateService: TranslateService) {

		// Debounce search.
		this.searchTextUpdate.pipe(
			debounceTime(HomeController.DEFAULT_BOUNCE_BUTTON_TIME),
			distinctUntilChanged())
			.subscribe(value => {
				//Abans d'anar a la base de dades, hem de tenir mínim 3 digits o que el searchtext sigui "" (voldrà dir que borra tot per tornar a començar)
				if (HString.isNullOrNullString(this.searchText))
					this.loadData(true, false);
				else if (this.searchText.length > 2)
					this.loadExactKeyData(false);
			});

		if (homeController.useIonic)
			this.useIonic = homeController.useIonic();

		this.truckingController.refreshShipmentList$.subscribe((data: boolean) => {
            if(data)
				this.loadData(true, false);
		});
	}

    @Input()
    public selectedRouteExpand: TruckingRouteExpandDto;

	public searchText: string = "";
	public searchText2: string = "";

	public filter: FilterStripDto;// = new FilterStripDto();
	public filterSearchByText: FilterTruckingShipmentPackageDto = FilterTruckingShipmentPackageDto.buildPage("", []);
	
	public shipments: Array<TruckingShipmentDto> = [];
	public shipmentsExactKey: Array<TruckingShipmentDto> = [];
	
	public filterMetadataShipmentExactKey: any = {};
	public filterMetadataShipment: any = {};
	
	public packages: Array<TruckingShipmentPackageDto> = [];
	public packagesExactKey: Array<TruckingShipmentPackageDto> = [];

	public filterMetadataPackageExactKey: any = {};
	public filterMetadataPackage: any = {};

	ngOnInit(): void {
		this.sliceCount = SharedActiveRoutes.SLICECOUNTINITIAL;

		this.optionsShowMode = new OptionsStripDto();
		this.optionsShowMode.add(OptionDto.build("PACKAGES", "TRUCKING.SHIPMENT.OPTION.PACKAGES.CAPTION", "TRUCKING.SHIPMENT.OPTION.PACKAGES.HELP", "", 1, -1, 1, false, false));
    	this.optionsShowMode.add(OptionDto.build("SHIPMENTS", "TRUCKING.SHIPMENT.OPTION.SHIPMENTS.CAPTION", "TRUCKING.SHIPMENT.OPTION.SHIPMENTS.HELP", "", 2, -1, 1, true, false));
   
		this.optionShowMode = this.optionsShowMode.getActualSelected().optionId;

		this.filter = new FilterStripDto();
		this.filter.add(FilterDto.build("TRUCKING.SHIPMENT.FILTER.PENDING", "TRUCKING.SHIPMENT.FILTER.PENDING.CAPTION", "TRUCKING.SHIPMENT.FILTER.PENDING.HELP", 1, false, ["TRUCKING.SHIPMENT.FILTER.ALL", "TRUCKING.SHIPMENT.FILTER.NOCONFIRMED"]));
        this.filter.add(FilterDto.build("TRUCKING.SHIPMENT.FILTER.NOCONFIRMED", "TRUCKING.SHIPMENT.FILTER.NOCONFIRMED.CAPTION", "TRUCKING.SHIPMENT.FILTER.NOCONFIRMED.HELP", 2, false, ["TRUCKING.SHIPMENT.FILTER.ALL", "TRUCKING.SHIPMENT.FILTER.PENDING"]));
		this.filter.add(FilterDto.build("TRUCKING.SHIPMENT.FILTER.ALL", "TRUCKING.SHIPMENT.FILTER.ALL.CAPTION", "TRUCKING.SHIPMENT.FILTER.ALL.HELP", 3, true, ["TRUCKING.SHIPMENT.FILTER.PENDING", "TRUCKING.SHIPMENT.FILTER.NOCONFIRMED"]));
        
		this.loadData(true, false);
	}

	ngAfterViewChecked(): void {
	}

	public optionShowMode: string;
	public optionsShowMode: OptionsStripDto;
	onClickOption(option: OptionDto) {
		this.optionShowMode = option.optionId;

		if(HString.equals(this.optionShowMode, "SHIPMENTS")){
			this.packages = new Array();
			this.packagesExactKey = new Array();
		} else if(HString.equals(this.optionShowMode, "PACKAGES")){
			this.shipments = new Array();
			this.shipmentsExactKey = new Array();
		}

		this.loadData(true, false);
	}

	loadFilterOfSearchByText() {
		if (this.filter != null)
			this.filterSearchByText.filterKeys = FilterStripDto.getActiveKeys(this.filter);
	}

	loadExactKeyData(forcedsearch: boolean) {

		this.loadFilterOfSearchByText();

		if (!HString.isNullOrNullString(this.searchText)) {
			this.shipmentsExactKey = null;
			this.filterSearchByText.searchText = this.searchText;
			if(HString.equals(this.optionShowMode, "SHIPMENTS")){
				this.truckingController.getTruckingShipmentsExactKey(this.filterSearchByText).subscribe(data => {

					if (data == null) {
						this.shipments = new Array();
						return;
					}
					this.shipmentsExactKey = data.shipments;
				});
			} else if(HString.equals(this.optionShowMode, "PACKAGES")){
				this.truckingController.getTruckingPackagesExactKey(this.filterSearchByText).subscribe(data => {

					if (data == null) {
						this.packages = new Array();
						return;
					}
					this.packagesExactKey = data.packages;
				});
			}
		}
		else {
			this.shipmentsExactKey = null;
		}
		this.loadData(true, false);
	}

	public loading: boolean = false;
	loadData(forcedsearch: boolean, busy: boolean) {

		//QUAN COMENCEM A BUSCAR, posem el searchtext2 igual al searchText per a que doni la sensació que busca
		this.searchText2 = this.searchText;

		this.loadFilterOfSearchByText();

		this.filterSearchByText.searchText = this.searchText;
		this.filterSearchByText.idReserca = HDateHour.toStringSorteable(HDateHour.now());
		this.filterSearchByText.sliceCount = this.sliceCount;
		if(HString.equals(this.optionShowMode, "SHIPMENTS")){
			this.loadShipments(forcedsearch, busy);
		} else if(HString.equals(this.optionShowMode, "PACKAGES")){
			this.loadPackages(forcedsearch, busy);
		}
	}

	loadShipments(forcedsearch: boolean, busy: boolean){
		this.truckingController.getTruckingShipments(this.filterSearchByText).subscribe(data => {

			if (data == null) {
				this.shipments = new Array();
				return;
			}

			//Si no es la última petició feta, obiem la resposta
			if (!HString.equals(data.idReserca, this.filterSearchByText.idReserca)) {
				return;
			}

			this.shipments = data.shipments;

			this.elementsCount = data.elementsAllCount;
			if (this.shipments != null) {
				this.itemsCount = data.elementsAllCount;

				//comprovem si la seleccionada encara esta
				if (this.selectedShipment != null) {
					let findselected = false;
					let i = 0;
					while (i < this.shipments.length) {
						if (this.shipments[i].shipmentId === this.selectedShipment.shipmentId) {
							findselected = true;
						}
						if (findselected) {
							i = this.shipments.length;
						}
						i++;
					}
					//Si la seleccionada ja no està a la llista, la resetejem
					if (!findselected)
						this.selectedShipment = null;
				}
			} else {
				this.selectedShipment = null;
			}

			this.loadFilterOfSearchByText();

			//Quan acabem de buscar ja no cal filtrar en local, ja ho ha fet el servidor
			this.searchText2 = "";

		});
	}

	loadPackages(forcedsearch: boolean, busy: boolean){
		this.truckingController.getTruckingPackages(this.filterSearchByText).subscribe(data => {
			
			if (data == null) {
				this.packages = new Array();
				return;
			}

			//Si no es la última petició feta, obiem la resposta
			if (!HString.equals(data.idReserca, this.filterSearchByText.idReserca)) {
				return;
			}

			this.packages = data.packages;

			this.elementsCount = data.elementsAllCount;
			if (this.packages != null) {
				this.itemsCount = data.elementsAllCount;

				//comprovem si la seleccionada encara esta
				if (this.selectedPackage != null) {
					let findselected = false;
					let i = 0;
					while (i < this.packages.length) {
						if (this.packages[i].packageId === this.selectedPackage.packageId) {
							findselected = true;
						}
						if (findselected) {
							i = this.packages.length;
						}
						i++;
					}
					//Si la seleccionada ja no està a la llista, la resetejem
					if (!findselected)
						this.selectedPackage = null;
				}
			} else {
				this.selectedPackage = null;
			}

			this.loadFilterOfSearchByText();

			//Quan acabem de buscar ja no cal filtrar en local, ja ho ha fet el servidor
			this.searchText2 = "";

		});
	}

	public elementsCount: number = 0;
	public _selectedShipment: TruckingShipmentDto;
	public get selectedShipment(): TruckingShipmentDto {
		return this._selectedShipment;
	}
	public set selectedShipment(value: TruckingShipmentDto) {
		if (this._selectedShipment === value)
			return;
		this._selectedShipment = value;
	}
	public _selectedPackage: TruckingShipmentPackageDto;
	public get selectedPackage(): TruckingShipmentPackageDto {
		return this._selectedPackage;
	}
	public set selectedPackage(value: TruckingShipmentPackageDto) {
		if (this._selectedPackage === value)
			return;
		this._selectedPackage = value;
	}

	public itemsCount = 0;
	public sliceCount: number = SharedActiveRoutes.SLICECOUNTINITIAL;
	public onSliceMore() {
		this.sliceCount = this.sliceCount + SharedActiveRoutes.SLICECOUNTMORE;
		this.loadData(true, false);
	}

	getShipments() {
		if (this.shipments != null) {
			return this.shipments;
		}
		return new Array();
	}

	getPackages() {
		if (this.packages != null) {
			return this.packages;
		}
		return new Array();
	}

	pretty(value) {
		return JSON.stringify(value);
	}


	onSearchFocus(event: any) {
		if (event && event.stopPropagation)
			event.stopPropagation();

	}

	onSearchClick(event: any) {
		if (event && event.stopPropagation)
			event.stopPropagation();
	}


	filterUpdate() {
		this.selectedShipment = null;
		this.loadData(true, true);
	}

	@Output() clickShipmen = new EventEmitter<TruckingShipmentDto>();
	onClickShipment(item: TruckingShipmentDto) {
		if (item == null) {
			this.selectedShipment = null;
			this.clickShipmen.next(this.selectedShipment);
			return;
		}
		this.selectedShipment = item;
		this.clickShipmen.next(this.selectedShipment);
	}

	@Output() clickPackage = new EventEmitter<TruckingShipmentPackageDto>();
	onClickPackage(item: TruckingShipmentPackageDto) {
		if (item == null) {
			this.selectedPackage = null;
			this.clickPackage.next(this.selectedPackage);
			return;
		}
		this.selectedPackage = item;
		this.clickPackage.next(this.selectedPackage);
	}

	onClickBack(refresh: boolean) {
		this.selectedShipment = null;
		this.selectedPackage = null;
		if (refresh){
			this.loadData(true, false);
			return;
		}
		if (this.optionShowMode == "SHIPMENTS" && (this.shipments == null || this.shipments.length == 0)){
			this.loadData(true, false);
			return;
		}
		if (this.optionShowMode == "PACKAGES" && (this.packages == null || this.packages.length == 0)){
			this.loadData(true, false);
			return;
		}
	}

}
