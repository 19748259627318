import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, LoadingService } from '@shared/src/public-api';
import { BaseController } from '../../base.controller';
import { IntegrationBackOfficeDto } from './IntegrationBackOfficeDto';
import { IntegrationBackOfficeFilterDto } from './IntegrationBackOfficeFilterDto';
@Injectable({
    providedIn: 'root',
})
export class IntegrationBackOfficeController extends BaseController {

    private base = '/internal/integrationbackoffice'
    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        protected router: Router,
        @Inject('LoadingService') protected loadingService: LoadingService) {
        super(http, alertService, translateService, loadingService, router);

    }
    public getInitialIntegrationBackOffice(): Observable<IntegrationBackOfficeDto> {
        return this.get(this.base + "/");
    }
    public getIntegrationBackOffice(filter: IntegrationBackOfficeFilterDto): Observable<IntegrationBackOfficeDto> {
        return this.put(this.base + "/", filter, "");
    }

}