export enum TotemSteps {
    language = "language",
    action = "action",
    operation = "operation",
    identification = "identification",
    idverification = "idverification",
    addMore = "addMore",
    none = "none",
    entered = "entered",
    enterFinish = "enterFinish",
    enterVerifyDriverPhone = "enterVerifyDriverPhone",
    enterVerifyPlateA = "enterVerifyPlateA",
    enterVerifyPlateB = "enterVerifyPlateB",
    enterVerifyDriversName = "enterVerifyDriversName",
    enterVerifyDriversLegalId = "enterVerifyDriversLegalId",
    enterVerifyDriversCarrierName = "enterVerifyDriversCarrierName",
    enterVerifyDriversCarrierLegalId = "enterVerifyDriversCarrierLegalId",
    enterVerifyDriversCarrierAddress = "enterVerifyDriversCarrierAddress",
    slotWithSlot = "slotWithSlot",
    slotYourAreLate = "slotYourAreLate",
    slotWithOutSlot = "slotWithOutSlot",
    slotGoDock = "slotGoDock",
    slotGoParking = "slotGoParking",
    signatureBloked = "signatureBloked",
    signature = "signature",
    signatureEnd = "signatureEnd",
    signed = "signed",
    estaDins = "estaDins",
    exitBloked = "exitBloked",
    exitGate = "exitGate",
    exitGoOut = "exitGoOut",
    exited = "exited"
}