import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SlotsController } from '@shared/src/controllers/address/slots/slots.controller';
import { SlotChangeReasonMaintenanceController } from '@shared/src/controllers/maintenance/reason.slotchange/SlotChangeReasonMaintenanceController';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { PhaseController } from '@shared/src/controllers/route/phase/phase.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { OpenResource } from '@shared/src/dtos/open/OpenResource';
import { PhaseSlotChangeReasonDataInfoDto } from '@shared/src/dtos/phase/slot/manage/reason/PhaseSlotChangeReasonDataInfoDto';
import { SlotChangeReasonItemDto } from '@shared/src/dtos/slotchangereason/SlotChangeReasonItemDto';
import { PhaseSlotChangeReasonManageDto } from '@shared/src/dtos/phase/slot/manage/reason/PhaseSlotChangeReasonManageDto';
import { PhaseSlotChangeReasonActionTypes } from '@shared/src/enums/PhaseSlotChangeReasonActionTypes';
import { DateFilter, HDate, HHour, HHourInterval, HString } from '@shared/src/public-api';

@Component({
  selector: 'shared-phase-slot-change-reason-manage-component',
  templateUrl: './shared.phase.slot.change.reason.manage.component.html',
  styleUrls: ['./shared.phase.slot.change.reason.manage.component.scss'],
})
export class SharedPhaseSlotChangeReasonManageComponent implements OnInit {

  constructor(public phaseController: PhaseController, public slotChangeReasonMaintenanceController: SlotChangeReasonMaintenanceController, public slotsController: SlotsController, public openRouteController: OpenRouteController, protected translateService: TranslateService) {
  }

  @Input() public readonly: boolean = false;
  public value: PhaseSlotChangeReasonManageDto;
  public objTranslate: any;

  public SLOTCHANGEREASON_FILTERKEYS: Array<string> = ['SLOTCHANGEREASON.NOTHING.FILTER'];

  private _phaseId: number;
  @Input() set phaseId(value: number) {
    if (this._phaseId === value)
      return;
    this._phaseId = value;
    this.loadData();
  }
  get phaseId(): number {
    return this._phaseId;
  }

  private _dataInfo: PhaseSlotChangeReasonDataInfoDto;
  @Input() set dataInfo(value: PhaseSlotChangeReasonDataInfoDto) {
    if (this._dataInfo === value)
      return;
    this._dataInfo = value;
    this.loadData();
  }
  get dataInfo(): PhaseSlotChangeReasonDataInfoDto {
    return this._dataInfo;
  }

  ngOnInit() {

  }

  loadData() {

    if (this.phaseId == null || this.dataInfo == null)
      return;

    if(this.dataInfo.action == PhaseSlotChangeReasonActionTypes.SwapSlot && this.dataInfo.slotAction != null){
      this.phaseController.getSlotChangeReasonManageBySwap(this.dataInfo.slotAction).subscribe(data => {
        this.welcome(data);
      });
    }else if(this.dataInfo.action == PhaseSlotChangeReasonActionTypes.ObtainSlot && this.dataInfo.slotAction != null){
      this.phaseController.getSlotChangeReasonManageByObtain(this.dataInfo.slotAction).subscribe(data => {
        this.welcome(data);
      });
    }else {
      this.phaseController.getSlotChangeReasonManage(this.phaseId, this.dataInfo.action).subscribe(data => {
        this.welcome(data);
      });
    }

  }

  welcome(data: PhaseSlotChangeReasonManageDto) {
    this.value = data;
    if (this.value != null && this.value.phaseSlot != null) {
      this.objTranslate = {};

      this.objTranslate.weekDay = this.translateService.instant(HDate.getDayOfWeek(this.value.phaseSlot.onDateSlot));
      this.objTranslate.date = DateFilter.getTransform(this.value.phaseSlot.onDateSlot);
      this.objTranslate.lapseTime = HHourInterval.toScreenString(this.value.phaseSlot.intervalSlot);

      this.objTranslate.dateOperant = HHour.getHourString(HHourInterval.getStarts(this.value.phaseSlot.intervalSlot));
      this.objTranslate.hourOperant = HHour.getHourString(HHourInterval.getEnds(this.value.phaseSlot.intervalSlot));
    }
    this.loadNextSlotChangeReason();
  }

  loadNextSlotChangeReason() {
    if (this.value == null || this.value.slotChangeReasons == null || this.value.slotChangeReasons.length == 0) {
      this.incompletReasons = true;
      this.incompletActualReasons = true;
      this.disabledButton = true;
      this.tenantsIncompletReason = new Array();
      return;
    }

    let reason = null;
    this.value.slotChangeReasons.forEach(element => {
      if (reason == null) {
        if (HLong.isNullOrNullLong(element.slotChangeReasonId))
          reason = element;
        else if (element.isRequiredComment && HString.isNullOrNullString(element.comment))
          reason = element;
      }
    });

    this.phaseSlotChangeReasonItem = reason;

    this.refreshIncompleteReasons();

    this.SLOTCHANGEREASON_FILTERKEYS = this.phaseSlotChangeReasonItem != null ? ['SLOTCHANGEREASON.BYTENANTID.FILTER:' + this.phaseSlotChangeReasonItem.tenantId, 'SLOTCHANGEREASON.FILTER.NOTISSYSTEM'] : ['SLOTCHANGEREASON.NOTHING.FILTER'];
  }

  private _phaseSlotChangeReasonItem: SlotChangeReasonItemDto;
  set phaseSlotChangeReasonItem(value: SlotChangeReasonItemDto) {
    if (this._phaseSlotChangeReasonItem === value)
      return;
    this._phaseSlotChangeReasonItem = value;
  }
  get phaseSlotChangeReasonItem(): SlotChangeReasonItemDto {
    return this._phaseSlotChangeReasonItem;
  }

  public comment: string = "";
  public slotChangeReasonChangedEvent(value: number) {
    if (value != null && this.phaseSlotChangeReasonItem != null) {

      this.slotChangeReasonMaintenanceController.getSlotChangeReasonItemByReasonId(value).subscribe(data  => {
        if (data != null) {
          this.phaseSlotChangeReasonItem.isRequiredComment = data.isRequiredComment;
          this.refreshIncompleteReasons();

        }
      });

    }
  }

  getResourceKeyCaption(){
    if (this.dataInfo == null)
      return "SHARED.PHASESLOTCHANGEREASONMANAGE.CANCELSLOT.CAPTION";
    return "SHARED.PHASESLOTCHANGEREASONMANAGE." + this.dataInfo.action.toUpperCase() + ".CAPTION";
  }

  getResourceKeyComments(){

    if (this.phaseSlotChangeReasonItem != null && this.phaseSlotChangeReasonItem.isRequiredComment)
      return "SHARED.SLOTCHANGEREASONMANAGE.COMMENTS.REQUIREPLACEHOLDER";
    return "SHARED.SLOTCHANGEREASONMANAGE.COMMENTS.PLACEHOLDER";

  }

  getResourceButton() {
    var base: string = "SHARED.PHASESLOTCHANGEREASONMANAGE.";
  
    if(this.phaseSlotChangeReasonItem != null){
      if (this.value.slotChangeReasons.length > 1 && this.tenantsIncompletReason.length > 0 && !this.tenantsIncompletReason.includes(this.phaseSlotChangeReasonItem.tenantId)) 
        return base + "NEXT";
    }
     
    return base + this.dataInfo.action.toUpperCase() + ".BUTTON";
  }

  changeComment(value: string) {
    this.comment = value;
    this.refreshIncompleteReasons();
  }

  public disabledButton: boolean = true;
  public incompletReasons: boolean = true;
  public incompletActualReasons: boolean = true;
  public tenantsIncompletReason: number[];
  refreshIncompleteReasons() {
    
    if (this.value == null || this.value.slotChangeReasons == null || this.value.slotChangeReasons.length == 0) {
      this.incompletReasons = true;
      this.incompletActualReasons = true;
      this.disabledButton = true;
      this.tenantsIncompletReason = new Array();
      return;
    }

    //Traspassem a la llista les dades informades
    if(this.phaseSlotChangeReasonItem != null){
      this.value.slotChangeReasons.forEach(element => {
        if (this.phaseSlotChangeReasonItem.tenantId == element.tenantId) {
          element.slotChangeReasonId = this.phaseSlotChangeReasonItem.slotChangeReasonId;
          element.comment = this.phaseSlotChangeReasonItem.comment;
          element.isRequiredComment = this.phaseSlotChangeReasonItem.isRequiredComment;
        }
      });
    }

    let reason: number[] = new Array();

    this.value.slotChangeReasons.forEach(element => {
      if (HLong.isNullOrNullLong(element.slotChangeReasonId))
        reason.push(element.tenantId);
      else if (element.isRequiredComment && HString.isNullOrNullString(element.comment))
        reason.push(element.tenantId);
    });

    this.incompletActualReasons = this.phaseSlotChangeReasonItem != null && (HLong.isNullOrNullLong(this.phaseSlotChangeReasonItem.slotChangeReasonId) || (this.phaseSlotChangeReasonItem.isRequiredComment && HString.isNullOrNullString(this.comment)));
   
    this.disabledButton = this.incompletActualReasons;

    this.incompletReasons = reason.length > 0;
    this.tenantsIncompletReason = reason;

  }


  doPreAction(event) {
    this.loadNextSlotChangeReason();

    if (this.phaseSlotChangeReasonItem == null && !this.incompletReasons) {
      this.doAction();
    }
  }


  @Output()
  public onSaveReason: EventEmitter<any> = new EventEmitter();

  onFinishDoAction(value: Boolean){
    if(value)
      this.onSaveReason.next(true);
    else
      this.loadData();
  }
  
  doAction() {

    if(this.dataInfo == null || this.value == null)
      return;

    if(this.dataInfo.action == PhaseSlotChangeReasonActionTypes.CancelSlot){
        if(this.dataInfo.slotAction != null){
          this.slotsController.releaseSlot(this.dataInfo.slotAction, this.value.slotChangeReasons, this.dataInfo.busy).subscribe((data) => {
            this.onFinishDoAction(data);
          });
    
        }else{
          this.openRouteController.phaseReleaseSlot(this.phaseId, this.dataInfo.fromActiveRoutes, this.value.slotChangeReasons).subscribe((data: boolean) => {
            this.onFinishDoAction(data);
          });
        }
    }else if(this.dataInfo.action == PhaseSlotChangeReasonActionTypes.CancelSlotExwork){
   
      this.openRouteController.clearExworkPhaseSlot(this.dataInfo.uuid, this.phaseId, this.value.slotChangeReasons).subscribe(data => {
        this.onFinishDoAction(data);
      });

    }else if(this.dataInfo.action == PhaseSlotChangeReasonActionTypes.MoveSlot){
      if(this.dataInfo.slotAction == null)
        return;

      this.slotsController.moveSlots(this.dataInfo.slotAction, this.value.slotChangeReasons, this.dataInfo.busy).subscribe(data => {
        this.onFinishDoAction(data);
      });

    }else if(this.dataInfo.action == PhaseSlotChangeReasonActionTypes.SwapSlot){
      if(this.dataInfo.slotAction == null)
        return;

      this.slotsController.swapSlots(this.dataInfo.slotAction, this.value.slotChangeReasons, this.dataInfo.busy).subscribe(data => {
        this.onFinishDoAction(data);
      });

    }else if(this.dataInfo.action == PhaseSlotChangeReasonActionTypes.ObtainSlot){
      if(this.dataInfo.slotAction == null)
        return;

      this.slotsController.obtainSlot(this.dataInfo.slotAction, this.value.slotChangeReasons, this.dataInfo.busy).subscribe(data => {
        this.onFinishDoAction(data);
      });
    }else if(this.dataInfo.action == PhaseSlotChangeReasonActionTypes.SetSlot){
      
      if(this.dataInfo.slotAction != null){

        this.slotsController.assignSlots(this.dataInfo.slotAction, this.value.slotChangeReasons, this.dataInfo.busy).subscribe(data => {
          this.onFinishDoAction(data);
        });

      }else if(this.dataInfo.addressinterval != null){
        if (this.dataInfo.addressinterval.addressTimetableIntervalId != null){
          this.openRouteController.phaseAllocateSlot(this.phaseId, this.dataInfo.addressinterval.addressTimetableIntervalId, this.value.slotChangeReasons).subscribe((data: Boolean) => {
            this.onFinishDoAction(data);
          });
        }else if (this.dataInfo.addressinterval.addressDaySettingResourceId != null) {
          this.openRouteController.phaseAllocateSlotByResource(this.phaseId, this.dataInfo.addressinterval.addressDaySettingResourceId, OpenResource.build(this.dataInfo.addressinterval), this.value.slotChangeReasons).subscribe((data: Boolean) => {
            this.onFinishDoAction(data);
          });
        }
      }
    
    }else if(this.dataInfo.action == PhaseSlotChangeReasonActionTypes.SetSlotExwork){
      if(this.dataInfo.addressinterval != null){
        if (!HLong.isNullOrNullLong(this.dataInfo.addressinterval.addressDaySettingResourceId)) {
          this.openRouteController.setExworkPhaseSlotByResource(this.dataInfo.uuid, this.phaseId, this.dataInfo.addressinterval.addressDaySettingResourceId, OpenResource.build(this.dataInfo.addressinterval), this.value.slotChangeReasons).subscribe(data => {
            this.onFinishDoAction(data);
          });
        } else if (!HLong.isNullOrNullLong(this.dataInfo.addressinterval.addressTimetableIntervalId)) {
            this.openRouteController.setExworkPhaseSlot(this.dataInfo.uuid, this.phaseId, this.dataInfo.addressinterval.addressTimetableIntervalId, this.value.slotChangeReasons).subscribe(data => {
              this.onFinishDoAction(data);
            });
        }
      }
    }else if(this.dataInfo.action == PhaseSlotChangeReasonActionTypes.ReserveOutOfHours){
      if(this.dataInfo.reserveOutOfHours == null)
        return;

      this.openRouteController.phaseReserveOutOfHours(this.phaseId, this.dataInfo.reserveOutOfHours, this.value.slotChangeReasons).subscribe((data: boolean) => {
        this.onFinishDoAction(data);
      });
    }    
}

public pretty(value) {
    return JSON.stringify(value);
  }
}
