import { Output, EventEmitter, Inject } from '@angular/core';
import { Component, Input, OnInit, NgZone, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { DragDropService } from '../../../services/dragdrop/DragDropService';
import { fromEvent as observableFromEvent } from 'rxjs';
import { HLong } from '@shared/src/datatypes/HLong';
import { HTags } from '@shared/src/datatypes/HTags';
import { HColor } from '@shared/src/datatypes/HColor';
import { HString } from '@shared/src/datatypes/HString';
import { SharedAsideFactory } from '../../aside/shared.aside.factory';
import { DocksController } from '@shared/src/controllers/address/docks.controller';
import { SlotsController } from '@shared/src/controllers/address/slots/slots.controller';
import { DockDto } from '@shared/src/dtos/address/dock/DockDto';
import { AlertService } from '@shared/src/public-api';
import { LoadConsoleSlotPhaseDto } from '@shared/src/dtos/loadconsole/LoadConsoleSlotPhaseDto';
import { SlotInfoDto } from '@shared/src/dtos/address/slots/SlotInfoDto';
import { PhaseSlotDto } from '@shared/src/dtos/phase/slot/PhaseSlotDto';

@Component({
  selector: 'shared-phase-slot',
  templateUrl: './shared.phase.slot.component.html',
  styleUrls: ['./shared.phase.slot.component.scss'],
})
export class SharedPhaseSlotComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private ele: ElementRef,
    public dockController: DocksController,
    public slotsController: SlotsController,
    public dragDropService: DragDropService,
    private ngZone: NgZone,
    @Inject('SharedAsideFactory')
    public sharedAsideFactory: SharedAsideFactory,
    @Inject('AlertService') public alertService: AlertService) {
    //https://stackoverflow.com/questions/43108155/angular-2-how-to-keep-event-from-triggering-digest-loop-detection-cycle
    this.ngZone.runOutsideAngular(() => {
      observableFromEvent(ele.nativeElement, "dragover")
        .subscribe((event: DragEvent) => {
          this.dragDropService.onDragOver(null, null, event);
        }
        );
    });
  }

  ngOnInit() { }

  onDragEnter(event: DragEvent, item: PhaseSlotDto) {
    if (!item)
      return;

  }
  onDragLeave(event: DragEvent, item: PhaseSlotDto) {
    if (!item)
      return;

  }

  public onDragStart(event: DragEvent, item: PhaseSlotDto) {

    if (!this.isPhaseSlotDraggable)
      item = null;

    DragDropService.moverOrCopy(event);
    event.dataTransfer.setData(DragDropService.TEXTPLAIN, DragDropService.HARDMAN_PHASESLOTDTO);
    event.dataTransfer.setData(DragDropService.HARDMANTYPE, JSON.stringify(item));
  }

  drop(event: DragEvent, item: PhaseSlotDto) {
    if (!item)
      return;
    if (this.phaseSlotDroppable()) {
      this.dragDropService.onDrop(DragDropService.extractDraggedObject(event), DragDropService.createDropObject(item, DragDropService.HARDMAN_PHASESLOTDTO), event, this.onDropCallBack, this);
    }
  }

  @Output() onRefreshData: EventEmitter<any> = new EventEmitter<any>();

  public onDropCallBack(draggedobject: any, ondropobject: any, event: DragEvent, hardmanobjectdragged: any, hardmanobjectdropped: any, callbackparam?: any) {
    if (callbackparam && callbackparam.onRefreshData)
      callbackparam.onRefreshData.next();
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
  }

  @Input()
  public isdailydocks: boolean;

  @Input()
  public disableinvokephase: boolean;

  @Input()
  public invokeasroute: boolean = false;

  private _action: string;
  @Input() public set action(value: string) {
    if (this._action === value)
      return;
    this._action = value;
    this.setPhaseSlotDraggable();
    this.refreshProperties();
  }
  public get action(): string {
    return this._action;
  }

  private _canalterelement: boolean = true;
  @Input() public set canalterelement(value: boolean) {
    if (this._canalterelement === value)
      return;
    this._canalterelement = value;
    this.setPhaseSlotDraggable();
    this.refreshProperties();
  }
  public get canalterelement(): boolean {
    return this._canalterelement;
  }

  public slotInfo: SlotInfoDto;
  private _phaseslot: PhaseSlotDto;
  @Input() public set phaseslot(value: PhaseSlotDto) {
    if (this._phaseslot === value)
      return;
    this._phaseslot = value;
    this.setPhaseSlotDraggable();
    this.setInternalTags();
    this.refreshProperties();
    this.slotInfo = SlotInfoDto.buildFromPhaseSlot(this._phaseslot);
  }
  public get phaseslot(): PhaseSlotDto {
    return this._phaseslot;
  }

  private _dockToAssigned: DockDto;
  @Input() public set dockToAssigned(value: DockDto) {
    if (this._dockToAssigned === value)
      return;
    this._dockToAssigned = value;
    this.refreshProperties();
  }
  public get dockToAssigned(): DockDto {
    return this._dockToAssigned;
  }


  private _loadConsoleSlotPhase: LoadConsoleSlotPhaseDto;
  @Input()
  set loadConsoleSlotPhase(value: LoadConsoleSlotPhaseDto) {
    if (this._loadConsoleSlotPhase === value)
      return;
    this._loadConsoleSlotPhase = value;
  }
  get loadConsoleSlotPhase(): LoadConsoleSlotPhaseDto {
    return this._loadConsoleSlotPhase;
  }

  public internalTags: String = "";
  setInternalTags() {
    this.internalTags = HTags.convertToString(this._phaseslot.internalTags);
  }

  public isPhaseSlotDraggable: boolean
  setPhaseSlotDraggable() {
    if (!this.canalterelement)
      this.isPhaseSlotDraggable = false;
    else if ((this.action == PhaseSlotDto.ACTION_ASSIGN || PhaseSlotDto.ACTION_ASSIGN_DOCK || this.action == PhaseSlotDto.ACTION_NONACCEPTANCE || this.action == PhaseSlotDto.ACTION_RETURNS || this.action == PhaseSlotDto.ACTION_INBOUNDS) && this.phaseslot != null && HLong.isNullOrNullLong(this.phaseslot.slotId))
      this.isPhaseSlotDraggable = true;
    else if ((this.action == PhaseSlotDto.ACTION_MOVE || this.action == PhaseSlotDto.ACTION_SWAP || PhaseSlotDto.ACTION_ASSIGN_DOCK) && this.phaseslot != null && !HLong.isNullOrNullLong(this.phaseslot.slotId))
      this.isPhaseSlotDraggable = true;
    else
      this.isPhaseSlotDraggable = false;
  }

  phaseSlotDroppable() {
    return this.action == PhaseSlotDto.ACTION_SWAP && this.canalterelement;
  }

  onDoubleClick() {
    if (this._phaseslot == null || this.disableinvokephase)
      return;
    this.sharedAsideFactory.invokeActiveRoutePhase(this._phaseslot.routeId, this._phaseslot.phaseId).then(value => {
      this.onRefreshData.next();
    });
    return;
    //TODO:OSCAR
    if (this.invokeasroute)
      this.sharedAsideFactory.invokeRoute(this._phaseslot.routeId).then(value => { });
    else
      this.sharedAsideFactory.invokePhase(this._phaseslot.phaseId).then(value => { });
  }

  @Output() onSlotSelected: EventEmitter<any> = new EventEmitter<any>();
  slotSelected() {
    if (this.action == PhaseSlotDto.ACTION_ASSIGN_DOCK) {
      if (this._phaseslot != null && this._phaseslot.phaseId > 0 && this.dockToAssigned != null) {
        if (this.dockToAssigned.dockId > 0) {
          this.dockController.setDockIdToPhaseId(this._phaseslot.phaseId, this.dockToAssigned.dockId).subscribe((data: Boolean) => {
            if (data) {
              if (this.isdailydocks)
                this.onRefreshData.next();
              else
                this.refreshSlotPlanning();
            }
          });
        } else if (!HString.isNullOrNullString(this.dockToAssigned.skin)) {
          this.dockController.setDockToPhaseId(this._phaseslot.phaseId, this.dockToAssigned.skin).subscribe((data: Boolean) => {
            if (data) {
              if (this.isdailydocks)
                this.onRefreshData.next();
              else
                this.refreshSlotPlanning();
            }
          });
        }
      }
    } else
      this.onSlotSelected.next();
  }

  refreshSlotPlanning() {
    if (this._phaseslot.phaseId > 0) {
      this.slotsController.getPhaseSlotPlanningDetail(this._phaseslot.slotId, this._phaseslot.phaseId).subscribe((data) => {
        this.phaseslot = data;
        this.refreshProperties();
      });
    }
  }

  removePhaseDock() {
    if (this.action == PhaseSlotDto.ACTION_REMOVE_DOCK && this.canalterelement && this.isAssignedDock) {
      this.dockController.removeDockToPhaseId(this._phaseslot.phaseId).subscribe((data) => {
        if (this.isdailydocks)
          this.onRefreshData.next();
        else
          this.refreshSlotPlanning();
      });
    }
    return false;
  }

  public isAssignedDock: boolean;
  setIsAssignedDock() {
    this.isAssignedDock = this._phaseslot != null && !HString.isNullOrNullString(this._phaseslot.dockSkin);
  }

  public isRemoveDock: boolean;
  setIsRemoveDock() {
    this.isRemoveDock = this.action == PhaseSlotDto.ACTION_REMOVE_DOCK && this.canalterelement && this.isAssignedDock;
  }

  refreshProperties() {
    this.setIsAssignedDock();
    this.setIsRemoveDock();
  }

  hasBigLength(disponibility: string) {
    return (disponibility.length > 5)
  }

  getTextColor(data: HColor) {
    if (data != null)
      return HColor.getWhiteBlackFromColor(data.web);

    return HColor.getWhiteBlackFromColor(HColor.defaultColor().web);
  }

  getBackColorWeb(data: HColor) {
    return HColor.getColorOrDefaultColor(data, HColor.defaultColor().web);
  }
  public pretty(value: any): string {
    return JSON.stringify(value);
  }

  public haveTags(tags: HTags): boolean {
    if (HTags.isEmpty(tags))
      return false;
    return true;
  }

  copy(text: string) {
    this.alertService.copyToClipboard(text).subscribe();
  }

  public getGeneralClass(): string {

    let generalClass = "";
    if (this.phaseslot.overbooked)
      generalClass += "slotOverbooked ";
    if (this.isRemoveDock)
      generalClass += "isRemoveDock ";
    if (this.phaseslot.isSlotBlocked || this.phaseslot.slotId)
      generalClass += "slot ";
    if (this.phaseslot.invalidReservation)
      generalClass += "invalidReservation ";

    generalClass += HTags.convertToStringWithDelimiter(this._phaseslot.routeInternalTags, " ");


    return generalClass;
  }
}
