import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from "@angular/router";
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { SocketController } from '@shared/src/controllers/socket/socket.controller';
import { SystemController } from '@shared/src/controllers/system/system.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { OpenRouteChangeDriverInfoAndPlatesDto } from '@shared/src/dtos/open/OpenRouteChangeDriverInfoAndPlatesDto';
import { RouteCardItemExpandDto } from '@shared/src/dtos/route/RouteCardItemExpandDto';
import { AlertService, SharedSaveButtonEvent } from '@shared/src/public-api';

@Component({
    selector: 'shared-active-slots-item-driverplate',
    templateUrl: './shared.active.slots.item.driverplate.html',
    styleUrls: ['./shared.active.slots.item.driverplate.scss']
})
export class SharedActiveSlotsItemDriverPlate implements OnInit {

    constructor(protected socketController: SocketController, protected activatedRoute: ActivatedRoute, public dialog: MatDialog,
        public openRouteController: OpenRouteController, public fileUpDownloadController: FileUpDownloadController, public shipmentController: ShipmentController, public systemController: SystemController,
        @Inject('AlertService') public alertService: AlertService) {
    }

    ngOnInit(): void {
    }

    @Output() refresh = new EventEmitter<boolean>();

    private _route: RouteCardItemExpandDto;
    @Input()
    set route(value: RouteCardItemExpandDto) {
        if (this._route === value)
            return;
        this._route = value;
        this.editMode = false;
        if (this._route != null) {
            this.plateA = this._route.plateA;
            this.plateB = this._route.plateB;
            this.plateC = this._route.plateC;
            this.driverName = this._route.driverName;
            this.driverLegalIdentification = this._route.driverLegalIdentification;
            this.driverPhone = this._route.driverPhone;
            this.carrierCode = this._route.carrierCode;
            this.carrierName = this._route.carrierName;
            this.carrierLegalIdentification = this._route.carrierLegalIdentification;
            this.carrierAddress = this._route.carrierAddress;
        }

    }
    get route(): RouteCardItemExpandDto {
        return this._route;
    }

    @Input()
    public hideLink: boolean = false;

    @Input() public readonly: boolean;

    public editMode: boolean = false;
    public plateA: string;
    public plateB: string;
    public plateC: string;
    public driverName: string;
    public driverLegalIdentification: string;
    public driverPhone: string;
    public carrierCode: string;
    public carrierName: string;
    public carrierLegalIdentification: string;
    public carrierAddress: string;
    public startEditmode() {
        this.editMode = true;
    }

    @Input()
    public autoEdit: boolean = false;

    save($event: SharedSaveButtonEvent) {
        if (this.route == null || HLong.isNullOrNullLong(this.route.routeId)) {
            return;
        }
        this.openRouteController.setDriverInfoAndPlates(this.route.routeId, OpenRouteChangeDriverInfoAndPlatesDto.build(this.driverName != null ? this.driverName : "",
            this.driverLegalIdentification != null ? this.driverLegalIdentification : "",
            this.driverPhone != null ? this.driverPhone : "", this.plateA != null ? this.plateA : "",
            this.plateB != null ? this.plateB : "", this.plateC != null ? this.plateC : "", this.carrierCode != null ? this.carrierCode : "", this.carrierName != null ? this.carrierName : "", this.carrierLegalIdentification != null ? this.carrierLegalIdentification : "", this.carrierAddress != null ? this.carrierAddress : "")).subscribe(data => {
                if (data) {
                    this.refresh.next(true);
                }
                if ($event)
                    $event.callback();
                this.editMode = false;
            });
    }

    cancel() {
        this.editMode = false;
    }

    public pretty(value: any): string {
        return JSON.stringify(value);
    }

}
