import { Injectable, Inject } from "@angular/core";
import { AccessControlController } from "@shared/src/controllers/accesscontrol/accesscontrol.controller";
import { DocksController } from "@shared/src/controllers/address/docks.controller";
import { SlotsController } from "@shared/src/controllers/address/slots/slots.controller";
import { LoadConsoleController } from "@shared/src/controllers/loadconsole/loadconsole.controller";
import { OpenPanelController } from "@shared/src/controllers/open/open.panel.controller";
import { OpenRouteController } from "@shared/src/controllers/open/open.route.controller";
import { HLong } from "@shared/src/datatypes/HLong";
import { HString } from "@shared/src/datatypes/HString";
import { SetPlateGateDto } from "@shared/src/dtos/accesscontrol/SetPlateGateDto";
import { AccessControlGateDto } from "@shared/src/dtos/accesscontrol/gate/AccessControlGateDto";
import { AccessControlGatePlateDto } from "@shared/src/dtos/accesscontrol/gate/plate/AccessControlGatePlateDto";
import { DockSlotPhaseDto } from "@shared/src/dtos/address/dock/DockSlotPhaseDto";
import { SlotActionDto } from "@shared/src/dtos/address/slots/actions/SlotActionDto";
import { InternationalPlanningDayDto } from "@shared/src/dtos/booking/InternationalPlanningDayDto";
import { InternationalPlanningPhaseDto } from "@shared/src/dtos/booking/InternationalPlanningPhaseDto";
import { LoadConsoleArrivalDto } from "@shared/src/dtos/loadconsole/LoadConsoleArrivalDto";
import { LoadConsoleDockZoneDto } from "@shared/src/dtos/loadconsole/LoadConsoleDockZoneDto";
import { LoadConsoleIntervalDto } from "@shared/src/dtos/loadconsole/LoadConsoleIntervalDto";
import { LoadConsoleSlotPhaseDto } from "@shared/src/dtos/loadconsole/LoadConsoleSlotPhaseDto";
import { PanelColumnDto } from "@shared/src/dtos/panel/column/PanelColumnDto";
import { PanelColumnElementDto } from "@shared/src/dtos/panel/column/element/PanelColumnElementDto";
import { SetElementColumnDto } from "@shared/src/dtos/panel/column/element/SetElementColumnDto";
import { DockZoneTypes } from "@shared/src/enums/DockZoneTypes";
import { AddressTimetableIntervalDto, HomeController, PhaseController } from "@shared/src/public-api";
import { SlotDto } from '../../dtos/address/slots/SlotDto';
import { PhaseSlotDto } from "@shared/src/dtos/phase/slot/PhaseSlotDto";
import { PhaseSlotChangeReasonActionTypes } from "@shared/src/enums/PhaseSlotChangeReasonActionTypes";
import { PhaseSlotChangeReasonDataInfoDto } from "@shared/src/dtos/phase/slot/manage/reason/PhaseSlotChangeReasonDataInfoDto";

/**
 * Servei que concentra el tema del dragdrop.
 * En teoria serveix per poder fer drag/ drop d'0objectes des de qualsevol lloc de l'aplicació. 
 * Aquesdt servei te com a objectiu Determinar i combinar si s'escau dos objectes el dragged i sobre el que es fa el drop.
 * Si la cosa va, aleshores crida de a una funció de callback per a que es referesqui la pantalla o el que calgui
 */
@Injectable({
    providedIn: 'root',
})
export class DragDropService {

    /**
    * Tots els elements arrossegats es traslladen amb hardman/data
    */
    public static HARDMANTYPE: string = "hardman/data";
    /**
    * Per si arrosseguen a text/plain. PEr ara hi posem el nom de l'objecte.
    */
    public static TEXTPLAIN: string = "text/plain";


    public static HARDMAN_SLOTDTO: string = "SlotDto";
    public static HARDMAN_PHASESLOTDTO: string = "PhaseSlotDto";
    public static HARDMAN_INTERNATIONALPLANNINGPHASEDTO: string = "InternationalPlanningPhaseDto";
    public static HARDMAN_ADDRESSTIMETABLEINTERVALDTO: string = "AddressTimetableIntervalDto";
    public static HARDMAN_INTERNATIONALPLANNINGDAYDTO: string = "InternationalPlanningDayDto";
    public static HARDMAN_ACCESSCONTROLGATEPLATEDTO: string = "AccessControlGatePlateDto";
    public static HARDMAN_ACCESSCONTROLGATEDTO: string = "AccessControlGateDto";

    public static HARDMAN_PANELCOLUMNDTO: string = "PanelColumnDto";
    public static HARDMAN_PANELCOLUMNELEMENTDTO: string = "PanelColumnElementDto";

    public static HARDMAN_DOCKSLOTPHASEDTO: string = "DockSlotPhaseDto";

    public static HARDMAN_LOADCONSOLESLOTPHASEDTO: string = "LoadConsoleSlotPhaseDto";
    public static HARDMAN_LOADCONSOLEDOCKZONEDTO: string = "LoadConsoleDockZoneDto";
    public static HARDMAN_LOADCONSOLEINTERVALDTO: string = "LoadConsoleIntervalDto";
    public static HARDMAN_LOADCONSOLEARRIVALDTO: string = "LoadConsoleArrivalDto";

    public static HARDMAN_ROUTECARDITEMDTO: string = "RouteCardItemDto";

    public static HARDMAN_TRUCKINGPACKAGEITEMDTO: string = "TruckingShipmentPackageDto";
    


    constructor(private slotsController: SlotsController, private openRouteController: OpenRouteController, private accessControlController: AccessControlController, private docksController: DocksController, private loadConsoleController: LoadConsoleController, private openPanelController: OpenPanelController, private phaseController: PhaseController, 
        @Inject('HomeController') public homeController: HomeController) {

    }


    public onDragOver(draggedobject: any, ondropobject: any, event: DragEvent, callbackfunction?: Function, callbackfunctionparam?: any) {
        if (event.preventDefault)
            event.preventDefault();

    }

    public static isDraggedObjectHardmanType(event: DragEvent): boolean{

        var dt = event.dataTransfer;
        if (dt.items) {
            for (var i = 0; i < dt.items.length; i++) {
                let item: DataTransferItem = dt.items[i];
                if(item == null)
                    return false;
                if (item.kind == "string" && item.type == DragDropService.HARDMANTYPE)
                    return true;
            }
        } 

        return false;

    }
    public static extractDraggedObject(event: DragEvent) {
        return { "hardman/data": JSON.parse(event.dataTransfer.getData(DragDropService.HARDMANTYPE)), "text/plain": event.dataTransfer.getData(DragDropService.TEXTPLAIN) };
    }
    public static createDropObject(dropobject: any, type: string) {
        return { "hardman/data": dropobject, "text/plain": type };
    }

    public static extractDraggedObjectHardmanType(event: DragEvent): any {

        let draggedobject = this.extractDraggedObject(event);

        if (!DragDropService.isHardmanType(draggedobject))
            return null;

        return draggedobject["hardman/data"];

    }


    public onDrop(draggedobject: any, ondropobject: any, event: DragEvent, callbackfunction?: Function, callbackfunctionparam?: any) {
        event.preventDefault();
        var dt = event.dataTransfer;

        let drop: boolean = false;
        let objectdroppped = ondropobject;
        let objectdragged = draggedobject;
        if (ondropobject != null) {
            if (DragDropService.isHardmanType(draggedobject) && DragDropService.isHardmanType(ondropobject)) {
                //Estic arrossegant sobre dos objectes, 
                //Pregunta: puc saber quins son?
                //Resposta: No directament, ho has de fer indirectament
                //Fem una funció que sigui la concatenació dels dos tipus
                let draggedtype = objectdragged["text/plain"].replace("HARDMAN:", "");
                let dropppedtype = objectdroppped["text/plain"].replace("HARDMAN:", "");

                let functionname = draggedtype + "DraggedTo" + dropppedtype;

                if (this[functionname]) {
                    let realdraggedobject = objectdragged["hardman/data"];
                    let realdropppedobject = objectdroppped["hardman/data"];

                    drop = this[functionname](realdraggedobject, realdropppedobject, event, objectdragged, objectdroppped, callbackfunction, callbackfunctionparam);
                }
            }
        }
    }

    public static moverOrCopy(event: DragEvent) {
        if (event.shiftKey)
            event.dataTransfer.dropEffect = "copy";
        else
            event.dataTransfer.dropEffect = "move";
    }
    public static copy(event: DragEvent) {
        event.dataTransfer.dropEffect = "copy";
    }

    public static isHardmanType(object: any): boolean {
        if (object == null)
            return false;
        if (object["text/plain"] == undefined)
            return false;
        return true;
    }



    /**
    * ARROSSEGUEM UN PhaseSlotDto SOBRE PhaseSlotDto
    */
    public PhaseSlotDtoDraggedToPhaseSlotDto(draggedobject: PhaseSlotDto, ondropobject: PhaseSlotDto, event: DragEvent, hardmanobjectdragged: any, hardmanobjectdropped: any, callbackfunction?: Function, callbackfunctionparam?: any): boolean {

        if (draggedobject == null || ondropobject == null)
            return;
        if (!HLong.isNullOrNullLong(draggedobject.phaseId) && !HLong.isNullOrNullLong(draggedobject.slotId) && !HLong.isNullOrNullLong(ondropobject.phaseId) && !HLong.isNullOrNullLong(ondropobject.slotId)) {
            
            //No es pot permutar la mateixa phase o el mateix slot
            if(draggedobject.phaseId == ondropobject.phaseId || draggedobject.slotId == ondropobject.slotId)
                return;
            
            this.phaseController.getRequiredSlotChangeReason(draggedobject.phaseId, PhaseSlotChangeReasonActionTypes.SwapSlot).subscribe((data: boolean) => {
                let slotaction = SlotActionDto.build(draggedobject.slotId, draggedobject.phaseId, ondropobject.slotId, ondropobject.phaseId, false);
                if (data) {
                    this.homeController.openAsidePhaseSlotChangeReasonManage(draggedobject.phaseId, PhaseSlotChangeReasonDataInfoDto.buildSwap(draggedobject.phaseId, slotaction, false, false)).then(value => {
                        if (callbackfunction)
                            callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
                    });
                }else{
                    this.slotsController.swapSlots(slotaction, new Array(), false).subscribe(data => {
                        if (callbackfunction)
                            callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
                    });
                }
            });

        }

        return true;
    }

    /**
    * ARROSSEGUEM UN PhaseSlotDto SOBRE SlotDto empty
    */
    public PhaseSlotDtoDraggedToSlotDto(draggedobject: PhaseSlotDto, ondropobject: SlotDto, event: DragEvent, hardmanobjectdragged: any, hardmanobjectdropped: any, callbackfunction?: Function, callbackfunctionparam?: any): boolean {

        if (draggedobject == null || ondropobject == null) {
            return;
        }

        if (!HLong.isNullOrNullLong(draggedobject.slotId) && ondropobject.isAvailable) {
            //Si el que estem arrossegant te slotId, significa que la fase ja esta assignada a un slot

            this.phaseController.getRequiredSlotChangeReason(draggedobject.phaseId, PhaseSlotChangeReasonActionTypes.MoveSlot).subscribe((data: boolean) => {
                let slotaction = SlotActionDto.build(draggedobject.slotId, draggedobject.phaseId, ondropobject.slotId, null, false);
                if (data) {
                    this.homeController.openAsidePhaseSlotChangeReasonManage(draggedobject.phaseId, PhaseSlotChangeReasonDataInfoDto.buildMove(draggedobject.phaseId, slotaction, false, false)).then(value => {
                        if (callbackfunction)
                            callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
                    });
                }else{
                    this.slotsController.moveSlots(slotaction, new Array(), false).subscribe(data => {
                        if (callbackfunction)
                            callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
                    });
                }
            });

        } else if (HLong.isNullOrNullLong(draggedobject.slotId) && ondropobject.isAvailable) {
            //Si el que estem arrossegant NO te slotId, significa que la fase encara NO esta assignada a un slot
           
            this.phaseController.getRequiredSlotChangeReason(draggedobject.phaseId, PhaseSlotChangeReasonActionTypes.SetSlot).subscribe((data: boolean) => {
                let slotaction = SlotActionDto.build(draggedobject.slotId, draggedobject.phaseId, ondropobject.slotId, null, false);
                if (data) {
                    this.homeController.openAsidePhaseSlotChangeReasonManage(draggedobject.phaseId, PhaseSlotChangeReasonDataInfoDto.buildSetSlotSlotAction(draggedobject.phaseId, slotaction, false, false)).then(value => {
                        if (callbackfunction)
                            callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
                    });
                }else{
                    this.slotsController.assignSlots(slotaction, new Array(), false).subscribe(data => {
                        if (callbackfunction)
                            callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
                    });
                }
            });
        }

        return true;
    }

    /**
    * ARROSSEGUEM UN AccessControlGatePlateDto SOBRE AccessControlGateDto (canvio de porta)
    */
    public AccessControlGatePlateDtoDraggedToAccessControlGateDto(draggedobject: AccessControlGatePlateDto, ondropobject: AccessControlGateDto, event: DragEvent, hardmanobjectdragged: any, hardmanobjectdropped: any, callbackfunction?: Function, callbackfunctionparam?: any): boolean {

        if (draggedobject == null || ondropobject == null) {
            return;
        }

        this.accessControlController.exitEntryPlateGate(SetPlateGateDto.build(draggedobject.accessControlId, draggedobject.plateA, draggedobject.routeId, draggedobject.phaseId, ondropobject.gateId, draggedobject.gateId)).subscribe(data => {
            if (callbackfunction)
                callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
        });

        return true;
    }

    /**
    * ARROSSEGUEM UN PhaseSlotDto SOBRE DockSlotPhaseDto (assigno la Phase a un slot dun moll)
    */
    public PhaseSlotDtoDraggedToDockSlotPhaseDto(draggedobject: PhaseSlotDto, ondropobject: DockSlotPhaseDto, event: DragEvent, hardmanobjectdragged: any, hardmanobjectdropped: any, callbackfunction?: Function, callbackfunctionparam?: any): boolean {

        if (draggedobject == null || ondropobject == null) {
            return;
        }

        //Pot passar que tinguem la phase assignada a l'slot o no estigui

        if (ondropobject.dockId > 0) {
            //Assignem un moll definit
            this.docksController.setDockIdToPhaseId(draggedobject.phaseId, ondropobject.dockId).subscribe((data: Boolean) => {
                if (callbackfunction)
                    callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
            });
        } else if (!HString.isNullOrNullString(ondropobject.dockSkin)) {
            //Assignem un moll NO definit
            this.docksController.setDockToPhaseId(draggedobject.phaseId, ondropobject.dockSkin).subscribe((data: Boolean) => {
                if (callbackfunction)
                    callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
            });
        }

        return true;
    }

    /**
    * ARROSSEGUEM UN LoadConsoleSlotPhaseDto SOBRE LoadConsoleDockZoneDto (cridem a entrar a moll o a sortir de moll)
    */
    public LoadConsoleSlotPhaseDtoDraggedToLoadConsoleDockZoneDto(draggedobject: LoadConsoleSlotPhaseDto, ondropobject: LoadConsoleDockZoneDto, event: DragEvent, hardmanobjectdragged: any, hardmanobjectdropped: any, callbackfunction?: Function, callbackfunctionparam?: any): boolean {

        if (draggedobject == null || ondropobject == null) {
            return;
        }

        if (ondropobject.dockZoneType == DockZoneTypes.CallInToDock) {
            this.loadConsoleController.setCallInToDock(draggedobject).subscribe(data => {
                if (callbackfunction)
                    callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
            });
        } else if (ondropobject.dockZoneType == DockZoneTypes.CallOutToDock) {
            this.loadConsoleController.setCallOutToDock(draggedobject).subscribe(data => {
                if (callbackfunction)
                    callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
            });
        } else if (ondropobject.dockZoneType == DockZoneTypes.Dock) {
            this.loadConsoleController.setAtToDock(draggedobject).subscribe(data => {
                if (callbackfunction)
                    callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
            });

        }
        return true;
    }

    /**
    * ARROSSEGUEM UN LoadConsoleSlotPhaseDto SOBRE LoadConsoleIntervalDto (el volem treure de pendent d'entrar a moll)
    */
    public LoadConsoleSlotPhaseDtoDraggedToLoadConsoleIntervalDto(draggedobject: LoadConsoleSlotPhaseDto, ondropobject: LoadConsoleIntervalDto, event: DragEvent, hardmanobjectdragged: any, hardmanobjectdropped: any, callbackfunction?: Function, callbackfunctionparam?: any): boolean {

        if (draggedobject == null || ondropobject == null) {
            return;
        }
        this.loadConsoleController.exitCallToDock(draggedobject).subscribe(data => {
            if (callbackfunction)
                callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
        });

        return true;
    }

    /**
    * ARROSSEGUEM UN LoadConsoleSlotPhaseDto SOBRE LoadConsoleArrivalDto (el volem treure de pendent d'entrar a moll)
    */
    public LoadConsoleSlotPhaseDtoDraggedToLoadConsoleArrivalDto(draggedobject: LoadConsoleSlotPhaseDto, ondropobject: LoadConsoleArrivalDto, event: DragEvent, hardmanobjectdragged: any, hardmanobjectdropped: any, callbackfunction?: Function, callbackfunctionparam?: any): boolean {

        if (draggedobject == null || ondropobject == null) {
            return;
        }
        this.loadConsoleController.exitCallToDock(draggedobject).subscribe(data => {
            if (callbackfunction)
                callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
        });

        return true;
    }

    /**
    * ARROSSEGUEM UN PanelColumnElementDto SOBRE PanelColumnDto (canvio de columna)
    */
    public PanelColumnElementDtoDraggedToPanelColumnDto(draggedobject: PanelColumnElementDto, ondropobject: PanelColumnDto, event: DragEvent, hardmanobjectdragged: any, hardmanobjectdropped: any, callbackfunction?: Function, callbackfunctionparam?: any): boolean {

        if (draggedobject == null || ondropobject == null) {
            return;
        }

        this.openPanelController.changeElementColumn(SetElementColumnDto.build(draggedobject.elementId, ondropobject.panelColumnId)).subscribe(data => {
            if (callbackfunction)
                callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
        });

        return true;
    }

    /**
    * ARROSSEGUEM UN InternationalPlanningPhaseDto SOBRE InternationalPlanningPhaseDto
    */
    public InternationalPlanningPhaseDtoDraggedToInternationalPlanningPhaseDto(draggedobject: InternationalPlanningPhaseDto, ondropobject: InternationalPlanningPhaseDto, event: DragEvent, hardmanobjectdragged: any, hardmanobjectdropped: any, callbackfunction?: Function, callbackfunctionparam?: any): boolean {

        if (draggedobject == null || ondropobject == null)
            return;
        if (!HLong.isNullOrNullLong(draggedobject.phaseId) && !HLong.isNullOrNullLong(draggedobject.slotId) && !HLong.isNullOrNullLong(ondropobject.phaseId) && !HLong.isNullOrNullLong(ondropobject.slotId)) {
            
            this.phaseController.getRequiredSlotChangeReason(draggedobject.phaseId, PhaseSlotChangeReasonActionTypes.SwapSlot).subscribe((data: boolean) => {
                let slotaction = SlotActionDto.build(draggedobject.slotId, draggedobject.phaseId, ondropobject.slotId, ondropobject.phaseId, false);
                if (data) {
                    this.homeController.openAsidePhaseSlotChangeReasonManage(draggedobject.phaseId, PhaseSlotChangeReasonDataInfoDto.buildSwap(draggedobject.phaseId, slotaction, false, false)).then(value => {
                        if (callbackfunction)
                            callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
                    });
                }else{
                    this.slotsController.swapSlots(slotaction, new Array(), false).subscribe(data => {
                        if (callbackfunction)
                            callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
                    });
                }
            });
            
          
        }

        return true;
    }

    /**
    * ARROSSEGUEM UN InternationalPlanningPhaseDto SOBRE empty
    */
    public InternationalPlanningPhaseDtoDraggedToAddressTimetableIntervalDto(draggedobject: InternationalPlanningPhaseDto, ondropobject: AddressTimetableIntervalDto, event: DragEvent, hardmanobjectdragged: any, hardmanobjectdropped: any, callbackfunction?: Function, callbackfunctionparam?: any): boolean {

        if (draggedobject == null || ondropobject == null) {
            return;
        }

        if (!HLong.isNullOrNullLong(draggedobject.slotId) && ondropobject.addressTimetableIntervalId) {
            //Si el que estem arrossegant te slotId, significa que la fase ja esta assignada a un slot
            // MOVE
            this.openRouteController.phaseAllocateSlot(draggedobject.phaseId, ondropobject.addressTimetableIntervalId, new Array()).subscribe(data => {
                if (callbackfunction)
                    callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
            });
        } else if (HLong.isNullOrNullLong(draggedobject.slotId) && ondropobject.addressTimetableIntervalId) {
            //Si el que estem arrossegant NO te slotId, significa que la fase encara NO esta assignada a un slot
            // ASSIGN
            this.openRouteController.phaseAllocateSlot(draggedobject.phaseId, ondropobject.addressTimetableIntervalId, new Array()).subscribe(data => {
                if (callbackfunction)
                    callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
            });
        }

        return true;
    }

    /**
    * ARROSSEGUEM UN InternationalPlanningPhaseDto SOBRE InternationalPlanningDayDto
    */
    public InternationalPlanningPhaseDtoDraggedToInternationalPlanningDayDto(draggedobject: InternationalPlanningPhaseDto, ondropobject: InternationalPlanningDayDto, event: DragEvent, hardmanobjectdragged: any, hardmanobjectdropped: any, callbackfunction?: Function, callbackfunctionparam?: any): boolean {

        if (draggedobject == null || ondropobject == null || !ondropobject.day) {
            return;
        }

        if (!HLong.isNullOrNullLong(draggedobject.slotId) && ondropobject.day) {
            //Si el que estem arrossegant te slotId, significa que la fase ja esta assignada a un slot
            // MOVE
            this.openRouteController.phaseAllocateSlotByDay(draggedobject.phaseId, ondropobject.day, new Array()).subscribe(data => {
                if (callbackfunction)
                    callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
            });
        } else if (HLong.isNullOrNullLong(draggedobject.slotId) && ondropobject.day) {
            //Si el que estem arrossegant NO te slotId, significa que la fase encara NO esta assignada a un slot
            // ASSIGN
            this.openRouteController.phaseAllocateSlotByDay(draggedobject.phaseId, ondropobject.day, new Array()).subscribe(data => {
                if (callbackfunction)
                    callbackfunction(draggedobject, ondropobject, event, hardmanobjectdragged, hardmanobjectdropped, callbackfunctionparam, data);
            });
        }

        return true;
    }
}
