import { Injectable } from '@angular/core'
import { IndicatorAsideDto } from '@shared/src/controllers/address/indicator/IndicatorAsideDto';
import { PlateRouteDto } from '@shared/src/dtos/accesscontrol/PlateRouteDto';
import { LoadConsolePhaseFilterDto } from '@shared/src/dtos/loadconsole/LoadConsolePhaseFilterDto';
import { ManagementModelChatInfoDto } from '@shared/src/dtos/managementmodel/ManagementModelChatInfoDto';
import { PanelElementFilterDto } from '@shared/src/dtos/panel/column/element/PanelElementFilterDto';
import { BookingSlotChangeReasonDataInfoDto } from '@shared/src/dtos/booking/slotchangereason/BookingSlotChangeReasonDataInfoDto';
import { PhaseSlotChangeReasonDataInfoDto } from '@shared/src/dtos/phase/slot/manage/reason/PhaseSlotChangeReasonDataInfoDto';
import { RouteReturnInBoundOutBoundDelegatedDto } from '@shared/src/dtos/route/wizard/RouteReturnInBoundOutBoundDelegatedDto';
import { RouteCardDto } from '@shared/src/public-api';
import { MapMarcadorDto } from '@shared/src/services/map/map.service';

@Injectable({
    providedIn: 'root'
})
export class SharedAsideFactory<T = undefined> {
    invokeAddress(addressId: number, formattedAddress: string) {
        this.invokeAddressCoordinates(addressId, formattedAddress, -1, -1);
    }
    invokeAddressCoordinates(addressId: number, formattedAddress: string, longitude: number, latitude: number) {
        throw new Error('Method not implemented.');
    }
    invokePhase(phaseId: number): Promise<any> {
        throw new Error('Method not implemented.');
    }

    public invokeActiveRoutePhase(routeId: number, phaseId: number): Promise<any> {
        throw ("you must define invokeRoute on");
    }
    public invokeRoute(routeId: number): Promise<any> {
        throw ("you must define invokeRoute on");
    }
    public invokeShipment(shipmentId: number): Promise<any> {
        throw ("you must define invokeShipment on");
    }
    public invokeRouteMapFromShipment(shipmentId: number): Promise<any> {
        throw ("you must define invokeRouteMapFromShipment on");
    }
    public invokeRouteMapFromRoute(routeId: number): Promise<any> {
        throw ("you must define invokeRouteMapFromRoute on");
    }
    invokePlateRoute(data: PlateRouteDto): Promise<any> {
        throw new Error('Method not implemented.');
    }
    invokeLoadConsolePhase(data: LoadConsolePhaseFilterDto): Promise<any> {
        throw new Error('Method not implemented.');
    }

    invokePanelElement(data: PanelElementFilterDto): Promise<any> {
        throw new Error('Method not implemented.');
    }


    invokeCreateNonacceptance(data: RouteReturnInBoundOutBoundDelegatedDto): Promise<any> {
        throw new Error('Method not implemented.');
    }

    invokeCreateInbound(data: RouteReturnInBoundOutBoundDelegatedDto): Promise<any> {
        throw new Error('Method not implemented.');
    }

    invokeCreateReturn(data: RouteReturnInBoundOutBoundDelegatedDto): Promise<any> {
        throw new Error('Method not implemented.');
    }

    invokeCreateOutbound(data: RouteReturnInBoundOutBoundDelegatedDto): Promise<any> {
        throw new Error('Method not implemented.');
    }

    invokeCreateDelegate(data: RouteReturnInBoundOutBoundDelegatedDto): Promise<any> {
        throw new Error('Method not implemented.');
    }
    invokeCreateReverseLogistics(data: RouteReturnInBoundOutBoundDelegatedDto): Promise<any> {
        throw new Error('Method not implemented.');
    }

    invokeBookingRoute(data: RouteCardDto): Promise<any> {
        throw new Error('Method not implemented.');
    }

    invokeManagementModelChat(data: ManagementModelChatInfoDto): Promise<any> {
        throw new Error('Method not implemented.');
    }

    invokeUserLogInfo(data: number): Promise<any> {
        throw new Error('Method not implemented.');
    }
    invokeMapMarcadorDto(m: MapMarcadorDto) {
        throw new Error('Method not implemented.');
    }

    invokeIndicators(m: IndicatorAsideDto): Promise<any> {
        throw new Error('Method not implemented.');
    }

    invokePhaseSlotManage(data: number): Promise<any> {
        throw new Error('Method not implemented.');
    }
    
    invokePhaseSlotChangeReasonManage(phaseId: number, data: PhaseSlotChangeReasonDataInfoDto): Promise<any> {
        throw new Error('Method not implemented.');
    }
    
    invokeBookingSlotChangeReasonManage(bookingId: number, data: BookingSlotChangeReasonDataInfoDto): Promise<any> {
        throw new Error('Method not implemented.');
    }
}