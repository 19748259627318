import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { ControlContainer, FormGroup, FormBuilder } from '@angular/forms';
import { Inject, Optional, Host, SkipSelf } from '@angular/core';

import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';
import { HomeController } from '../../../../controllers/home/home.controller';
import { HColor } from '../../../../datatypes/HColor';
import { AvatarDto } from '../../../../dtos/core/avatar/AvatarDto';
import { HTriState } from '@shared/src/datatypes/HTriState';

@Component({
  selector: 'tristate',
  templateUrl: './tristate.component.html',
  styleUrls: ['./tristate.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: TriStateComponent, multi: true }
  ]
})

export class TriStateComponent extends ValueAccessorValidatedBase<number, HTriState> {

  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this.rFormColor = this.fb.group({
      dtpvalidator: ['', validators, asyncvalidators]
    });
  }

  isValid(value: number): boolean {
    return HTriState.validState(value);
  }

  public rFormColor: FormGroup;

  public useIonic: boolean = false;
  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    @Inject('HomeController') protected homecontroller: HomeController
  ) {
    super(controlContainer, validators, asyncValidators);
    if (homecontroller.useIonic)
      this.useIonic = homecontroller.useIonic();
  }

  @Input() public preplaceholder: string;
  @Input() public placeholder: string;
  @Input() public readonly: boolean;
  @Output() valueChanged: EventEmitter<HTriState> = new EventEmitter();

  public identifier = 'tristate-' + identifier++;

  public isTrue: boolean;
  public isFalse: boolean;
  public isIndeterminate: boolean;

  externalValue(value: number): HTriState {
    if (value == null)
      return undefined;

    if (!HTriState.validState(value))
      return null;

    return HTriState.build(value);
  }
  internalValue(value: HTriState): number {
    if (value == null)
      return undefined;

    if (!HTriState.validState(value.state))
      return null;

    this.calculateState(value.state);
    return value.state;
  }

  calculateState(state: number) {
    this.isTrue = state == HTriState.True;
    this.isFalse = state == HTriState.False;
    this.isIndeterminate = state == HTriState.Indeterminate;
  }

  setState(state: number) {
    if (this.readonly)
      return;
    if (state === this.value)
      return;
    this.value = state;
    this.calculateState(state);
    this.onChange(state);
  }

  onChange(e) {
    this.valueChanged.next(this.externalValue(this.value));
  }

  keyEnter(event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
}

let identifier = 0;