import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { SharedInactivityService } from '@shared/src/services/inactivity/SharedInactivityService';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { SystemController } from '@shared/src/controllers/system/system.controller';
import { ImageTypes } from '@shared/src/enums/ImageTypes';
import { TotemController } from '@shared/src/controllers/totem/totem.controller';
import { LanguagePoints } from '@shared/src/dtos/language/LanguagePoints';
import { TotemDto } from '@shared/src/controllers/totem/TotemDto';
import { TotemSteps } from '@shared/src/controllers/totem/TotemSteps';
import { TotemOperationDto } from '@shared/src/controllers/totem/TotemOperationDto';
import { TotemActionDto } from '@shared/src/controllers/totem/TotemActionDto';
import { TranslateService } from '@ngx-translate/core';
import { HString } from '@shared/src/public-api';

@Component({
    selector: 'shared-totem',
    templateUrl: './shared.totem.component.html',
    styleUrls: ['./shared.totem.component.scss']
})
export class SharedTotemComponent implements OnInit, OnDestroy {

    constructor(protected systemController: SystemController,
        protected activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        protected inactivityService: SharedInactivityService,
        protected totemService: TotemController) {

        this.traduccio = this.translateService.instant('TOTEMPAGE.IDENTIFICATION.TITLE');
    }

    public traduccio: string = "";

    public processing: boolean = false;

    public totemSteps = TotemSteps;
    private inactivityTimeout: any;

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(queryParams => {
            this.callResolveParams();
        });
        this.activatedRoute.params.subscribe(routeParams => {
            this.callResolveParams();
        });

        this.callResolveParams();
    }
    ngOnDestroy() {
        this.inactivityService.stopMonitoring();
    }

    onBackClick() {
        this.inactivityService.stopMonitoring();
        this.totemService.back(this.totem).subscribe(d => {
            this.wellCome(d);
        });
    }
    onResetClick() {
        this.reset();
    }

    onInactivity() {
        if (!this.totem.checkInactivity)
            return;
        this.reset();
    }
    onHaveActivity() {
        this.inactivitats = 0;
    }
    public inactivityProgress: number = 0;
    public inactivitats: number = 0;
    public pasInactivitat() {
        if (!this.totem.checkInactivity)
            return;
        this.inactivitats++;
        this.inactivityProgress = (this.inactivitats * 100) / this.totem.maxSecondsOfInactivity;
        if (this.inactivityProgress >= 100)
            this.reset();
    }

    private _resolvingParams: boolean = false;
    private callResolveParams() {
        if (this._resolvingParams)
            return;
        this._resolvingParams = true;
        this.resolveParams();
        this._resolvingParams = false;
    }

    public __totemid: number;
    private resolveParams(): boolean {
        let result: boolean = false;
        const routeParams = this.activatedRoute.snapshot.paramMap;
        const queryParams = this.activatedRoute.snapshot.queryParamMap;
        const routeUrl = this.activatedRoute.snapshot.url;

        let totemid = routeParams.get('totemid');
        if (!HString.isNullOrNullString(totemid)) {
            let pId: number = Number.parseInt(totemid);

            if (this.__totemid === pId)
                return;

            this.__totemid = pId;
            this.loadData(true);
        }

        return result;
    }
    public logoImage: FileUpDownLoadDto = null;
    public logoPropi: boolean = false;
    public totem: TotemDto;
    loadData(arg0: boolean) {
        if (this.logoImage == null)
            this.systemController.getImage(ImageTypes.logoBar).subscribe((data: FileUpDownLoadDto) => {
                this.logoImage = data;
                if (this.logoImage != null && this.logoImage.fileBytes != null && this.logoImage.fileBytes.length > 0)
                    this.logoPropi = true;
                else
                    this.logoPropi = false;
            });


        this.reset();
    }


    wellCome(d: TotemDto) {
        this.totem = d;
        this.processing = false;
        if (this.totem.step == TotemSteps.entered || this.totem.step == TotemSteps.signed || this.totem.step == TotemSteps.exited)
            this.reset();
        else {
            this.inactivityService.setInactivityTime(this.totem.maxSecondsOfInactivity * 1000);
            if (this.totem.checkInactivity) {
                this.inactivityService.startMonitoring(() => this.onInactivity());
                this.inactivityService.haveActivity(() => this.onHaveActivity());

                if (this.inactivityTimeout != null)
                    clearInterval(this.inactivityTimeout);

                this.inactivityTimeout = setInterval(() => {
                    this.pasInactivitat();
                }, 1000);
            }
        }
    }

    public reset() {
        this.processing = false;
        this.inactivitats = 0;
        this.inactivityProgress = 0;
        this.inactivityService.stopMonitoring();
        this.totemService.getSetting(this.__totemid).subscribe(d => {
            this.wellCome(d);
        });
    }

    public onLanguageClick(v: LanguagePoints) {
        this.inactivitats = 0;
        this.inactivityProgress = 0;
        this.totem.language = v.language;
        this.inactivityService.stopMonitoring();
        this.totemService.next(this.totem).subscribe(d => {
            this.wellCome(d);
        });
    }
    public onActionClick(v: TotemActionDto) {
        this.inactivitats = 0;
        this.inactivityProgress = 0;
        this.totem.action = v.action;
        this.inactivityService.stopMonitoring();
        this.totemService.next(this.totem).subscribe(d => {
            this.wellCome(d);
        });
    }
    public onOperationClick(v: TotemOperationDto) {
        this.inactivitats = 0;
        this.inactivityProgress = 0;
        this.totem.operation = v.operation;
        this.inactivityService.stopMonitoring();
        this.totemService.next(this.totem).subscribe(d => {
            this.wellCome(d);
        });
    }
    public next() {
        this.processing = true;
        this.inactivityService.stopMonitoring();
        this.totemService.next(this.totem).subscribe(d => {
            this.wellCome(d);
        });
    }

    public action(action: string){
        this.processing = true;
        this.inactivityService.stopMonitoring();
        this.totemService.action(this.totem, action).subscribe(d => {
            this.wellCome(d);
        });
    }

    public refreshTotem() {
        this.inactivityService.stopMonitoring();
        this.totemService.refresh(this.totem).subscribe(d => {
            this.wellCome(d);
        });
    }

    public getLanguageCaption(lang: LanguagePoints) {
        return ('LANGUAGES.' + lang.language + '.CAPTION').toUpperCase();
    }
    public getLanguageName(lang: LanguagePoints) {
        return ('LANGUAGES.' + lang.language + '.NAME').toUpperCase();
    }
    public getActionCaption(v: TotemActionDto) {
        return ('TOTEMACTIONS.' + v.action + '.CAPTION').toUpperCase();
    }
    public getActionName(v: TotemActionDto) {
        return ('TOTEMACTIONS.' + v.action + '.NAME').toUpperCase();
    }
    public getOperationCaption(v: TotemOperationDto) {
        return ('TOTEMOPERATIONS.' + v.operation + '.CAPTION').toUpperCase();
    }
    public getOperationName(v: TotemOperationDto) {
        return ('TOTEMOPERATIONS.' + v.operation + '.NAME').toUpperCase();
    }
    public isEnterDataConfirmationStep(step: TotemSteps) {
        if (step && step.startsWith("enterVerify"))
            return true;
        return false;

    }
    public isSlotStep(step: TotemSteps) {
        if (step.startsWith("slot"))
            return true;
        return false;

    }

    public isSignatureStep(step: TotemSteps) {
        if (step && step.startsWith("signature"))
            return true;
        return false;

    }

    public isExitStep(step: TotemSteps) {
        if (step && step.startsWith("exit"))
            return true;
        return false;

    }
    public isEstaDins(step: TotemSteps) {
        return (step && step.startsWith("estaDins"));
    }
    public isEnterFinish(step: TotemSteps) {
        return (step && step.startsWith("enterFinish"));
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}


