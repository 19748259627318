export enum PhaseSlotChangeReasonActionTypes {
	CancelSlot = "CancelSlot",
	CancelSlotExwork = "CancelSlotExwork",
	SetSlot = "SetSlot",
	SetSlotExwork = "SetSlotExwork",
	SwapSlot = "SwapSlot",
	ObtainSlot = "ObtainSlot",
	MoveSlot = "MoveSlot",
	ReserveOutOfHours = "ReserveOutOfHours",
	None = "None"
}
