import { SlotActionDto } from "../../../../address/slots/actions/SlotActionDto";
import { AddressTimetableIntervalDto } from "../../../../address/AddressTimetableIntervalDto";
import { PhaseSlotChangeReasonActionTypes } from "@shared/src/enums/PhaseSlotChangeReasonActionTypes";
import { ReserveOutOfHoursDto } from "@shared/src/dtos/address/slots/actions/ReserveOutOfHoursDto";


export class PhaseSlotChangeReasonDataInfoDto {
    public action: PhaseSlotChangeReasonActionTypes; //cancelSlot (tronja), setSlot (verd), swapSlot(blau), obtainSlot(vermell)
    public uuid: string;
    public phaseId: number;
    public addressinterval: AddressTimetableIntervalDto;
    public slotAction: SlotActionDto;
    public reserveOutOfHours: ReserveOutOfHoursDto;
    public fromActiveRoutes: boolean;
    public busy: boolean;

    public static buildSetSlot(phaseId: number, addressinterval: AddressTimetableIntervalDto, fromActiveRoutes: boolean, busy: boolean): PhaseSlotChangeReasonDataInfoDto {
        let result = new PhaseSlotChangeReasonDataInfoDto();
        result.phaseId = phaseId;
        result.addressinterval = addressinterval;
        result.fromActiveRoutes = fromActiveRoutes;
        result.busy = busy;
        result.action = PhaseSlotChangeReasonActionTypes.SetSlot;
        return result;
    }

    public static buildSetSlotSlotAction(phaseId: number, slotAction: SlotActionDto, fromActiveRoutes: boolean, busy: boolean): PhaseSlotChangeReasonDataInfoDto {
        let result = new PhaseSlotChangeReasonDataInfoDto();
        result.phaseId = phaseId;
        result.slotAction = slotAction;
        result.fromActiveRoutes = fromActiveRoutes;
        result.busy = busy;
        result.action = PhaseSlotChangeReasonActionTypes.SetSlot;
        return result;
    }

    public static buildSetSlotExwork(uuid: string, phaseId: number, addressinterval: AddressTimetableIntervalDto): PhaseSlotChangeReasonDataInfoDto {
        let result = new PhaseSlotChangeReasonDataInfoDto();
        result.uuid = uuid;
        result.phaseId = phaseId;
        result.addressinterval = addressinterval;
        result.action = PhaseSlotChangeReasonActionTypes.SetSlotExwork;
        return result;
    }

    public static buildReserveOutOfHours(phaseId: number, reserveOutOfHours: ReserveOutOfHoursDto): PhaseSlotChangeReasonDataInfoDto {
        let result = new PhaseSlotChangeReasonDataInfoDto();
        result.phaseId = phaseId;
        result.reserveOutOfHours = reserveOutOfHours;
        result.action = PhaseSlotChangeReasonActionTypes.ReserveOutOfHours;
        return result;
    }
    
    public static buildCancel(phaseId: number, fromActiveRoutes: boolean, busy: boolean): PhaseSlotChangeReasonDataInfoDto {
        let result = new PhaseSlotChangeReasonDataInfoDto();
        result.phaseId = phaseId;
        result.fromActiveRoutes = fromActiveRoutes;
        result.busy = busy;
        result.action = PhaseSlotChangeReasonActionTypes.CancelSlot;
        return result;
    }

    public static buildCancelSlotAction(phaseId: number, slotAction: SlotActionDto, fromActiveRoutes: boolean, busy: boolean): PhaseSlotChangeReasonDataInfoDto {
        let result = new PhaseSlotChangeReasonDataInfoDto();
        result.phaseId = phaseId;
        result.slotAction = slotAction;
        result.fromActiveRoutes = fromActiveRoutes;
        result.busy = busy;
        result.action = PhaseSlotChangeReasonActionTypes.CancelSlot;
        return result;
    }

    public static buildCancelExwork(uuid: string, phaseId: number): PhaseSlotChangeReasonDataInfoDto {
        let result = new PhaseSlotChangeReasonDataInfoDto();
        result.uuid = uuid;
        result.phaseId = phaseId;
        result.action = PhaseSlotChangeReasonActionTypes.CancelSlotExwork;
        return result;
    }

    public static buildSwap(phaseId: number, slotAction: SlotActionDto, fromActiveRoutes: boolean, busy: boolean): PhaseSlotChangeReasonDataInfoDto{
        let result = new PhaseSlotChangeReasonDataInfoDto();
        result.phaseId = phaseId;
        result.slotAction = slotAction;
        result.fromActiveRoutes = fromActiveRoutes;
        result.busy = busy;
        result.action = PhaseSlotChangeReasonActionTypes.SwapSlot;
        return result
    }

    public static buildObtain(phaseId: number, slotAction: SlotActionDto, fromActiveRoutes: boolean, busy: boolean): PhaseSlotChangeReasonDataInfoDto{
        let result = new PhaseSlotChangeReasonDataInfoDto();
        result.phaseId = phaseId;
        result.slotAction = slotAction;
        result.fromActiveRoutes = fromActiveRoutes;
        result.busy = busy;
        result.action = PhaseSlotChangeReasonActionTypes.ObtainSlot;
        return result
    }

    public static buildMove(phaseId: number, slotAction: SlotActionDto, fromActiveRoutes: boolean, busy: boolean): PhaseSlotChangeReasonDataInfoDto{
        let result = new PhaseSlotChangeReasonDataInfoDto();
        result.phaseId = phaseId;
        result.slotAction = slotAction;
        result.fromActiveRoutes = fromActiveRoutes;
        result.busy = busy;
        result.action = PhaseSlotChangeReasonActionTypes.MoveSlot;
        return result
    }
}