import { Component, Inject, NgZone, ElementRef, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { AlertService, HomeController, HTranslateService, SecurityController, SocketController } from '@shared/src/public-api';
import { SharedInactivityService } from '@shared/src/services/inactivity/SharedInactivityService';
import { TotemController } from '@shared/src/controllers/totem/totem.controller';
import { TotemDto } from '@shared/src/controllers/totem/TotemDto';
import { Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TotemSteps } from '@shared/src/controllers/totem/TotemSteps';
import { TotemRouteDto } from '@shared/src/controllers/totem/TotemRouteDto';
import { HLong } from '@shared/src/datatypes/HLong';

@Component({
    selector: 'shared-totem-identification',
    templateUrl: './shared.totem.identification.component.html',
    styleUrls: ['./shared.totem.identification.component.scss']
})
export class SharedTotemIdentificationComponent implements OnInit, OnDestroy {

    constructor(protected inactivityService: SharedInactivityService,
        protected totemService: TotemController,
        protected translateService: HTranslateService) {

    }

    public searchTextSubject = new Subject<string>();
    public searchText: string = "";
    public totemSteps = TotemSteps;
    public loading: number = 0;
    public sCoincidentRoutesCount: string ="0";

    resizeObservable$: Observable<Event>;
    resizeSubscription$: Subscription;
    scrollObservable$: Observable<Event>;
    scrollSubscription$: Subscription;

    ngOnInit(): void {
        this.searchTextSubject.pipe(
            debounceTime(HomeController.DEFAULT_BOUNCE_BUTTON_TIME),
            distinctUntilChanged())
            .subscribe(value => {
                this.loading++;
                this.totemService.identifyRoute(this.totem, value).subscribe(d => {
                    this.totem = d;
                    this.totemChange.next(this.totem);
                    this.loading = 0;
                    this.sCoincidentRoutesCount = this.totem.coincidentRoutesCount.toString();
                });
            });


        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
            this.onScroll();
        });
        this.scrollObservable$ = fromEvent(window, 'scroll');
        this.scrollSubscription$ = this.scrollObservable$.subscribe(evt => {
            this.onScroll();
        });
    }
    ngOnDestroy() {
        if (this.resizeSubscription$)
            this.resizeSubscription$.unsubscribe();
        if (this.scrollSubscription$)
            this.scrollSubscription$.unsubscribe();
    }
    ngAfterViewInit() {
        this.onScroll();

        if (this.container != null && this.container.nativeElement != null && this.container.nativeElement.parentElement != null) {
            const scrollerHandle = this.container.nativeElement.parentElement;
            if (scrollerHandle.addEventListener) {
                scrollerHandle.addEventListener("scroll", () => {
                    this.onScroll();
                });
            }
        }

    }
    getShowScroll() {
        this.onScroll();
        return (this.scrollHeight > this.parentHeight && this.parentScrollTop == 0);
    }
    @ViewChild("container", { read: ElementRef, static: false }) container: ElementRef;
    public scrollHeight = 0;
    public parentHeight = 0;
    public parentScrollTop = 0;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.onScroll();
    }
    @HostListener("window:scroll", ['$event'])
    doSomethingOnInternalScroll($event: Event) {
        this.onScroll();
    }
    public onScroll() {
        if (this.container != null && this.container.nativeElement != null) {
            this.scrollHeight = this.container.nativeElement.scrollHeight;
            if (this.container.nativeElement.parentElement != null) {
                this.parentHeight = this.container.nativeElement.parentElement.clientHeight;
                this.parentScrollTop = this.container.nativeElement.parentElement.scrollTop;
            }
        }
    }

    @Input()
    public totem: TotemDto;
    @Output() totemChange = new EventEmitter<TotemDto>();

    @Output() onFinish: EventEmitter<any> = new EventEmitter<any>();

    selectItem($event: TotemRouteDto) {
        this.totem.identificationSelected = $event;
    }
    confirmacioRutaCancelar() {
        this.totem.identificationSelected = null;
    }
    confirmacioRutaAcceptar() {
        if(this.totem.identificationSelected != null)
            this.onFinish.next();
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}


