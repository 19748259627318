import { BookingSlotChangeReasonActionTypes } from "@shared/src/enums/BookingSlotChangeReasonActionTypes";

export class BookingSlotChangeReasonDataInfoDto {
    public action: BookingSlotChangeReasonActionTypes; 
    public bookingId: number;

    public static buildCancelAllSlot(bookingId: number): BookingSlotChangeReasonDataInfoDto {
        let result = new BookingSlotChangeReasonDataInfoDto();
        result.bookingId = bookingId;
        result.action = BookingSlotChangeReasonActionTypes.CancelAllSlot;
        return result;
    }

}