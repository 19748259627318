import { Component, Input, OnInit } from '@angular/core';
import { PhaseController } from '@shared/src/controllers/route/phase/phase.controller';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { PhaseSlotManageDto } from '@shared/src/dtos/phase/slot/manage/PhaseSlotManageDto';

@Component({
  selector: 'shared-phase-slot-manage-component',
  templateUrl: './shared.phase.slot.manage.component.html',
  styleUrls: ['./shared.phase.slot.manage.component.scss'],
})
export class SharedPhaseSlotManageComponent implements OnInit {

  constructor(public phaseController: PhaseController) {
  }

  @Input() public readonly: boolean = false;

  public options: OptionsStripDto;

  public value: PhaseSlotManageDto;

  private _phaseId: number;
  @Input() set phaseId(value: number) {
    if (this._phaseId === value)
      return;
    this._phaseId = value;
    this.loadData();
  }
  get phaseId(): number {
    return this._phaseId;
  }

  ngOnInit() {

  }
  pretty(value) {
    return JSON.stringify(value);
}
  loadData() {

    this.phaseController.getPhaseSlotManage(this.phaseId).subscribe(data => {
      this.welcome(data);
    });

  }

  welcome(data: PhaseSlotManageDto) {
    this.value = data;

    if (data != null) {
      if (data.options != null)
        this.options = OptionsStripDto.buildFromOptionsStripDto(data.options);

      this.selectedOptionId = OptionsStripDto.arrange(this.options, this.selectedOptionId, data.optionId);
    }
  }

  public selectedOptionId = "INFO";
  onClickOption(option: OptionDto) {
    if (option != null)
      this.selectedOptionId = option.optionId;
    else
      this.selectedOptionId = "";
  }

  onRefresh() {
    if (this.value.phase == null || this.value.phaseId == null)
      return;

    this.phaseController.actualizePhaseSlotManager(this.value).subscribe(data => {
      this.welcome(data);
    });
  }


}
