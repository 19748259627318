<div class="line">
    <div class="texte-line routeKey" *ngIf="value.routeKey">
        {{value.routeKey}}
    </div>
    <div class="texte-line customerReference" *ngIf="value.customerReference">
        {{value.customerReference}}
    </div>
    <div class="texte-line shipmentReference" *ngIf="value.shipmentReferences">
        {{value.shipmentReferences}}
    </div>
    <div *ngIf="value.partnerParentTrademark" class="partner requadre parent element"
        [style.color]="getColor(value.partnerParentTrademark)"
        [style.background-color]="getBackgroundColor(value.partnerParentTrademark)">
        <span class="trademark">{{ value.partnerParentTrademark }}</span>
    </div>
    <div *ngIf="value.partnerShipperId && value.partnerShipperTrademark && (value.partnerParentTrademark!=value.partnerShipperTrademark)"
        class="partner requadre shipper element">
        <span class="trademark">{{ value.partnerShipperTrademark }}</span>
    </div>
    <div *ngIf="value.partnerCarrierId && value.partnerCarrierTrademark && (value.partnerParentTrademark!=value.partnerCarrierTrademark)"
        class="partner requadre carrier element">
        <span class="trademark">{{ value.partnerCarrierTrademark }}</span>
    </div>
</div>
<div class="plate-box">
    <div class="toplabel">
        {{'ROUTEPAGE.ITEM.EDIT.PLATEA' | translateIn:totem.language | async}}
    </div>
    {{value.plateA}}
</div>
<div class="plate-box">
    <div class="toplabel">
        {{'ROUTEPAGE.ITEM.EDIT.PLATEB' | translateIn:totem.language | async}}
    </div>
    {{value.plateB}}
</div>
<div class="plate-box" *ngIf="value.plateC">
    <div class="toplabel">
        {{'ROUTEPAGE.ITEM.EDIT.PLATEC' | translateIn:totem.language | async}}
    </div>
    {{value.plateC}}
</div>
<div class="plate-box large">
    <div class="toplabel">
        {{'ROUTEPAGE.ITEM.EDIT.DRIVERNAME' | translateIn:totem.language | async}}
    </div>
    {{value.driverName}}
</div>
<div class="plate-box">
    <div class="toplabel">
        {{'ROUTEPAGE.ITEM.EDIT.DRIVERPHONE' | translateIn:totem.language | async}}
    </div>
    {{value.driverPhone}}
</div>