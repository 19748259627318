import { Injectable } from '@angular/core';

@Injectable()
export class OpenRouteChangeDriverInfoAndPlatesDto {

    public driverName: string;
    public driverLegalIdentification: string;
    public driverPhone: string;
    public plateA: string;
    public plateB: string;
    public plateC: string;
    public carrierCode: string;
    public carrierName: string;
    public carrierLegalIdentification: string;
    public carrierAddress: string;

    public static build(driverName: string, driverLegalIdentification: string, driverPhone: string, plateA: string, plateB: string, plateC: string,
        carrierCode: string, carrierName: string, carrierLegalIdentification: string, carrierAddress: string) {
        const dto = new OpenRouteChangeDriverInfoAndPlatesDto;
        dto.driverName = driverName;
        dto.driverLegalIdentification = driverLegalIdentification;
        dto.driverPhone = driverPhone;
        dto.plateA = plateA;
        dto.plateB = plateB;
        dto.plateC = plateC;
        dto.carrierCode = carrierCode;
        dto.carrierName = carrierName;
        dto.carrierLegalIdentification = carrierLegalIdentification;
        dto.carrierAddress = carrierAddress;
        return dto;
    }
}
