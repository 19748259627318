import { Component, EventEmitter, Inject, OnInit, Output, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { TruckingRouteDto } from '@shared/src/dtos/trucking/TruckingRouteDto';
import { TruckingRouteExpandDto } from '@shared/src/dtos/trucking/TruckingRouteExpandDto';
import { TruckingController } from '@shared/src/controllers/trucking/trucking.controller';
import { TruckingShipmentPackageDto } from '@shared/src/dtos/trucking/TruckingShipmentPackageDto';
import { DragDropService } from '@shared/src/services/dragdrop/DragDropService';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { ChatController } from '@shared/src/public-api';


@Component({
	selector: 'shared-trucking-route-panel',
	templateUrl: './shared.trucking.route.panel.component.html',
	styleUrls: ['./shared.trucking.route.panel.component.scss']
})
export class SharedTruckingRoutePanelComponent implements OnInit {

	public useIonic: boolean = false;
	public iReadonly: boolean = false;

	public searchTextGroup: string = "";
	public filterMetadata: any = {};


	@Output() clickBack = new EventEmitter<boolean>();
	@Output() updateRoute = new EventEmitter<TruckingRouteDto>();

	constructor(
		public truckingController: TruckingController,
		public chatController: ChatController,
		public fb: FormBuilder,
		@Inject('HomeController') protected homeController: HomeController,
		@Inject("SecurityController") protected securityController: SecurityController,
		@Inject("AlertService") protected alertService: AlertService) {
		this.securityController.getStoredUserDto().subscribe(data => {
			this.iReadonly = data.isReadOnly;
		});

		if (homeController.useIonic)
			this.useIonic = homeController.useIonic();

		this.truckingController.routeChanged$.subscribe((routeId: number) => {
            if(this.selectedRouteExpand && routeId == this.selectedRouteExpand.routeId){
				this.loadRouteExpand();
			}
		});
	}


	ngOnInit(): void {
		this.options = this.buildOptions();
	}

	public _openPanel: boolean = false;
	@Input()
	public get openPanel(): boolean {
		return this._openPanel;
	}
	public set openPanel(value: boolean) {
		if (this._openPanel === value)
			return;
		this._openPanel = value;
	}

	public _selectedRouteExpand: TruckingRouteExpandDto;
	@Input()
	public get selectedRouteExpand(): TruckingRouteExpandDto {
		return this._selectedRouteExpand;
	}
	public set selectedRouteExpand(value: TruckingRouteExpandDto) {
		if (this._selectedRouteExpand === value)
			return;
		this._selectedRouteExpand = value;
	}

	public options: OptionsStripDto;
	public actualOptionId: string = "LOAD";
	public buildOptions(): OptionsStripDto {
		let result = new OptionsStripDto();
		result.add(OptionDto.build("LOAD", "TRUCKING.ROUTE.OPCIONS.LOAD.CAPTION", "TRUCKING.ROUTE.OPCIONS.LOAD.HELP", "", 1, -1, 1, true, false));
		result.add(OptionDto.build("TRUCK", "TRUCKING.ROUTE.OPCIONS.TRUCK.CAPTION", "TRUCKING.ROUTE.OPCIONS.TRUCK.HELP", "", 2, -1, 1, false, false));
		result.add(OptionDto.build("ROUTE", "TRUCKING.ROUTE.OPCIONS.ROUTE.CAPTION", "TRUCKING.ROUTE.OPCIONS.ROUTE.HELP", "", 3, -1, 1, false, false));
		result.add(OptionDto.build("PARTNERS", "TRUCKING.ROUTE.OPCIONS.PARTNERS.CAPTION", "TRUCKING.ROUTE.OPCIONS.PARTNERS.HELP", "", 4, -1, 1, false, false));
		result.add(OptionDto.build("DRIVER", "TRUCKING.ROUTE.OPCIONS.DRIVER.CAPTION", "TRUCKING.ROUTE.OPCIONS.DRIVER.HELP", "", 5, -1, 1, false, false));
		result.add(OptionDto.build("CHAT", "TRUCKING.ROUTE.OPCIONS.CHAT.CAPTION", "TRUCKING.ROUTE.OPCIONS.CHAT.HELP", "", 6, -1, 1, false, false));
		result.add(OptionDto.build("DOCUMENT", "TRUCKING.ROUTE.OPCIONS.DOCUMENT.CAPTION", "TRUCKING.ROUTE.OPCIONS.DOCUMENT.HELP", "", 7, -1, 1, false, false));
		this.actualOptionId = result.getActualSelected().optionId;
		return result;
	}

	public forcedOwnerParticipant: ParticipantDto;
	onClickOption(option: OptionDto) {
		this.actualOptionId = option.optionId;
		if (option && option.optionId === "CHAT") {
			this.forcedOwnerParticipant = ParticipantDto.buildByParams(this.selectedRouteExpand.routeId, ChatParticipantTypes.Route);
		} 
	}
	clickParticipant(participant: ParticipantDto) {
	  this.chatController.onClickParticipant(participant);
	}

	onClickBack(event) {
		this.clickBack.next(true);
	}

	onUpdateRoute(event) {
		this.updateRoute.next(this.selectedRouteExpand);
	}

	
	//////////////////////////////// DRAG & DROP ///////////////////////////////////////////
	
	dragover(event: DragEvent) {
		event.preventDefault();
		if (DragDropService.isDraggedObjectHardmanType(event)) {
			event.dataTransfer.dropEffect = 'copy';
		}else{
            event.dataTransfer.dropEffect = 'none';
		}
	}

	dropAtZone(event: DragEvent) {
		if (DragDropService.isDraggedObjectHardmanType(event)) {
			let draggedobject: any = DragDropService.extractDraggedObjectHardmanType(event);
			if (draggedobject != null) {
				this.onPackageAdd(draggedobject);
				return;
			}
		}
	
	}
	
	////////////////////////////////////////////////////////////////

	public onPackageAdd(spackage: TruckingShipmentPackageDto) {
		if (this._selectedRouteExpand == null || spackage == null)
			return;

		if (this._selectedRouteExpand.packages == null)
			this._selectedRouteExpand.packages = new Array();

		let exists = false;
		this._selectedRouteExpand.packages.forEach(element => {
			if (element.packageId == spackage.packageId)
				exists = true;
		});

		if (exists)
			return;

		this._selectedRouteExpand.packages.push(spackage);
		this._selectedRouteExpand.totalPackages = this._selectedRouteExpand.packages.length;

		this.truckingController.addPackageInRoute(this._selectedRouteExpand.routeId, spackage.packageId).subscribe(data => {
			if(!data)
				this.onPackageRemove(spackage);
			else
				this.loadRouteExpand();
		});
	}

	loadRouteExpand(){
		if(this._selectedRouteExpand != null){
			this.truckingController.getTruckingRouteExpand(this._selectedRouteExpand.routeId).subscribe(data => {
				this._selectedRouteExpand = data;
			});
		}else{
			this._selectedRouteExpand = null;
		}
	}

	onPackageRemove(spackage: TruckingShipmentPackageDto) {
		if (spackage == null)
			return;
		let newlist: Array<TruckingShipmentPackageDto> = [];
		this._selectedRouteExpand.packages.forEach(element => {
			if (element.packageId != spackage.packageId)
				newlist.push(element);
		});
		this._selectedRouteExpand.packages = newlist;
		this._selectedRouteExpand.totalPackages = this._selectedRouteExpand.packages.length;
	}

}
