
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FilterDto } from '@shared/src/components/core/maintenance/filter/FilterDtos';
import { FilterStripDto } from '@shared/src/components/core/maintenance/filter/FilterStripDtos';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { TenantSettingMaintenanceController } from '@shared/src/controllers/maintenance/tenant/TenantSettingMaintenanceController';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { PhaseLogDto } from '@shared/src/dtos/phase/PhaseLogDto';
import { BaseLogDto } from '@shared/src/dtos/route/BaseLogDto';
import { FilterLogDto } from '@shared/src/dtos/route/FilterLogDto';
import { RouteCardDto } from '@shared/src/dtos/route/RouteCardDto';
import { RouteLogDto } from '@shared/src/dtos/route/RouteLogDto';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';
import { ShipmentLogDto } from '@shared/src/dtos/shipment/ShipmentLogDto';
import { PhaseCardDto, PhaseController, SharedAsideFactory } from '@shared/src/public-api';


@Component({
    selector: 'shared-route-item-log-maintenance',
    templateUrl: './shared.route.item.log.maintenance.component.html',
    styleUrls: ['./shared.route.item.log.maintenance.component.scss']
})
export class SharedRouteItemLogMaintenanceComponent implements OnInit {

    public useIonic: boolean = false;
    public searchText: string = "";

    constructor(@Inject('HomeController') protected homeController: HomeController,
        @Inject('SharedAsideFactory') protected sharedAsideFactory: SharedAsideFactory,
        public routeController: RouteController, public shipmentController: ShipmentController,
        public phaseController: PhaseController, public tenantSettingController: TenantSettingMaintenanceController) {

        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();
    }


    private _route: RouteCardDto;
    @Input()
    set route(value: RouteCardDto) {
        if (this._route === value)
            return;
        this._route = value;
        if (this._route)
            this.routeId = this.route.routeId;
        else
            this.routeId = 0;
    }
    get route(): RouteCardDto {
        return this._route;
    }

    private __routeId: number;
    @Input()
    set routeId(value: number) {
        if (this.__routeId === value)
            return;
        const prevRouteId = this.__routeId;
        this.__routeId = value;
        if (prevRouteId)
            this.loadRouteLog();
    }
    get routeId(): number {
        return this.__routeId;
    }


    private _shipment: ShipmentCardDto;
    @Input()
    set shipment(value: ShipmentCardDto) {
        if (this._shipment === value)
            return;
        const prevShipment = this._shipment;
        console.log(value, prevShipment);
        this._shipment = value;

        this._shipmentId = value.shipmentId
        this.loadShipmentLog();

    }
    get shipment(): ShipmentCardDto {
        return this._shipment;
    }

    private _shipmentId: number;
    @Input()
    set shipmentId(value: number) {
        if (this._shipmentId === value)
            return;
        const prevShipment = this._shipmentId;
        console.log(value, prevShipment);
        this._shipmentId = value;

        this.loadShipmentLog();
    }
    get shipmentId(): number {
        return this._shipmentId;
    }

    private _phase: PhaseCardDto;
    @Input() public set phase(value: PhaseCardDto) {
        if (this._phase === value)
            return;

        this._phase = value;
        this.loadPhaseLog();
    }
    public get phase(): PhaseCardDto {
        return this._phase;
    }

    ngOnInit(): void {
        this.activeInterestedFields.add(FilterDto.build("ALL", "ROUTELOGSTABLE.FILTER.ALL.CAPTION", "ROUTELOGSTABLE.FILTER.ALL.HELP", 1, false, ["BLAME", "INTERESTED"]));
        this.activeInterestedFields.add(FilterDto.build("INTERESTED", "ROUTELOGSTABLE.FILTER.INTERESTED.CAPTION", "ROUTELOGSTABLE.FILTER.INTERESTED.HELP", 2, true, ["ALL", "BLAME"]));
        this.activeInterestedFields.add(FilterDto.build("BLAME", "ROUTELOGSTABLE.FILTER.BLAME.CAPTION", "ROUTELOGSTABLE.FILTER.BLAME.HELP", 3, false, ["ALL", "INTERESTED"]));

        this.loadData(FilterStripDto.getFirstActive(this.activeInterestedFields));
    }

    public activeInterestedFields: FilterStripDto = new FilterStripDto();
    private _routeLogsFilter: FilterLogDto = new FilterLogDto();

    public logs: Array<BaseLogDto>;

    loadData(filter: FilterDto) {

        if (filter && filter.filterActive)
            this._routeLogsFilter.filterType = filter.filterId;

        this.loadRouteLog();
        this.loadShipmentLog();
        this.loadPhaseLog();
    }

    loadRouteLog() {
        if (this.routeId == null || HLong.isNullOrNullLong(this.routeId)) {
            this.logs = [];
            return;
        }
        this.routeController.getRouteLogs(this.routeId, this._routeLogsFilter).subscribe((data: Array<RouteLogDto>) => {
            this.logs = data;
            this.itemsCount = this.logs.length;
        });
    }
    loadShipmentLog() {
        if (this.shipment == null || HLong.isNullOrNullLong(this.shipmentId)) {
            this.logs = null;
            return;
        }
        this.shipmentController.getShipmentLogs(this.shipmentId, this._routeLogsFilter).subscribe((data: Array<ShipmentLogDto>) => {
            this.logs = data;
            this.itemsCount = this.logs.length;
        });
    }

    loadPhaseLog() {
        if (this.phase != null) {
            this.phaseController.getPhaseLogs(this.phase.phaseId, this._routeLogsFilter).subscribe((data: Array<PhaseLogDto>) => {
                this.logs = data;
                this.itemsCount = this.logs.length;
            });
        }
    }

    public showAside: boolean = false;
    onClickUser(item: BaseLogDto) {
        if (item != null && item.userId != null) {
            if (this.showAside)
                return;
            this.showAside = true;
            this.sharedAsideFactory.invokeUserLogInfo(item.userId).then(value => {
                this.showAside = false;
            });
        }
    }

    public itemsCount = 0;
    public sliceCount: number = 200;
    public onSliceMore() {
        this.sliceCount = this.sliceCount + 100;
    }


    pretty(data) {
        return JSON.stringify(data);
    }
}
