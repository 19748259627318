export enum PhaseSlotLogTypes {
    SetSlot = "SetSlot",
    SetSlotOutOfHours = "SetSlotOutOfHours",
    PhaseEndBefore = "PhaseEndBefore",
    YourAreLate = "YourAreLate",
    UserRelease = "UserRelease",
    Overbooking = "Overbooking",
    ObtainSlot = "ObtainSlot",
    PhaseSetIncomplete = "PhaseSetIncomplete",
    PhaseSetCanceled = "PhaseSetCanceled",
    DividedRoute = "DividedRoute",
    GroupedRoute = "GroupedRoute",
    SetSlotJointAssignament = "SetSlotJointAssignament",
    SlotNotCompatible = "SlotNotCompatible",
    PhaseSwapSlot = "PhaseSwapSlot",
    DockIncompatibleWithPhase = "DockIncompatibleWithPhase",
    PhaseWithSlotAvailable = "PhaseWithSlotAvailable",
    DeletePhase = "DeletePhase",
    PhaseNotExists = "PhaseNotExists",
    none = "none",
    SystemRelease = "SystemRelease"
}

export class HPhaseSlotLogTypes {
    public static MEMORABLES = [
        PhaseSlotLogTypes.PhaseEndBefore, PhaseSlotLogTypes.YourAreLate,
        PhaseSlotLogTypes.GroupedRoute];

    public static isMemorableReason(type: PhaseSlotLogTypes): boolean {
        if (type == null)
            type = PhaseSlotLogTypes.none;

        return this.MEMORABLES.indexOf(type) > -1;
    }
}
