import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { HColor, HString, HTranslateService, PhaseSlotLogTypes } from '@shared/src/public-api';
import { SharedInactivityService } from '@shared/src/services/inactivity/SharedInactivityService';
import { TotemController } from '@shared/src/controllers/totem/totem.controller';
import { TotemDto } from '@shared/src/controllers/totem/TotemDto';
import { TotemSteps } from '@shared/src/controllers/totem/TotemSteps';
import { TotemPhaseSlotDto } from '@shared/src/controllers/totem/TotemPhaseSlotDto';

@Component({
    selector: 'shared-totem-slot',
    templateUrl: './shared.totem.slot.component.html',
    styleUrls: ['./shared.totem.slot.component.scss']
})
export class SharedTotemSlotComponent implements OnInit, OnDestroy {

    constructor(protected inactivityService: SharedInactivityService,
        protected totemService: TotemController,
        protected translateService: HTranslateService) {

    }

    public totemSteps = TotemSteps;

    public showIntervals: boolean = false;

    ngOnInit(): void {
    }
    ngOnDestroy() {
    }


    private _totem: TotemDto;
    @Input()
    public set totem(value: TotemDto) {
        if (this._totem === value)
            return;
        this._totem = value;
    }
    public get totem(): TotemDto {
        return this._totem;
    }

    @Output() onRefresh = new EventEmitter<TotemDto>();

    @Output() onFinish: EventEmitter<any> = new EventEmitter<any>();


    public getTitle(): string {
        if(this.totem.step == TotemSteps.slotWithSlot){
            if(this.totem.canChangeSlot)
                return "TOTEMPAGE.SLOT." + HString.toUpperCase(this.totem.step) + ".TITLE.CHANGESLOT";
            return "TOTEMPAGE.SLOT." + HString.toUpperCase(this.totem.step) + ".TITLE.REGISTERED";
        }
        return "TOTEMPAGE.SLOT." + HString.toUpperCase(this.totem.step) + ".TITLE";
    }
    public getHelp(): string {
        if(this.totem.identificationSelected.slotInfo.isBlockedBookSlotSystem){
            if(this.totem.step == TotemSteps.slotWithOutSlot || this.totem.step == TotemSteps.slotGoParking)
                return "TOTEMPAGE.SLOT." + HString.toUpperCase(this.totem.step) + ".HELPBLOCKED";
        }else if(this.totem.step == TotemSteps.slotGoParking && this.totem.identificationSelected.slotInfo.slotStatus == PhaseSlotLogTypes.YourAreLate)
            return "TOTEMPAGE.SLOT." + HString.toUpperCase(this.totem.step) + ".HELPWITHOUTSLOT";
        
        return "TOTEMPAGE.SLOT." + HString.toUpperCase(this.totem.step) + ".HELP";
    }
    public getButtonActionCode(): string {
        return "TOTEMPAGE.SLOT." + HString.toUpperCase(this.totem.step) + ".BUTTON.CODE";
    }
    public getButtonActionName(): string {
        return "TOTEMPAGE.SLOT." + HString.toUpperCase(this.totem.step) + ".BUTTON.NAME";
    }

    public buttonOk() {
        this.onFinish.next();
    }

    public onSlotChanged() {
        this.showIntervals = false;
        this.refresh();
    }
    public refresh() {
        this.onRefresh.next();
    }

    getTextColor(data: HColor) {
        if (data != null)
            return HColor.getWhiteBlackFromColor(data.web);

        return HColor.getWhiteBlackFromColor(HColor.defaultColor().web);
    }
    getBackColor(data: HColor) {
        return HColor.getColorOrDefaultColor(data, HColor.defaultColor().web);
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}


