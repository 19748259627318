import { Pipe } from '@angular/core';
import { HDateHour } from '../datatypes/HDateHour';
import { TruckingShipmentPackageDto } from '../dtos/trucking/TruckingShipmentPackageDto';

@Pipe({
	name: "orderbytruckingshipmentpackagedto"
})
export class OrderByTruckingShipmentPackageDto {
	transform(array: Array<TruckingShipmentPackageDto>, asc: boolean): Array<TruckingShipmentPackageDto> {
		if (array == null)
			return null;

		array.sort((a: TruckingShipmentPackageDto, b: TruckingShipmentPackageDto) => {
			if (asc)
				return OrderByTruckingShipmentPackageDto.compareAny(a, b);

			return OrderByTruckingShipmentPackageDto.compareAny(b, a);
		});
		return array;
	}

	public static compareAny(a: TruckingShipmentPackageDto, b: TruckingShipmentPackageDto): number {

		return this.validateDateHour(a, b);
	}

	public static validateDateHour(a: TruckingShipmentPackageDto, b: TruckingShipmentPackageDto): number {

		let av = a.dateHourMinToLoadPackage;
		let bv = b.dateHourMinToLoadPackage;
		
		if (HDateHour.isNullOrNullValue(av) && !HDateHour.isNullOrNullValue(bv))
			return OrderByTruckingShipmentPackageDto.xivato(1, a, b);
		else if (!HDateHour.isNullOrNullValue(av) && HDateHour.isNullOrNullValue(bv))
			return OrderByTruckingShipmentPackageDto.xivato(-1, a, b);
		else if (HDateHour.isGreaterThan(av, bv))
			return OrderByTruckingShipmentPackageDto.xivato(1, a, b);
		else if (HDateHour.isMinorThan(av, bv))
			return OrderByTruckingShipmentPackageDto.xivato(-1, a, b);
		else
			return a.packageKey > b.packageKey ? 1 : -1;
	}

	public static xivato(valor, a: TruckingShipmentPackageDto, b: TruckingShipmentPackageDto) {
		return valor;
	}
}
