import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { HString, HTranslateService, PhaseController, TranslateInPipe } from '@shared/src/public-api';
import { TotemController } from '@shared/src/controllers/totem/totem.controller';
import { TotemDto } from '@shared/src/controllers/totem/TotemDto';
import { TotemSteps } from '@shared/src/controllers/totem/TotemSteps';

@Component({
    selector: 'shared-totem-exit',
    templateUrl: './shared.totem.exit.component.html',
    styleUrls: ['./shared.totem.exit.component.scss']
})
export class SharedTotemExitComponent implements OnInit, OnDestroy {

    constructor(
        protected totemService: TotemController,
        protected translateService: HTranslateService,
        protected translateIn: TranslateInPipe) {

    }

    public totemSteps = TotemSteps;

    ngOnInit(): void {
    }
    ngOnDestroy() {
    }

    private _totem: TotemDto;
    @Input()
    public set totem(value: TotemDto) {
        if (this._totem === value)
            return;
        this._totem = value;
        this.loadData();
    }
    public get totem(): TotemDto {
        return this._totem;
    }

    public reasonHtml: string = "";
    loadData() {
        this.reasonHtml = "";
        if (this.totem != null && this.totem.identificationSelected != null) {
            let translationResult = this.translateIn.transform(this.totem.identificationSelected.exitBlockedHelp, this.totem.language);

            if(translationResult != null){
                if (translationResult.subscribe) {
                    // Si és un Observable, ens hi subscrivim
                    translationResult.subscribe((translatedText: string) => {
                        this.reasonHtml = translatedText;
                    });
                } else {
                    // Si no és un Observable, assignem directament
                    this.reasonHtml = translationResult;
                }
            }
        }
    }

    getGatesToExit(): string {
        if (this.totem != null && this.totem.identificationSelected != null)
            return this.totem.identificationSelected.gatesToExit;

        return "-";
    }

    @Output() onFinish: EventEmitter<any> = new EventEmitter<any>();

    @Output() onClickAction = new EventEmitter<string>();

    public getButtonActionCode(): string {
        return "TOTEMPAGE.EXIT." + HString.toUpperCase(this.totem.step) + ".BUTTON.CODE";
    }

    public getButtonActionName(): string {
        return "TOTEMPAGE.EXIT." + HString.toUpperCase(this.totem.step) + ".BUTTON.NAME";
    }

    public buttonOk() {
        this.onFinish.next();
    }

    public buttonGoToSignature() {
        this.onClickAction.next("GOSTEPTOSIGNATURE");
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}


