import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FilterStripDto } from "@shared/src/components/core/maintenance/filter/FilterStripDtos";
import { BookingDto } from "@shared/src/dtos/booking/BookingDto";
import { BookingFilterDto } from "@shared/src/dtos/booking/BookingFilterDto";
import { BookingSlotDto } from "@shared/src/dtos/booking/BookingSlotDto";
import { ContainerNumberDto } from "@shared/src/dtos/booking/ContainerNumberDto";
import { InternationalPlanningDto } from "@shared/src/dtos/booking/InternationalPlanningDto";
import { MultiSelectDto } from "@shared/src/dtos/booking/MultiSelectDto";
import { ActionsStripDto } from "@shared/src/dtos/maintenance/action/ActionsStripDto";
import { OptionsStripDto } from "@shared/src/dtos/maintenance/option/OptionsStripDto";
import { AlertService, BookingStatus, LoadingService, RouteCardDto } from "@shared/src/public-api";
import { Observable } from "rxjs-compat";
import { BaseController } from "../base.controller";
import { MaintenanceSearchByTextDto } from "../maintenance/_base/MaintenanceSearchByTextDtos";
import { SecurityController } from "../security/security.controller";
import { ActionDto } from "@shared/src/dtos/maintenance/action/actionDto";
import { SlotChangeReasonItemDto } from "@shared/src/dtos/slotchangereason/SlotChangeReasonItemDto";
import { BookingSlotChangeReasonActionTypes } from "@shared/src/enums/BookingSlotChangeReasonActionTypes";
import { BookingSlotChangeReasonManageDto } from "@shared/src/dtos/booking/slotchangereason/BookingSlotChangeReasonManageDto";

@Injectable({
  providedIn: 'root',
})
export class BookingController extends BaseController {
  private base = '/booking'

  constructor(
    @Inject('SecurityController') protected securityController: SecurityController,
    protected http: HttpClient,
    @Inject('AlertService') protected alertService: AlertService,
    protected translateService: TranslateService,
    @Inject('LoadingService') protected loadingService: LoadingService,
    protected router: Router
  ) {
    super(http, alertService, translateService, loadingService, router);
  }

  public getFilters(): Observable<FilterStripDto> {
    return this.get(this.base + "/filters");
  }

  public getOptions(bookingId: number): Observable<OptionsStripDto> {
    return this.get(this.base + "/options" + (bookingId ? "/" + bookingId : ""));
  }

  public getActions(bookingId: number): Observable<ActionsStripDto> {
    return this.get(this.base + "/actions" + (bookingId ? "/" + bookingId : ""));
  }
  public executeAction(bookingId: number, action: ActionDto): Observable<Boolean> {
    return this.put(this.base + "/actions/" + bookingId, action, "executeAction");
  }
  public executeActionWithSlotChangeReason(bookingId: number, action: BookingSlotChangeReasonActionTypes, slotChangeReasons: SlotChangeReasonItemDto[]): Observable<Boolean> {
    return this.post(this.base + "/actionswhitslotchangereason/bookingid/" + bookingId + "/action/" + action, slotChangeReasons, "executeActionWithSlotChangeReason");
  }

  public getSlotChangeReasonManage(bookingId: number, action: BookingSlotChangeReasonActionTypes): Observable<BookingSlotChangeReasonManageDto> {
      return this.get(this.base + "/slotchangereasonmanage/bookingid/" + bookingId + "/action/" + action);
  }
      
  public getRequiredSlotChangeReason(bookingId: number, action: BookingSlotChangeReasonActionTypes): Observable<boolean> {
    return this.get(this.base + "/requiredslotchangereason/bookingid/" + bookingId + "/action/" + action);
  }

  public getBookingsByKey(filter: MaintenanceSearchByTextDto, bookingKey: string): Observable<Array<BookingDto>> {
    filter.searchtext = bookingKey;
    return this.post(this.base + "/search/bykey/", filter, "getAllBookingsByKey");
  }
  public getAllBookings(filter: MaintenanceSearchByTextDto): Observable<Array<BookingDto>> {
    return this.post(this.base + "/search", filter, "getAllBookings");
  }

  public getBooking(bookingId: number): Observable<BookingDto> {
    return this.get(this.base + "/" + bookingId);
  }

  public getBookingByRouteId(routeIdId: number): Observable<BookingDto> {
    return this.get(this.base + "/routeId/" + routeIdId);
  }

  public getBookingsByBookingNumber(number: string): Observable<Array<BookingDto>> {
    return this.get(this.base + "/by-number/" + number);
  }

  public getBookingAvailableStatuses(bookingId: number): Observable<Array<BookingStatus>> {
    return this.get(this.base + "/" + bookingId + "/available-status");
  }

  public createBooking(data: BookingDto): Observable<BookingDto> {
    return this.postBusy(this.base, data, "createBooking");
  }

  public updateBooking(bookingId: number, data: BookingDto): Observable<BookingDto> {
    return this.putBusy(this.base + "/" + bookingId, data, "updateBooking");
  }

  public setFreightForwarder(bookingId: number, partnerFreightForwarderId: number): Observable<BookingDto> {
    return this.putBusy(this.base + "/" + bookingId + "/set/freightforwarder/" + (partnerFreightForwarderId ? partnerFreightForwarderId : ""), null, "setFreightForwarder");
  }

  public setShipper(bookingId: number, partnerShipperId: number): Observable<BookingDto> {
    return this.putBusy(this.base + "/" + bookingId + "/set/shipper/" + (partnerShipperId ? partnerShipperId : ""), null, "setShipper");
  }

  public setStatus(bookingId: number, status: BookingStatus): Observable<BookingDto> {
    return this.put(this.base + "/" + bookingId + "/set/status/" + status, null, "setStatus");
  }

  public cloneBooking(bookingId: number): Observable<BookingDto> {
    return this.postBusy(this.base + "/clone/" + bookingId, null, "cloneBooking");
  }

  public deleteBooking(bookingId: number): Observable<boolean> {
    return this.deleteBusy(this.base + "/" + bookingId);
  }

  ////////////////////////////
  /////////// SLOTS //////////
  ////////////////////////////

  public getBookingSlot(routeId: number): Observable<BookingSlotDto> {
    return this.get(this.base + "/" + routeId + "/slot");
  }

  public reserveBooking(bookingId: number, routeId: number): Observable<Boolean> {
    return this.postBusy(this.base + "/" + bookingId + "/slot/reserve/" + routeId, null, "reserveBooking");
  }

  public multiReserveBooking(bookingId: number, data: MultiSelectDto): Observable<Boolean> {
    return this.postBusy(this.base + "/" + bookingId + "/slot/multi-reserve", data, "multiReserveBooking");
  }

  public releaseBooking(bookingId: number, routeId: number): Observable<Boolean> {
    return this.postBusy(this.base + "/" + bookingId + "/slot/release/" + routeId, null, "releaseBooking");
  }

  public multiReleaseBooking(bookingId: number, data: MultiSelectDto): Observable<Boolean> {
    return this.postBusy(this.base + "/" + bookingId + "/slot/multi-release", data, "multiReleaseBooking");
  }

  public setContainerNumber(bookingId: number, bookingSlotId: number, data: ContainerNumberDto): Observable<Boolean> {
    return this.putBusy(this.base + "/" + bookingId + "/slot/" + bookingSlotId + "/container-number", data, "setContainerNumber");
  }

  public getInternationalPlanning(filter: BookingFilterDto, busy: boolean): Observable<InternationalPlanningDto> {
    if (busy)
      return this.postBusy(this.base + "/international-planning", filter, "getBookingsInForce");
    return this.post(this.base + "/international-planning", filter, "getBookingsInForce");
  }

  ////////////////////////////
  ////////// ROUTES //////////
  ////////////////////////////

  public getRoutes(bookingId: number): Observable<Array<RouteCardDto>> {
    return this.get(this.base + "/" + bookingId + "/routes");
  }

  public getConflictiveRoutes(bookingId: number): Observable<Array<RouteCardDto>> {
    return this.get(this.base + "/" + bookingId + "/conflictive-routes");
  }

  public getPendingRoutes(bookingId, filter: MaintenanceSearchByTextDto, busy: boolean): Observable<Array<RouteCardDto>> {
    if (busy)
      return this.postBusy(this.base + "/pending-routes" + (bookingId ? "/" + bookingId : ""), filter, "getPendingRoutes");
    return this.post(this.base + "/pending-routes" + (bookingId ? "/" + bookingId : ""), filter, "getPendingRoutes");
  }
}