<div class="card" *ngIf="shipment">
    <div class="internalcard" [ngClass]="{'pendingtrucking': !shipment.isTrucking, 'trucked': shipment.isTrucking }">
        <div class="shipmentId">{{shipment.shipmentId}}</div>
        <div class="line">
            <div class="shipmentKey">
                <shared-copyclipboard [value]="shipment.shipmentKey">{{shipment.shipmentKey}} </shared-copyclipboard>
            </div>
            <i class="fa fa-chevron-circle-right" *ngIf="!__showDetails" (click)="onClickOpenDetails($event)" aria-hidden="true"></i>
            <i class="fa fa-chevron-circle-down" *ngIf="__showDetails" (click)="onClickCloseDetails($event)" aria-hidden="true"></i>
        </div>
        <div class="line">
            <actions class="baractions" [actionstrip]="actions" (clickAction)="onClickAction($event)"></actions>
        </div>
        <div class="bottom-zone">
            <shared-trucking-shipment-strip-card-component [shipment]="shipment"></shared-trucking-shipment-strip-card-component>
        </div>
        <div class="detail" *ngIf="__showDetails && shipmentExpand != null">
            <shared-trucking-shipment-detail class="container-panel"
                [shipmentExpand]="shipmentExpand" (updateShipment)="onRefreshShipment($event)">
            </shared-trucking-shipment-detail>
        </div>
        <div class="packages" *ngIf="!__showDetails && __showPackages && shipmentExpand != null && shipmentExpand.packages != null">
            
            <i class="fa fa-times-circle" (click)="onClickClosePackages($event)" aria-hidden="true"></i>
            <shared-trucking-package-item class="package-item" 
                *ngFor="let package of shipmentExpand.packages" [selectedRouteExpand]="selectedRouteExpand" [package]="package" (click)="onClickPackage($event, package)"
                [ngClass]="{'selected':selectedPackage!=null && package.packageId==selectedPackage.packageId}"
                [isDraggable]="true" (refresh)="onRefreshShipment($event)">
            </shared-trucking-package-item>
                
        </div>
    </div>
</div>