import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseController } from '../base.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { api } from '../../environments/environment';
import { DocumentParentObjectDto } from '@shared/src/dtos/documentaryreview/DocumentParentObjectDto';
import { DocumentViewerTypeDto } from '@shared/src/dtos/document/struct/DocumentViewerTypeDto';
import { OpenDocument } from '@shared/src/public-api';
import { OpenDocumentDetail } from '@shared/src/dtos/open/OpenDocumentDetail';


@Injectable({
    providedIn: 'root',
})
export class OpenDocumentController extends BaseController {

    private base = '/public/document'

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getDocumentDetailPdfById(id: number): Observable<Blob> {
        return this.get(this.base + "/documentdetail/pdf/" + id);
    }

    public getApiDocumentDetailPdfById(id: number): string {
        return  api(this.base + "/documentdetail/pdf/" + id);
    }

    public getDocument(documentId: number): Observable<OpenDocument> {
            return this.get(this.base + "/document/" + documentId);
    }

    public getDocumentDetail(documentDetailId: number): Observable<OpenDocumentDetail> {
        return this.get(this.base + "/documentdetail/" + documentDetailId);
    }
    
    public getAllDocumentsByParentObject(parentObject: DocumentParentObjectDto): Observable<Array<DocumentViewerTypeDto>> {
        return this.post(this.base + "/get/byparentobject", parentObject, "getAllDocumentsByParentObject");
    }

}
