import { Location } from '@angular/common';
import { Component, Inject, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FilterStripDto } from '@shared/src/components/core/maintenance/filter/FilterStripDtos';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { SocketController } from '@shared/src/controllers/socket/socket.controller';
import { HDateHour } from "@shared/src/datatypes/HDateHour";
import { HString } from '@shared/src/datatypes/HString';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SharedAsideFactory } from '../../aside/shared.aside.factory';
import { TruckingController } from '@shared/src/controllers/trucking/trucking.controller';
import { FilterTruckingRouteDto } from '@shared/src/dtos/trucking/FilterTruckingRouteDto';
import { SharedActiveRoutes } from '../../components.api';
import { TruckingRouteDto } from '@shared/src/dtos/trucking/TruckingRouteDto';
import { FilterDto } from '../../core/maintenance/filter/FilterDtos';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';


@Component({
	selector: 'shared-trucking-routes',
	templateUrl: './shared.trucking.routes.html',
	styleUrls: ['./shared.trucking.routes.scss']
})
export class SharedTruckingRoutes implements OnInit {


	searchTextUpdate = new Subject<string>();

	public static SLICECOUNTINITIAL: number = 100;
	public static SLICECOUNTMORE: number = 100;

	public useIonic: boolean = false;
	constructor(public activatedRoute: ActivatedRoute,
		public router: Router, public location: Location, public truckingController: TruckingController,
		@Inject('HomeController') public homeController: HomeController,
		@Inject("SecurityController") public securityController: SecurityController,
		@Inject('SharedAsideFactory') public sharedAsideFactory: SharedAsideFactory,
		public socketController: SocketController,
		public translateService: TranslateService) {

		// Debounce search.
		this.searchTextUpdate.pipe(
			debounceTime(HomeController.DEFAULT_BOUNCE_BUTTON_TIME),
			distinctUntilChanged())
			.subscribe(value => {
				//Abans d'anar a la base de dades, hem de tenir mínim 3 digits o que el searchtext sigui "" (voldrà dir que borra tot per tornar a començar)
				if (HString.isNullOrNullString(this.searchText))
					this.loadData(true, false);
				else if (this.searchText.length > 2)
					this.loadExactKeyData(false);
			});

		if (homeController.useIonic)
			this.useIonic = homeController.useIonic();

	}

	public searchText: string = "";
	public searchText2: string = "";

	public filter: FilterStripDto;
	public filterSearchByText: FilterTruckingRouteDto = FilterTruckingRouteDto.buildPage("", []);
	public routes: Array<TruckingRouteDto> = [];
	public routesExactKey: Array<TruckingRouteDto> = [];
	public filterMetadataExactKey: any = {};
	public filterMetadata: any = {};

  	public actions: ActionsStripDto = new ActionsStripDto();

	ngOnInit(): void {
		this.sliceCount = SharedActiveRoutes.SLICECOUNTINITIAL;
		this.filter = new FilterStripDto();
		this.filter.add(FilterDto.build("TRUCKING.ROUTE.FILTER.NOCONFIRMED", "TRUCKING.ROUTE.FILTER.NOCONFIRMED.CAPTION", "TRUCKING.ROUTE.FILTER.NOCONFIRMED.HELP", 1, false, ["TRUCKING.ROUTE.FILTER.ALL", "TRUCKING.ROUTE.FILTER.CONFIRMED", "TRUCKING.ROUTE.FILTER.PREDEFINED"]));
        this.filter.add(FilterDto.build("TRUCKING.ROUTE.FILTER.CONFIRMED", "TRUCKING.ROUTE.FILTER.CONFIRMED.CAPTION", "TRUCKING.ROUTE.FILTER.CONFIRMED.HELP", 2, false, ["TRUCKING.ROUTE.FILTER.ALL", "TRUCKING.ROUTE.FILTER.NOCONFIRMED", "TRUCKING.ROUTE.FILTER.PREDEFINED"]));
		this.filter.add(FilterDto.build("TRUCKING.ROUTE.FILTER.PREDEFINED", "TRUCKING.ROUTE.FILTER.PREDEFINED.CAPTION", "TRUCKING.ROUTE.FILTER.PREDEFINED.HELP", 2, false, ["TRUCKING.ROUTE.FILTER.ALL", "TRUCKING.ROUTE.FILTER.CONFIRMED", "TRUCKING.ROUTE.FILTER.NOCONFIRMED"]));
		this.filter.add(FilterDto.build("TRUCKING.ROUTE.FILTER.ALL", "TRUCKING.ROUTE.FILTER.ALL.CAPTION", "TRUCKING.ROUTE.FILTER.ALL.HELP", 3, true, ["TRUCKING.ROUTE.FILTER.NOCONFIRMED", "TRUCKING.ROUTE.FILTER.CONFIRMED", "TRUCKING.ROUTE.FILTER.PREDEFINED"]));
        	  
		this.loadData(true, false);
	}

	public _routeId: number;
	public get routeId(): number {
		return this._routeId;
	}
	public set routed(value: number) {
		if (this._routeId === value)
			return;
		this._routeId = value;
	}

	ngAfterViewChecked(): void {
	}


	loadFilterOfSearchByText() {
		if (this.filter != null)
			this.filterSearchByText.filterKeys = FilterStripDto.getActiveKeys(this.filter);
	}

	loadExactKeyData(forcedsearch: boolean) {

		this.loadFilterOfSearchByText();

		if (!HString.isNullOrNullString(this.searchText)) {
			this.routesExactKey = null;
			this.filterSearchByText.searchText = this.searchText;
			this.truckingController.getTruckingRoutesExactKey(this.filterSearchByText).subscribe(data => {

				if (data == null) {
					this.routes = new Array();
					return;
				}
				this.routesExactKey = data.routes;
			});
		}
		else {
			this.routesExactKey = null;
		}
		this.loadData(true, false);
	}

	public loading: boolean = false;
	loadData(forcedsearch: boolean, busy: boolean) {

		//QUAN COMENCEM A BUSCAR, posem el searchtext2 igual al searchText per a que doni la sensació que busca
		this.searchText2 = this.searchText;

		//Si tenim route seleccionat, no te sentit fer cerca total
		if (this.routeId > 0)
			return;

		this.loadFilterOfSearchByText();

		this.filterSearchByText.searchText = this.searchText;
		this.filterSearchByText.idReserca = HDateHour.toStringSorteable(HDateHour.now());
		this.filterSearchByText.sliceCount = this.sliceCount;
		this.truckingController.getTruckingRoutes(this.filterSearchByText).subscribe(data => {

			if (data == null) {
				this.routes = new Array();
				return;
			}

			//Si no es la última petició feta, obiem la resposta
			if (!HString.equals(data.idReserca, this.filterSearchByText.idReserca)) {
				return;
			}

			this.routes = data.routes;

			this.routesCount = data.routeAllCount;
			if (this.routes != null) {
				this.itemsCount = data.routeAllCount;

				//comprovem si la seleccionada encara esta
				if (this.selectedRoute != null) {
					let findselected = false;
					let i = 0;
					while (i < this.routes.length) {
						if (this.routes[i].routeId === this.selectedRoute.routeId) {
							findselected = true;
							this.selectedRoute = this.routes[i];
						}
						if (findselected) {
							i = this.routes.length;
						}
						i++;
					}
					//Si la seleccionada ja no està a la llista, la resetejem
					if (!findselected)
						this.selectedRoute = null;
				}
			} else {
				this.selectedRoute = null;
			}

			this.loadFilterOfSearchByText();

			//Quan acabem de buscar ja no cal filtrar en local, ja ho ha fet el servidor
			this.searchText2 = "";

			this.loadActions();
		});
	}

	loadActions(){
		let tactions: ActionsStripDto = new ActionsStripDto();

		tactions.add(ActionDto.buildAdd("NEWROUTE", "TRUCKING.ROUTE.ACTION.NEWROUTE.CAPTION", "TRUCKING.ROUTE.ACTION.NEWROUTE.HELP", "fa-plus-square", 1, 0, 0, ""));
	
		if(this.selectedRoute != null && !this.selectedRoute.isBrokenForTrucking)
			tactions.add(ActionDto.build("BROKENROUTE", "TRUCKING.ROUTE.ACTION.BROKENROUTE.CAPTION", "TRUCKING.ROUTE.ACTION.BROKENROUTE.HELP", "fa-chain-broken", 2, 0, -1, ""));
	
		this.actions = tactions;
	}

	public routesCount: number = 0;
	public _selectedRoute: TruckingRouteDto;
	@Input()
	public get selectedRoute(): TruckingRouteDto {
		return this._selectedRoute;
	}
	public set selectedRoute(value: TruckingRouteDto) {
		if (this._selectedRoute === value)
			return;
		this._selectedRoute = value;
		this._routeId = value != null ? value.routeId : 0;
		this.loadActions();
	}

	public itemsCount = 0;
	public sliceCount: number = SharedActiveRoutes.SLICECOUNTINITIAL;
	public onSliceMore() {
		this.sliceCount = this.sliceCount + SharedActiveRoutes.SLICECOUNTMORE;
		this.loadData(true, false);
	}

	getRoutes() {
		if (this.routes != null) {
			return this.routes;
		}
		return new Array();
	}

	pretty(value) {
		return JSON.stringify(value);
	}


	onSearchFocus(event: any) {
		if (event && event.stopPropagation)
			event.stopPropagation();

	}

	onSearchClick(event: any) {
		if (event && event.stopPropagation)
			event.stopPropagation();
	}


	filterUpdate() {
		this.selectedRoute = null;
		this.loadData(true, true);
	}

	@Output() clickItem = new EventEmitter<TruckingRouteDto>();
	onClickItem(item: TruckingRouteDto) {
		if (item == null) {
			this.selectedRoute = null;
			this.clickItem.next(this.selectedRoute);
			return;
		}
		this.selectedRoute = item;
		this.clickItem.next(this.selectedRoute);
	}

	onClickBack(refresh: boolean) {
		this.selectedRoute = null;
		if (refresh || this.routes == null || this.routes.length == 0)
			this.loadData(true, false);
	}

	onNewRoute(event) {
		this.truckingController.createEmptyRoute().subscribe(data => {
			this.routes.push(data);
			this.onClickItem(data);
			this.loadData(true, false);
		});
	}

	onClickAction(action: ActionDto) {
		
		if (action != null) {
			if (action.actionId == "NEWROUTE") {
				this.truckingController.createEmptyRoute().subscribe(data => {
					this.routes.push(data);
					this.onClickItem(data);
					this.loadData(true, false);
				});
			} else if (action.actionId == "BROKENROUTE") {
				if(this.selectedRoute != null){
					this.truckingController.brokenRoute(this.selectedRoute.routeId).subscribe(data => {
						this.onRefreshRoute(data);
						this.onClickItem(data);
						this.loadData(true, false);
					});
				}
			} 
		}
	}
	
	@Output() refreshRoute = new EventEmitter<TruckingRouteDto>();
	onRefreshRoute(item: TruckingRouteDto) {

		let finditem = false;
		let findselected = false;
		let i = 0;
		while (i < this.routes.length) {
			if (this.routes[i].routeId === item.routeId) {
				finditem = true;
				this.routes[i] = item;
			}

			if (this.routes[i].routeId === this.selectedRoute.routeId) {
				findselected = true;
				this.selectedRoute = this.routes[i];
			}
			if (findselected && finditem) {
				i = this.routes.length;
			}
			i++;
		}
		//Si la seleccionada ja no està a la llista, la resetejem
		if (!findselected)
			this.selectedRoute = null;
		
		this.refreshRoute.next(this.selectedRoute);
	}
	
}
