import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PhaseController } from '@shared/src/controllers/route/phase/phase.controller';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { PhaseSlotManageDto } from '@shared/src/dtos/phase/slot/manage/PhaseSlotManageDto';
import { HTranslateService, HTriState } from '@shared/src/public-api';

@Component({
  selector: 'shared-phase-slot-manage-blocked-component',
  templateUrl: './shared.phase.slot.manage.blocked.component.html',
  styleUrls: ['./shared.phase.slot.manage.blocked.component.scss'],
})
export class SharedPhaseSlotManageBlockedComponent implements OnInit {

  constructor(public phaseController: PhaseController, private translateService: HTranslateService) {
  }

  public generalClass: string = "";

  @Input() public readonly: boolean = false;

  public actions: ActionsStripDto;

  public modificarReserva: boolean = false;

  public mostrar24h: boolean = false;

  public objTranslate: any;


  private _value: PhaseSlotManageDto;
  @Input() set value(value: PhaseSlotManageDto) {
    if (this._value === value)
      return;
    this._value = value;
    this.wellcome();
  }
  get value(): PhaseSlotManageDto {
    return this._value;
  }

  loadData() {
  }

  ngOnInit() {

  }

  @Output()
  refresh: EventEmitter<any> = new EventEmitter();
  onRefresh() {
    this.refresh.next();
  }

  onIsBlockedUserChanged(triState:HTriState){
    if (this.value.phase == null || this.value.phaseId == null)
      return;

    this.phaseController.blockPhaseByUser(this.value.phaseId, triState).subscribe(data => {
      this.onRefresh();
    });
    
  }

  onIsBlockedExternalUserChanged(triState:HTriState){
    if (this.value.phase == null || this.value.phaseId == null)
      return;

    this.phaseController.blockExternalsPhaseByUser(this.value.phaseId, triState).subscribe(data => {
      this.onRefresh();
    });
  }
  pretty(value) {
    return JSON.stringify(value);
}
 onClickAction(action: ActionDto) {
     if(action == null)
       return;

     if(action.actionId == "PHASE.BLOCKSLOT.CALCULATE.SYSTEM"){
        this.phaseController.calculatePhaseBlocked(this.value.phaseId).subscribe(data => {
          this.onRefresh();
        });
     } else if(action.actionId == "PHASE.BLOCKEXTERNALSSLOT.CALCULATE.SYSTEM.CAPTION"){
        this.phaseController.calculatePhaseBlockedExternals(this.value.phase.phaseId).subscribe(data => {
          this.onRefresh();
        });
    }
  }

  public wellcome() {

    this.generalClass = "";

    if(this.value == null){
      this.actions = null;
      return;
    }


    if (this.value.actionsBlocked != null)
      this.actions = ActionsStripDto.buildFromActionsStripDto(this.value.actionsBlocked);
    else
      this.actions = null;

    if (this.value.phaseSlot != null) {
      if (this.value.phaseSlot.isSlotBlocked)
        this.generalClass += " isSlotBlocked";
      else
        this.generalClass += " noIsSlotBlocked";
      if (this.value.phaseSlot.isSlotBlockedExternal)
        this.generalClass += " isSlotBlockedExternal";
      else
        this.generalClass += " noIsSlotBlockedExternal";
      if (this.value.parentHasBlocked)
        this.generalClass += " isParentBlocked";
      else
        this.generalClass += " noIsParentBlocked";
      if (this.value.isBlockedSystem)
        this.generalClass += " isSystemBlocked";
      else
        this.generalClass += " noIsSystemBlocked";
      if (this.value.isBlockedExternalsSystem)
        this.generalClass += " isExSystemBlocked";
      else
        this.generalClass += " noIsExSystemBlocked";
    }
  }
}
