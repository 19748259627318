
import { Component, Inject, Input, OnInit } from '@angular/core';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { TenantSettingMaintenanceController } from '@shared/src/controllers/maintenance/tenant/TenantSettingMaintenanceController';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { PhaseSlotLogDto } from '@shared/src/dtos/phase/PhaseSlotLogDto';
import { PhaseController, SharedAsideFactory } from '@shared/src/public-api';


@Component({
    selector: 'shared-phase-slot-log-component',
    templateUrl: './shared.phase.slot.log.component.html',
    styleUrls: ['./shared.phase.slot.log.component.scss']
})
export class SharedPhaseSlotLogComponent implements OnInit {

    public useIonic: boolean = false;
    public searchText: string = "";

    constructor(@Inject('HomeController') protected homeController: HomeController,
        @Inject('SharedAsideFactory') protected sharedAsideFactory: SharedAsideFactory,
        public routeController: RouteController, public shipmentController: ShipmentController,
        public phaseController: PhaseController, public tenantSettingController: TenantSettingMaintenanceController) {

        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();
    }
    
    private _phaseId: number;
    @Input() public set phaseId(value: number) {
        if (this._phaseId === value)
            return;

        this._phaseId = value;
        this.loadData();
    }
    public get phaseId(): number {
        return this._phaseId;
    }

    ngOnInit(): void {
   
    }

    public logs: Array<PhaseSlotLogDto>;

    loadData() {

        if (this.phaseId != null) {
            this.phaseController.getPhaseSlotLogByPhaseId(this.phaseId).subscribe(data => {
                this.logs = data;
                this.itemsCount = this.logs.length;
            });
        }
    }


    public showAside: boolean = false;
    onClickUser(item: PhaseSlotLogDto) {
        if (item != null && item.userId != null) {
            if (this.showAside)
                return;
            this.showAside = true;
            this.sharedAsideFactory.invokeUserLogInfo(item.userId).then(value => {
                this.showAside = false;
            });
        }
    }

    public itemsCount = 0;
    public sliceCount: number = 200;
    public onSliceMore() {
        this.sliceCount = this.sliceCount + 100;
    }


    pretty(data) {
        return JSON.stringify(data);
    }
}
