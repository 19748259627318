<div *ngIf="shipment">
    <shared-shipment-item [shipment]="shipment"></shared-shipment-item>
    <div class="bar">
        <div class="baroptions">
            <options [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
        </div>
        <div class="baractions">
            <shared-state [(value)]="states" (stateChange)="onSelectorChangedState($event)" [readonly]="readonly">
            </shared-state>
            <actions *ngIf="!readonly" [actionstrip]="actions" (clickAction)="onClickAction($event)"></actions>
        </div>
    </div>
    <div class="info component" *ngIf="'INFO'==selectedOptionId">
        <div class="block">
            <div class="notes" [innerHTML]="shipment.notesHtml" matTooltip="{{shipment.notesText}}">
            </div>
            <div *ngIf="!shipment.originAddresses" class="block address origin">
                <div class="subtitle">{{'SHARED.SHIPMENT.ITEM.MAINTENANCE.ORIGIN.CAPTION'| translate}}</div>
                <div class="icon" (dblclick)="onMarkerDoubleClick('origin')">
                    <i class="fa fa-map-marker"></i>
                </div>
                <div class="address">
                    <span class="pre-tags-strip">@</span>
                    <shared-tags-strip-component class="line tags" [uniqueString]="shipment.originSkinAddress">
                    </shared-tags-strip-component>
                    <div class="line useraddress originUserAddress">{{shipment.originDescriptionShort}}</div>
                    <div class="line realaddress originRealAddress">{{shipment.originFormatedAddress}}</div>
                </div>
            </div>
            <div *ngIf="shipment.originAddresses" class="block address origin">
                <div class="subtitle">{{'SHARED.SHIPMENT.ITEM.MAINTENANCE.ORIGIN.CAPTION'| translate}}</div>
                <span class="multiaddress" *ngFor="let address of shipment.originAddresses">
                    <div class="icon" (dblclick)="onManualAddressMarkerDoubleClick(address)">
                        <i class="fa fa-map-marker"></i>
                    </div>
                    <div class="address">
                        <span class="pre-tags-strip">@</span>
                        <shared-tags-strip-component class="line tags" [uniqueString]="address.skin">
                        </shared-tags-strip-component>
                        <div class="line realaddress originRealAddress">{{address.formattedAddress}}</div>
                    </div>
                </span>
            </div>
            <div *ngIf="!shipment.destinationAddresses" class="block address destination">
                <div class="subtitle">{{'SHARED.SHIPMENT.ITEM.MAINTENANCE.DESTINATION.CAPTION'| translate}}</div>
                <div class="icon" (dblclick)="onMarkerDoubleClick('destination')">
                    <i class="fa fa-map-marker"></i>
                </div>
                <div class="address">
                    <span class="pre-tags-strip">@</span>
                    <shared-tags-strip-component class="line tags" [uniqueString]="shipment.destinationSkinAddress">
                    </shared-tags-strip-component>
                    <div class="line useraddress destinationUserAddress">{{shipment.destinationDescriptionShort}}</div>
                    <div class="line realaddress destinationRealAddress">{{shipment.destinationFormatedAddress}}</div>
                </div>
            </div>
            <div *ngIf="shipment.destinationAddresses" class="block address destination">
                <div class="subtitle">{{'SHARED.SHIPMENT.ITEM.MAINTENANCE.DESTINATION.CAPTION'| translate}}</div>
                <span class="multiaddress" *ngFor="let address of shipment.destinationAddresses">
                    <div class="icon" (dblclick)="onManualAddressMarkerDoubleClick(address)">
                        <i class="fa fa-map-marker"></i>
                    </div>
                    <div class="address">
                        <span class="pre-tags-strip">@</span>
                        <shared-tags-strip-component class="line tags" [uniqueString]="address.skin">
                        </shared-tags-strip-component>
                        <div class="line realaddress originRealAddress">{{address.formattedAddress}}</div>
                    </div>
                </span>
            </div>
            <shared-tags-strip-component class="line tags" [tags]="shipment.userTags">
            </shared-tags-strip-component>
            <shared-tags-strip-component class="line tags only-hardman-user internal-tags"
                [tags]="shipment.internalTags">
            </shared-tags-strip-component>
            <shared-tags-strip-component class="line tags only-hardman-user" [tags]="shipment.incidentTags">
            </shared-tags-strip-component>
            <div class="grup goods">
                <div class="titol">{{'SHARED.SHIPMENT.ITEM.MAINTENANCE.GOODS.CAPTION'| translate}}</div>
                <div class="contingut">{{shipment.goods}}</div>
            </div>
            <div class="grup comments">
                <div class="titol">{{'SHARED.SHIPMENT.ITEM.MAINTENANCE.COMMENTS.CAPTION'| translate}}</div>
                <div class="contingut">{{shipment.comments}}</div>
            </div>
            <div class="grup moments">
                <div class="titol">{{'SHARED.PHASE.ITEM.MAINTENANCE.FIRSTLASTMOMENT.CAPTION'| translate}}</div>
                <div class="contingut">
                    <table class=" hardman">
                        <tr class="origin">
                            <th rowspan="2">{{'SHARED.SHIPMENT.ITEM.MAINTENANCE.ORIGIN.CAPTION' | translate}}</th>
                            <th class="header">
                                {{'SHARED.SHIPMENT.ITEM.MAINTENANCE.ORIGINFIRSTMOMENT.CAPTION' | translate}}
                            </th>
                            <td>{{shipment.originFastConditionFirstMoment | datehournotz}}</td>
                        </tr>
                        <tr class="origin">
                            <th class="header">
                                {{'SHARED.SHIPMENT.ITEM.MAINTENANCE.ORIGINLASTMOMENT.CAPTION' | translate}}
                            </th>
                            <td>{{shipment.originFastConditionLastMoment | datehournotz}}</td>
                        </tr>
                        <tr class="destination">
                            <th rowspan="2">{{'SHARED.SHIPMENT.ITEM.MAINTENANCE.DESTINATION.CAPTION' | translate}}</th>
                            <th class="header">
                                {{'SHARED.SHIPMENT.ITEM.MAINTENANCE.DESTINATIONFIRSTMOMENT.CAPTION' | translate}}
                            </th>
                            <td>{{shipment.destinationFastConditionFirstMoment | datehournotz}}</td>
                        </tr>
                        <tr class="destination">
                            <th class="header">
                                {{'SHARED.SHIPMENT.ITEM.MAINTENANCE.DESTINATIONLASTMOMENT.CAPTION' | translate}}
                            </th>
                            <td>{{shipment.destinationFastConditionLastMoment | datehournotz}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="grup conditions">
                <div class="titol">
                    {{'SHARED.SHIPMENT.ITEM.MAINTENANCE.CONDITIONS.CAPTION'| translate}}
                </div>
                <div class="contingut">
                    <shared-shipment-item-conditions-maintenance [shipment]="shipment" [phase]="phase"
                        [routeId]="routeId" (valueChange)="onEditChanged()">
                    </shared-shipment-item-conditions-maintenance>
                </div>
            </div>
        </div>
    </div>
    <shared-shipment-item-edit-maintenance *ngIf="selectedOptionId == 'EDIT'" [shipment]="shipment" [phase]="phase"
        [routeId]="routeId" (valueChange)="onEditChanged()">
    </shared-shipment-item-edit-maintenance>
    <shared-shipment-item-form-maintenance *ngIf="selectedOptionId == 'LOADFORM'" [shipment]="shipment"
        [formShipmentType]="'LoadForm'">
    </shared-shipment-item-form-maintenance>
    <shared-shipment-item-form-maintenance *ngIf="selectedOptionId == 'UNLOADFORM'" [shipment]="shipment"
        [formShipmentType]="'UnLoadForm'">
    </shared-shipment-item-form-maintenance>
    <shared-route-item-log-maintenance *ngIf="selectedOptionId == 'LOG'" [shipment]="shipment">
    </shared-route-item-log-maintenance>
    <shared-documentaryreview-documents-component class="block" *ngIf="'DOCUMENTS'==selectedOptionId"
        [parentObject]="parentObject">
    </shared-documentaryreview-documents-component>
    <shared-chat class="chats-body-zonachat chat-without-header" *ngIf="selectedOptionId == 'CHAT'" [notes]="true"
        [forcedOwnerParticipant]="forcedOwnerParticipant" (clickParticipant)="clickParticipant($event)">
    </shared-chat>
    <shared-shipment-delivery-component *ngIf="selectedOptionId == 'DELIVERY'" [shipmentId]="shipment.shipmentId">
    </shared-shipment-delivery-component>
    <shared-shipment-cmr-maintenance *ngIf="selectedOptionId == 'CMR'" [shipmentId]="shipment.shipmentId">
    </shared-shipment-cmr-maintenance>

    <shared-shipment-multi-address-component *ngIf="selectedOptionId == 'MULTIADDRESS.ORIGIN'" [shipment]="shipment"
        (valueChange)="onEditChanged()">
    </shared-shipment-multi-address-component>

    <shared-shipment-multi-address-component *ngIf="selectedOptionId == 'MULTIADDRESS.DESTINATION'"
        [shipment]="shipment" [isOrigin]="false" (valueChange)="onEditChanged()">
    </shared-shipment-multi-address-component>

    <shared-telemetry class="maintenance-component fullheight reduced" *ngIf="selectedOptionId == 'TELEMETRY'"
        [model]="'Shipment'" [modelId]="shipmentId" [dataInici]="shipment.originDateTimeStart"
        [dataFi]="shipment.destinationDateTimeEnds" [isActiveRoutes]="true"></shared-telemetry>

    <shared-statustime-component *ngIf="selectedOptionId == 'TIMES'" [shipment]="shipment">
    </shared-statustime-component>

    <shared-eschenker-component *ngIf="selectedOptionId == 'ESCHENKER'" [shipmentId]="shipment.shipmentId">
    </shared-eschenker-component>

    <shared-package-component *ngIf="selectedOptionId == 'PACKAGES'" [shipment]="shipment"></shared-package-component>

</div>