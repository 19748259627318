import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HDate, HHour, HHourInterval, HomeController, HourIntervalsValidator, HourIntervalValidator, HourValidator, HTime, PhaseController } from '@shared/src/public-api';
import { HLong } from '@shared/src/datatypes/HLong';
import { ReserveOutOfHoursDto } from '@shared/src/dtos/address/slots/actions/ReserveOutOfHoursDto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PhaseSlotChangeReasonActionTypes } from '@shared/src/enums/PhaseSlotChangeReasonActionTypes';
import { PhaseSlotChangeReasonDataInfoDto } from '@shared/src/dtos/phase/slot/manage/reason/PhaseSlotChangeReasonDataInfoDto';

@Component({
    selector: 'shared-reserveoutofhours-box',
    templateUrl: './shared.reserveoutofhours.box.html',
    styleUrls: ['./shared.reserveoutofhours.box.scss']
})
export class SharedReserveOutOfHoursBox implements OnInit {

    public rForm: FormGroup;

    public useIonic: boolean = false;
    constructor(public fb: FormBuilder, public phaseController: PhaseController,
        public openRouteController: OpenRouteController,
        @Inject('SecurityController') public securityController: SecurityController,
        @Inject('HomeController') public homeController: HomeController) {
        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();

        this.rForm = this.fb.group({
            'date': ['', [Validators.required, Validators.nullValidator]],
            'startHour': ['', HourValidator.validate],
            'previousTimeDocumentation': ['', Validators.nullValidator],
            'previousTimeDock': ['', Validators.nullValidator],
            'timeWheelbarrowDriver': ['', Validators.nullValidator],
            'postTimeDock': ['', Validators.nullValidator],
            'postTimeDocumentation': ['', Validators.nullValidator]
        });
    }

    ngOnInit(): void {
    }


    public __phaseId: number;
    @Input()
    public set phaseId(value: number) {
        if (this.__phaseId === value)
            return;
        this.__phaseId = value;
        this.loadData();
    }
    public get phaseId(): number {
        return this.__phaseId;
    }

    public __startHour: HHour;
    public set startHour(value: HHour) {
        if (this.__startHour === value)
            return;
        this.__startHour = value;
        this.refreshIntervalsReserveObject();
    }
    public get startHour(): HHour {
        return this.__startHour;
    }

    public __previousTimeDocumentation: HTime;
    public set previousTimeDocumentation(value: HTime) {
        if (this.__previousTimeDocumentation === value)
            return;
        this.__previousTimeDocumentation = value;
        this.refreshIntervalsReserveObject();
    }
    public get previousTimeDocumentation(): HTime {
        return this.__previousTimeDocumentation;
    }
    
    public __previousTimeDock: HTime;
    public set previousTimeDock(value: HTime) {
        if (this.__previousTimeDock === value)
            return;
        this.__previousTimeDock = value;
        this.refreshIntervalsReserveObject();
    }
    public get previousTimeDock(): HTime {
        return this.__previousTimeDock;
    }

    public __timeWheelbarrowDriver: HTime;
    public set timeWheelbarrowDriver(value: HTime) {
        if (this.__timeWheelbarrowDriver === value)
            return;
        this.__timeWheelbarrowDriver = value;
        this.refreshIntervalsReserveObject();
    }
    public get timeWheelbarrowDriver(): HTime {
        return this.__timeWheelbarrowDriver;
    }
    
    public __postTimeDock: HTime;
    public set postTimeDock(value: HTime) {
        if (this.__postTimeDock === value)
            return;
        this.__postTimeDock = value;
        this.refreshIntervalsReserveObject();
    }
    public get postTimeDock(): HTime {
        return this.__postTimeDock;
    }

    public __postTimeDocumentation: HTime;
    public set postTimeDocumentation(value: HTime) {
        if (this.__postTimeDocumentation === value)
            return;
        this.__postTimeDocumentation = value;
        this.refreshIntervalsReserveObject();
    }
    public get postTimeDocumentation(): HTime {
        return this.__postTimeDocumentation;
    }
    
    public reserveObject: ReserveOutOfHoursDto;
    public refreshIntervalsReserveObject(){
        if(this.reserveObject == null)
            this.reserveObject = new ReserveOutOfHoursDto();

        if(this.startHour == null)
            return;

        this.reserveObject.previousTimeDocumentation = HTime.buildbytime(this.previousTimeDocumentation);
        this.reserveObject.previousTimeDock = HTime.buildbytime(this.previousTimeDock);
        this.reserveObject.postTimeDock = HTime.buildbytime(this.postTimeDock);
        this.reserveObject.postTimeDocumentation = HTime.buildbytime(this.postTimeDocumentation);

        let startI: HHour = this.startHour;
        
        let startDock: HHour = startI.addTime(this.previousTimeDocumentation);
        
        let startWheelbarrowDriver: HHour = startDock.addTime(this.previousTimeDock);
        let endWheelbarrowDriver: HHour = startWheelbarrowDriver.addTime(this.timeWheelbarrowDriver);

        let endDock: HHour = endWheelbarrowDriver.addTime(this.postTimeDock);

        let endI: HHour = endDock.addTime(this.postTimeDocumentation);
        
        this.reserveObject.interval = HHourInterval.build(startI, endI);
        this.reserveObject.intervalDock = HHourInterval.build(startDock, endDock);
        this.reserveObject.intervalWheelbarrowDriver = HHourInterval.build(startWheelbarrowDriver, endWheelbarrowDriver);

    }

    public valueChange(){
        this.refreshIntervalsReserveObject();
    }

    public loadData() {

        if (!HLong.isNullOrNullLong(this.phaseId)) {
            this.phaseController.getDefaultDataReserveOutOfHoursByPhaseId(this.__phaseId).subscribe(data => {
                      
                if(this.reserveObject == null)
                    this.reserveObject = new ReserveOutOfHoursDto();
                
                this.reserveObject.date = HDate.buildbydate(data.date);
                  
                this.reserveObject.interval = HHourInterval.buildByDto(data.interval);
                this.reserveObject.intervalDock = HHourInterval.buildByDto(data.intervalDock);
                this.reserveObject.intervalWheelbarrowDriver = HHourInterval.buildByDto(data.intervalWheelbarrowDriver);
                this.reserveObject.previousTimeDocumentation = HTime.buildbytime(data.previousTimeDocumentation);
                this.reserveObject.previousTimeDock = HTime.buildbytime(data.previousTimeDock);
                this.reserveObject.postTimeDock = HTime.buildbytime(data.postTimeDock);
                this.reserveObject.postTimeDocumentation = HTime.buildbytime(data.postTimeDocumentation);
                
                this.previousTimeDocumentation = this.reserveObject.previousTimeDocumentation;
                this.previousTimeDock = this.reserveObject.previousTimeDock;
                this.timeWheelbarrowDriver = HHourInterval.totalOf(this.reserveObject.intervalWheelbarrowDriver);
                this.postTimeDock = this.reserveObject.postTimeDock;
                this.postTimeDocumentation = this.reserveObject.postTimeDocumentation;

                this.__startHour = this.reserveObject.interval.getStart();

            });
        } else{
            this.reserveObject = null;
            this.startHour = null;
            this.previousTimeDocumentation = null;
            this.previousTimeDock = null;
            this.timeWheelbarrowDriver = null;
            this.postTimeDock = null;
            this.postTimeDocumentation = null;
        }
    }

    @Output() public finishReserve: EventEmitter<any> = new EventEmitter<Boolean>();
    public onReservarForaHoresClick() {
        if (HLong.isNullOrNullLong(this.phaseId)) 
            return;
        if (this.reserveObject == null || !this.reserveObject.isValid())
            return;
       
        this.phaseController.getRequiredSlotChangeReason(this.phaseId, PhaseSlotChangeReasonActionTypes.ReserveOutOfHours).subscribe((data: boolean) => {
            if (data && !this.useIonic) {
                this.homeController.openAsidePhaseSlotChangeReasonManage(this.phaseId, PhaseSlotChangeReasonDataInfoDto.buildReserveOutOfHours(this.phaseId, this.reserveObject)).then(value => {
                    this.finishReserve.next(data);
                });
            }else{
                this.openRouteController.phaseReserveOutOfHours(this.phaseId, this.reserveObject, new Array()).subscribe((data: boolean) => {
                    this.finishReserve.next(data);
                });
            }
        });

       
    }
    public isReserveOutOfHoursValid(): boolean {
        if(!this.rForm.valid)
            return false;
        if (this.reserveObject == null || !this.reserveObject.isValid())
            return false;
        return true;
    }
     
}
