<ng-container *ngIf="value">

    <div [ngClass]="generalClass">
        <actions class="bar baractions" *ngIf="!readonly" [actionstrip]="actions" (clickAction)="onClickAction($event)"></actions>

        <div class="block">
            <div class="title">
                <div class="icones">
                    <i class="fa fa-lock internal" aria-hidden="true"></i>
                    <i class="fa fa-unlock internal" aria-hidden="true"></i>
                </div>
                <span class="blocked">{{'SHARED.PHASESLOTMANAGE.BLOCKED.TITLE.BLOCKED' | translate}}</span>
                <span class="unblocked">{{'SHARED.PHASESLOTMANAGE.BLOCKED.TITLE.UNBLOCKED' | translate}}</span>
            </div>
            <div class="content">
                <tristate class="col-x lock" [(ngModel)]="value.isBlockedUser"
                                placeholder="{{'SHARED.PHASESLOTMANAGE.BLOCKED.PLACEHOLDER.INTERNAL' | translate}}" [readonly]="readonly || !value.canBlockSystem"
                                (valueChanged)="onIsBlockedUserChanged($event)">
                </tristate>
                <div class="blocked">
                    <div>
                        <div class="bodyTitle">{{'SHARED.PHASESLOTMANAGE.BLOCKED.SYSTEM' | translate}}
                            <div class="icones">
                                <i class="fa fa-lock system" aria-hidden="true"></i>
                                <i class="fa fa-unlock system" aria-hidden="true"></i>
                            </div>
                            <span class="sysblocked">{{'SHARED.PHASESLOTMANAGE.BLOCKED.TEXT.BLOCKED' | translate}}</span>
                            <span class="sysunblocked">{{'SHARED.PHASESLOTMANAGE.BLOCKED.TEXT.UNBLOCKED' | translate}}</span>
                        </div>
                        <!--<div class="bodyText">
                            <b>{{'SHARED.PHASESLOTMANAGE.BLOCKED.TEXT.BLOCKED' | translate}} </b>
                            <span [innerHTML]="value.isSlotBlockedSystemExplanation"></span>
                        </div>-->
                    </div>
                    <div>
                        <div class="bodyTitle">{{'SHARED.PHASESLOTMANAGE.BLOCKED.PARENT' | translate}}
                            <div class="icones">
                                <i class="fa fa-lock parent" aria-hidden="true"></i>
                                <i class="fa fa-unlock parent" aria-hidden="true"></i>
                            </div>
                            <span class="parblocked">{{'SHARED.PHASESLOTMANAGE.BLOCKED.TEXT.BLOCKED' | translate}}</span>
                            <span class="parunblocked">{{'SHARED.PHASESLOTMANAGE.BLOCKED.TEXT.UNBLOCKED' | translate}}</span>
                        </div>
                        <!--<div class="bodyText">
                            <b>{{'SHARED.PHASESLOTMANAGE.BLOCKED.TEXT.BLOCKED' | translate}} </b>
                            <span [innerHTML]="value.isSlotBlockedParentExplanation"></span>
                        </div>-->
                    </div>
                </div>
            </div>
            <div class="title">
                <div class="icones">
                    <i class="fa fa-lock external" aria-hidden="true"></i>
                    <i class="fa fa-unlock external" aria-hidden="true"></i>
                </div>
                <span class="blockedex">{{'SHARED.PHASESLOTMANAGE.BLOCKED.TITLE.BLOCKEDEXTERNAL' | translate}}</span>
                <span class="unblockedex">{{'SHARED.PHASESLOTMANAGE.BLOCKED.TITLE.UNBLOCKEDEXTERNAL' | translate}}</span>
                
            </div>
            <div class="content">
                <tristate class="col-x lock" [(ngModel)]="value.isBlockedExternalsUser"
                                placeholder="{{'SHARED.PHASESLOTMANAGE.BLOCKED.PLACEHOLDER.EXTERNAL' | translate}}" 
                                [readonly]="readonly || !value.canBlockExternalSystem" (valueChanged)="onIsBlockedExternalUserChanged($event)">
                </tristate>
                <div class="blocked">
                    <div>
                        <div class="bodyTitle">{{'SHARED.PHASESLOTMANAGE.BLOCKED.SYSTEM' | translate}}
                            <div class="icones">
                                <i class="fa fa-lock exsystem" aria-hidden="true"></i>
                                <i class="fa fa-unlock exsystem" aria-hidden="true"></i>
                            </div>
                            <span class="exsysblocked">{{'SHARED.PHASESLOTMANAGE.BLOCKED.TEXT.BLOCKED' | translate}}</span>
                            <span class="exsysunblocked">{{'SHARED.PHASESLOTMANAGE.BLOCKED.TEXT.UNBLOCKED' | translate}}</span>
                        </div>
                        <!--<div class="bodyText">
                            <b>{{'SHARED.PHASESLOTMANAGE.BLOCKED.TEXT.BLOCKED' | translate}} </b>
                            <span [innerHTML]="value.isSlotBlockedExternalExplanation"></span>
                        </div>-->
                    </div>
                </div>
            </div>
            <div class="availability" *ngIf='value.availability != ""'>
                <div class="title">{{'SHARED.PHASESLOTMANAGE.BLOCKED.TITLE.AVAILABILITY' | translate}}</div>
                <div class="bodyText">{{value.availability | translate}}</div>
            </div>
        </div>
    </div>
</ng-container> 
