<ng-container *ngIf="totem">
    <div class="page-title">
        {{ 'TOTEMPAGE.SIGN.TITLE' | translateIn:totem.language | async}}
    </div>
    <div class="column-container">
        <div class="column-central">

            <div *ngIf="totem.step==totemSteps.signatureBloked" class="block-signature">
                <div class="text showSignatureBlocked">
                    {{'TOTEMPAGE.SIGN.SIGNATUREBLOCKED.CAPTION'| translateIn:totem.language | async}}
                    <span class="block-signature-subtitle" *ngIf="totem.identificationSelected != null && totem.identificationSelected.isSignatureBlocked">
                        {{totem.identificationSelected.signatureBlockedReason | translateIn:totem.language | async}}
                    </span>
                </div>
            </div>

            <div *ngIf="totem.step==totemSteps.signature" class="block-signature">
                <shared-sign [uuid]="uuid" [agent]="agent" (signedChanged)="onSignedChanged()"></shared-sign>
            </div>
            <div *ngIf="totem.step==totemSteps.signatureEnd" class="block-signature">
                <div class="text goto">
                    {{'TOTEMPAGE.SIGN.SIGNATUREEND.CAPTION'| translateIn:totem.language | async}}
                </div>
            </div>
        </div>
        <div class="column-dreta">
            <div class="confirmacio-ruta">
                <button *ngIf="totem.step==totemSteps.signatureEnd && totem.identificationSelected != null && totem.identificationSelected.isExitBlocked" class="button-action" (click)="buttonOk()">
                    <i aria-hidden="true" class="fa fa-check-circle"></i>
                    <div class="code">{{ getButtonActionCode() | translateIn:totem.language | async}}
                    </div>
                    <div class="name">{{ getButtonActionName() | translateIn:totem.language | async}}
                    </div>
                </button>
                <button *ngIf="totem.step==totemSteps.signatureEnd && totem.identificationSelected != null && !totem.identificationSelected.isExitBlocked" class="button-action alternate" (click)="buttonGoToExit()">
                    <i aria-hidden="true" class="fa fa-check-circle"></i>
                    <div class="code">{{ 'TOTEMPAGE.SIGN.SIGNATUREEND.EXIT.BUTTON.CODE' | translateIn:totem.language | async}}
                    </div>
                    <div class="name">{{ 'TOTEMPAGE.SIGN.SIGNATUREEND.EXIT.BUTTON.NAME' | translateIn:totem.language | async}}
                    </div>
                </button>
            </div>
        </div>
    </div>
</ng-container>