import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { SlotChangeReasonItemMaintenanceDto } from './SlotChangeReasonItemMaintenanceDto';
import { SlotChangeReasonListMaintenanceDto } from './SlotChangeReasonListMaintenanceDto';
import { Observable } from 'rxjs';

@Injectable()
export class SlotChangeReasonMaintenanceController extends BaseMaintenanceController<SlotChangeReasonListMaintenanceDto, SlotChangeReasonItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "slotchangereason");
    }
    private base = '/maintenance/slotchangereason'

    public getResourceRoot(): string {
        return "SLOTCHANGEREASON";
    }

    public getSlotChangeReasonItemByReasonId(reasonId: number): Observable<SlotChangeReasonItemMaintenanceDto> {
        return this.internal_get(this.base + "/byreasonid/" + reasonId)
    }

    public buildUrl(uri: string): string {
        return "system/dataandparameters/slotchangereason" + uri;
    }
}