<div class="base-component" (window:resize)="onResize($event)">
  <div class="documentacio incomplerta" [innerHTML]="'ROUTE.PART.INCOMPLETDOCUMENTATION.CAPTION'| translate"
    *ngIf="isIncompletDocument">
  </div>

  <options *ngIf="addOptionMultiFirma || multidocument" class="nomenu" [optionstrip]="optionsMultiDocument" (clickOption)="onClickOptionMultiDocument($event)"></options>
  <options *ngIf="addOptionDetailFirma || documentDetailsLength > 1" class="nomenu" [optionstrip]="optionsMultiDetail" (clickOption)="onClickOptionMultiDetail($event)"></options>

  <ng-container *ngIf="!CMRAsDocument && (optionMultiDocument == null || optionMultiDocument.optionId=='CMRDigital')">

    <shared-route-part-cmr-digitaldocument-editable-component [routePartCMRId]="routePartCMRId" [readonly]="readonly">
    </shared-route-part-cmr-digitaldocument-editable-component>

  </ng-container>

  <ng-container *ngIf="documentDetail!=null">

    <shared-document-view-component class="full" [documentDetail]="documentDetail"
      [nominiatures]="true"></shared-document-view-component>

  </ng-container>


  <ng-container *ngIf="showsignatures">
    <div class="ecmr-block signatures" *ngIf="showsignatures">
      <div class="signatura-block">
        <span class="ecmr-block-title">
          {{ 'ROUTEPARTCMRSIGNATUREAGENTS.ENUM.SENDER' | translate}}
        </span>
        <shared-sign-cmr-maintenance class="nomessignagtura nofixed noborder justsignature"
          [routePartCMRId]="routePartCMRId" [type]="'sender'" [readonly]="true"></shared-sign-cmr-maintenance>
      </div>
      <div class="signatura-block">
        <span class="ecmr-block-title">
          {{ 'ROUTEPARTCMRSIGNATUREAGENTS.ENUM.CARRIER' | translate}}
        </span>
        <shared-sign-cmr-maintenance class="nomessignagtura nofixed noborder justsignature"
          [routePartCMRId]="routePartCMRId" [type]="'carrier'" [readonly]="true"></shared-sign-cmr-maintenance>
      </div>
      <div class="signatura-block">
        <span class="ecmr-block-title">
          {{ 'ROUTEPARTCMRSIGNATUREAGENTS.ENUM.CONSIGNEE' | translate}}
        </span>
        <shared-sign-cmr-maintenance class="nomessignagtura nofixed noborder justsignature"
          [routePartCMRId]="routePartCMRId" [type]="'consignee'" [readonly]="true"></shared-sign-cmr-maintenance>
      </div>
      <div class="signatura-block">
        <span class="ecmr-block-title">
          {{ 'ROUTEPARTCMRSIGNATUREAGENTS.ENUM.DRIVER' | translate}}
        </span>
        <shared-sign-cmr-maintenance class="nomessignagtura nofixed noborder justsignature"
          [routePartCMRId]="routePartCMRId" [type]="'driver'" [readonly]="true"></shared-sign-cmr-maintenance>
      </div>
    </div>
  </ng-container>
</div>