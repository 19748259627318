import { Component, Input, OnInit } from '@angular/core';
import { PhaseController } from '@shared/src/controllers/route/phase/phase.controller';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { PhaseSlotManageDto } from '@shared/src/dtos/phase/slot/manage/PhaseSlotManageDto';
import { DateFilter, HDate, HHour, HHourInterval, HTranslateService } from '@shared/src/public-api';

@Component({
  selector: 'shared-phase-slot-manage-log-component',
  templateUrl: './shared.phase.slot.manage.log.component.html',
  styleUrls: ['./shared.phase.slot.manage.log.component.scss'],
})
export class SharedPhaseSlotManageLogComponent implements OnInit {

  constructor(public phaseController: PhaseController, private translateService: HTranslateService) {
  }

  @Input() public readonly: boolean = false;

  public actions: ActionsStripDto;

  public modificarReserva: boolean = false;

  public mostrar24h: boolean = false;
 
  public objTranslate: any;


  private _value: PhaseSlotManageDto;
  @Input() set value(value: PhaseSlotManageDto) {
    if (this._value === value)
      return;
    this._value = value;
    this.welcome();

  }
  get value(): PhaseSlotManageDto {
    return this._value;
  }

  loadData(){

    
  }

  ngOnInit() {

  }



  welcome() {

    if (this.value && this.value.actionsLog != null)
      this.actions = ActionsStripDto.buildFromActionsStripDto(this.value.actionsLog);
    else
      this.actions = null;
  }
  
  onClickAction(action: ActionDto) {
    if(action == null)
      return;

    if(action.actionId == "EXPORT"){

    }
}
}
