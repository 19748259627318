import { HDate } from '../../../datatypes/HDate';
import { BaseController } from '../../base.controller';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { AddressDaySettingPlanningSlotsDto } from '@shared/src/dtos/address/planning/AddressDaySettingPlanningSlotsDto';
import { SlotActionDto } from '@shared/src/dtos/address/slots/actions/SlotActionDto';
import { SlotDetailDto } from '@shared/src/dtos/address/slots/SlotDetailDto';
import { SocketController } from '@shared/src/controllers/socket/socket.controller';
import { SlotLogDto } from '@shared/src/dtos/address/slots/SlotLogDto';
import { FilterSlotLogTableDto } from '@shared/src/dtos/address/slots/FilterSlotLogTableDto';
import { AddressDaySettingPlanningSlotsFilterDto } from '@shared/src/dtos/address/planning/AddressDaySettingPlanningSlotsFilterDto';
import { PhaseSlotDto } from '@shared/src/dtos/phase/slot/PhaseSlotDto';
import { AddressDaySettingPlanningSearchRightCtrlDto } from '@shared/src/dtos/address/planning/AddressDaySettingPlanningSearchRightCtrlDto';
import { SlotChangeReasonItemDto } from '@shared/src/dtos/slotchangereason/SlotChangeReasonItemDto';


@Injectable({
    providedIn: 'root'
})
export class SlotsController extends BaseController {
    constructor(
        protected http: HttpClient,
        protected cookieService: CookieService,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router,
        private socketController: SocketController) {
        super(http, alertService, translateService, loadingService, router);
    }

    private base = "/slot";

    public getAddressDaySettingPlanningSlots(addressId: number, day: HDate, ambitId: number, busy: boolean): Observable<AddressDaySettingPlanningSlotsDto> {
        if (busy)
            return this.getBusy(this.base + "/" + addressId + "/setting/planningslots/" + HDate.getDateStringRequest(day) + "/" + ambitId);
        return this.get(this.base + "/" + addressId + "/setting/planningslots/" + HDate.getDateStringRequest(day) + "/" + ambitId);
    }

    public searchAddressDaySettingPlanningSlotsSearch(addressId: number, ambitId: number, filter: AddressDaySettingPlanningSlotsFilterDto, busy: boolean): Observable<AddressDaySettingPlanningSearchRightCtrlDto> {
        if (busy)
            return this.postBusy(this.base + "/" + addressId + "/setting/planningslots" + "/" + ambitId + "/search", filter, "searchAddressDaySettingPlanningSlotsSearch");
        return this.post(this.base + "/" + addressId + "/setting/planningslots" + "/" + ambitId + "/search", filter, "searchAddressDaySettingPlanningSlotsSearch");
    }

    public searchAddressDaySettingPlanningSlotsSearchExactKey(addressId: number, ambitId: number, filter: AddressDaySettingPlanningSlotsFilterDto, busy: boolean): Observable<AddressDaySettingPlanningSearchRightCtrlDto> {
        if (busy)
            return this.postBusy(this.base + "/" + addressId + "/setting/planningslots" + "/" + ambitId + "/exactkey", filter, "searchAddressDaySettingPlanningSlotsSearchExactKey");
        return this.post(this.base + "/" + addressId + "/setting/planningslots" + "/" + ambitId + "/exactkey", filter, "searchAddressDaySettingPlanningSlotsSearchExactKey");
    }

    public getPhaseSlotPlanningDetail(slotId: number, phaseId: number): Observable<PhaseSlotDto> {
        return this.get(this.base + "/phaseslotplanningdetail/slotid/" + slotId + "/phaseid/" + phaseId);
    }

    public swapSlots(data: SlotActionDto, slotChangeReasons: SlotChangeReasonItemDto[], busy: boolean): Observable<boolean> {
        if (busy)
            return new Observable<any>((observer) => {
                this.socketController.pauseInterest();
                super.postBusy(this.base + "/swap", {'slotaction': data, 'slotChangeReasons': slotChangeReasons}, "swapSlots").subscribe(result => {
                    this.socketController.resumeInterest();
                    this.checkOverbookingByPhaseId(data.phaseOrigenId).subscribe(overbooking => {
                        observer.next(result && !overbooking);
                        observer.complete();
                    })
                });
            });
        return new Observable<any>((observer) => {
            this.socketController.pauseInterest();
            super.post(this.base + "/swap", {'slotaction': data, 'slotChangeReasons': slotChangeReasons}, "swapSlots").subscribe(result => {
                this.socketController.resumeInterest();
                this.checkOverbookingByPhaseId(data.phaseOrigenId).subscribe(overbooking => {
                    observer.next(result && !overbooking);
                    observer.complete();
                })
            });
        });
    }
    public moveSlots(data: SlotActionDto, slotChangeReasons: SlotChangeReasonItemDto[], busy: boolean): Observable<boolean> {
        if (busy)
            return new Observable<any>((observer) => {
                this.socketController.pauseInterest();
                super.postBusy(this.base + "/move", {'slotaction': data, 'slotChangeReasons': slotChangeReasons}, "moveSlots").subscribe(result => {
                    this.socketController.resumeInterest();
                    this.checkOverbookingByPhaseId(data.phaseOrigenId).subscribe(overbooking => {
                        observer.next(result && !overbooking);
                        observer.complete();
                    })
                });
            });
        return new Observable<any>((observer) => {
            this.socketController.pauseInterest();
            super.post(this.base + "/move", {'slotaction': data, 'slotChangeReasons': slotChangeReasons}, "moveSlots").subscribe(result => {
                this.socketController.resumeInterest();
                this.checkOverbookingByPhaseId(data.phaseOrigenId).subscribe(overbooking => {
                    observer.next(result && !overbooking);
                    observer.complete();
                })
            });
        });
    }
    public assignSlots(data: SlotActionDto, slotChangeReasons: SlotChangeReasonItemDto[], busy: boolean): Observable<boolean> {
        if (busy)
            return new Observable<any>((observer) => {
                this.socketController.pauseInterest();
                super.postBusy(this.base + "/assign", {'slotaction': data, 'slotChangeReasons': slotChangeReasons}, "assignSlots").subscribe(result => {
                    this.socketController.resumeInterest();
                    this.checkOverbookingByPhaseId(data.phaseOrigenId).subscribe(overbooking => {
                        observer.next(result && !overbooking);
                        observer.complete();
                    })
                });
            });
        return new Observable<any>((observer) => {
            this.socketController.pauseInterest();
            super.post(this.base + "/assign", {'slotaction': data, 'slotChangeReasons': slotChangeReasons}, "assignSlots").subscribe(result => {
                this.socketController.resumeInterest();
                this.checkOverbookingByPhaseId(data.phaseOrigenId).subscribe(overbooking => {
                    observer.next(result && !overbooking);
                    observer.complete();
                })
            });
        });
    }
    public obtainSlot(data: SlotActionDto, slotChangeReasons: SlotChangeReasonItemDto[], busy: boolean): Observable<boolean> {
        if (busy)
            return new Observable<any>((observer) => {
                this.socketController.pauseInterest();
                super.postBusy(this.base + "/obtain", {'slotaction': data, 'slotChangeReasons': slotChangeReasons}, "obtainSlot").subscribe(result => {
                    this.socketController.resumeInterest();
                    this.checkOverbookingByPhaseId(data.phaseOrigenId).subscribe(overbooking => {
                        observer.next(result && !overbooking);
                        observer.complete();
                    })
                });
            });
        return new Observable<any>((observer) => {
            this.socketController.pauseInterest();
            super.post(this.base + "/obtain", {'slotaction': data, 'slotChangeReasons': slotChangeReasons}, "obtainSlot").subscribe(result => {
                this.socketController.resumeInterest();
                this.checkOverbookingByPhaseId(data.phaseOrigenId).subscribe(overbooking => {
                    observer.next(result && !overbooking);
                    observer.complete();
                })
            });
        });
    }
    public releaseSlot(data: SlotActionDto, slotChangeReasons: SlotChangeReasonItemDto[], busy: boolean): Observable<boolean> {
        if (busy)
            return new Observable<any>((observer) => {
                this.socketController.pauseInterest();
                super.postBusy(this.base + "/release", {'slotaction': data, 'slotChangeReasons': slotChangeReasons}, "releaseSlot").subscribe(result => {
                    this.socketController.resumeInterest();
                    observer.next(result);
                    observer.complete();
                });
            });
        return new Observable<any>((observer) => {
            this.socketController.pauseInterest();
            super.post(this.base + "/release", {'slotaction': data, 'slotChangeReasons': slotChangeReasons}, "releaseSlot").subscribe(result => {
                this.socketController.resumeInterest();
                observer.next(result);
                observer.complete();
            });
        });
    }
    public releaseInvalidReservation(data: SlotActionDto, busy: boolean): Observable<boolean> {
        if (busy)
            return new Observable<any>((observer) => {
                this.socketController.pauseInterest();
                super.postBusy(this.base + "/releaseinvalidreservation", data, "releaseInvalidReservation").subscribe(result => {
                    this.socketController.resumeInterest();
                    observer.next(result);
                    observer.complete();
                });
            });
        return new Observable<any>((observer) => {
            this.socketController.pauseInterest();
            super.post(this.base + "/releaseinvalidreservation", data, "releaseInvalidReservation").subscribe(result => {
                this.socketController.resumeInterest();
                observer.next(result);
                observer.complete();
            });
        });
    }

    public getSlotDetail(slotId: number, phaseId: number): Observable<SlotDetailDto> {
        return this.get(this.base + "/" + slotId + "/detail/" + phaseId);
    }

    public getSlotLogsByAddressId(addressid: number, filter: FilterSlotLogTableDto): Observable<FilterSlotLogTableDto> {
        return this.post(this.base + "/logs/addressid/" + addressid, filter, "getSlotLogsByAddressIdInForce");
    }

    public getSlotLogsByPhaseId(phaseId: number): Observable<SlotLogDto[]> {
        return this.get(this.base + "/logs/phaseid/" + phaseId);
    }

    public getLastSlotLogsByPhaseId(phaseId: number): Observable<SlotLogDto> {
        return this.get(this.base + "/logs/last/phaseid/" + phaseId);
    }

    public checkOverbookingByPhaseId(phaseId: number): Observable<boolean> {
        return this.getBusy(this.base + "/overbooking/" + phaseId);
    }

}