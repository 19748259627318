import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EntitySelectorDto } from '@shared/src/dtos/base/entity-selector/EntitySelectorDto';
import { EntitySelectorItemDto } from '@shared/src/dtos/base/entity-selector/EntitySelectorItemDto';
import { AccessControlEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/AccessControlEntitySelectorDto';
import { AddressEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/AddressEntitySelectorDto';
import { AmbitEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/AmbitEntitySelectorDto';
import { BookingEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/BookingEntitySelectorDto';
import { DeviceEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/DeviceEntitySelectorDto';
import { FestivesEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/FestivesEntitySelectorDto';
import { FormEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/FormEntitySelectorDto';
import { GateEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/GateEntitySelectorDto';
import { LoadConsoleEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/LoadConsoleEntitySelectorDto';
import { ManagementModelEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/ManagementModelEntitySelectorDto';
import { MaritimeAgencyEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/MaritimeAgencyEntitySelectorDto';
import { PackageEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/PackageEntitySelectorDto';
import { PanelColumnEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/PanelColumnEntitySelectorDto';
import { PanelEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/PanelEntitySelectorDto';
import { PartnerEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/PartnerEntitySelectorDto';
import { PermissionGroupEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/PermissionGroupEntitySelectorDto';
import { PhaseByAddressEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/PhaseByAddressEntitySelectorDto';
import { PhaseEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/PhaseEntitySelectorDto';
import { PlateEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/PlateEntitySelectorDto';
import { PortAddressEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/PortAddressEntitySelectorDto';
import { QuestionEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/QuestionEntitySelectorDto';
import { RoleTypesEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/RoleTypesEntitySelectorDto';
import { RouteEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/RouteEntitySelectorDto';
import { ScopeEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/ScopeEntitySelectorDto';
import { ShipmentDeliveryLineEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/ShipmentDeliveryLineEntitySelectorDto';
import { ShipmentEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/ShipmentEntitySelectorDto';
import { TypologyEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/TypologyEntitySelectorDto';
import { UserEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/UserEntitySelectorDto';
import { EntitysSelectors } from '@shared/src/enums/EntitysSelectors';
import { Observable } from 'rxjs';
import { AlertService } from '../../../services/alert/alert.service';
import { LoadingService } from '../../../services/loading/loading.service';
import { BaseController } from '../../base.controller';
import { SecurityController } from '../../security/security.controller';
import { PlateTypologyEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/PlateTypologyEntitySelectorDto';
import { TotemSettingEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/TotemSettingEntitySelectorDto';
import { SlotChangeReasonEntitySelectorDto } from '@shared/src/dtos/base/entity-selector/entity/SlotChangeReasonEntitySelectorDto';

@Injectable({
    providedIn: 'root',
})
export class EntitySelectorController extends BaseController {
    private base = '/entityselector';

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        protected router: Router,
        @Inject('LoadingService') protected loadingService: LoadingService) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getEntitySelectorItems(entityselectorctrl: EntitySelectorDto): Observable<EntitySelectorDto> {
        return super.internal_post(entityselectorctrl.urlBack + this.base + "/items", entityselectorctrl, "getEntitySelectorItems");
    }

    public getEntitySelectorItem(entityselectorctrl: EntitySelectorDto, id: any): Observable<EntitySelectorItemDto> {
        return super.internal_post(entityselectorctrl.urlBack + this.base + "/item/objectbyid/" + id, entityselectorctrl, "getEntitySelectorItem");
    }
    public deleteEntitySelectorItem(entityselectorctrl: EntitySelectorDto, id: any): Observable<boolean> {
        return super.internal_post(entityselectorctrl.urlBack + this.base + "/delete/objectbyid", id, "deleteEntitySelectorItem");
    }

    public static buildCtrlByCtrl(entityselectordto: EntitySelectorDto): EntitySelectorDto {
        if (entityselectordto == null)
            return null;
        let result = this.buildCtrl(entityselectordto.entitySelector, entityselectordto.filterKeys);
        result.loadDataByDto(entityselectordto);
        return result;
    }

    public static buildCtrl(entityselector: EntitysSelectors, activeFilterKeys: Array<String>): EntitySelectorDto {
        if (entityselector == null)
            return null;

        switch (entityselector) {
            case EntitysSelectors.AccessControl:
                return AccessControlEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Address:
                return AddressEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Ambit:
                return AmbitEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Booking:
                return BookingEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Device:
                return DeviceEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Form:
                return FormEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Festives:
                return FestivesEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Gate:
                return GateEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Question:
                return QuestionEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.LoadConsole:
                return LoadConsoleEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.ManagementModel:
                return ManagementModelEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.MaritimeAgency:
                return MaritimeAgencyEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.PermissionGroup:
                return PermissionGroupEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Route:
                return RouteEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Scope:
                return ScopeEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Shipment:
                return ShipmentEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Partner:
                return PartnerEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Phase:
                return PhaseEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.PhaseByAddress:
                return PhaseByAddressEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.PortAddress:
                return PortAddressEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.User:
                return UserEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Panel:
                return PanelEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.PanelColumn:
                return PanelColumnEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Plate:
                return PlateEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.PlateTypology:
                return PlateTypologyEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.RoleTypes:
                return RoleTypesEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Typology:
                return TypologyEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.Package:
                return PackageEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.TotemSetting:
                return TotemSettingEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.ShipmentDeliveryLine:
                return ShipmentDeliveryLineEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.SlotChangeReason:
                return SlotChangeReasonEntitySelectorDto.new(activeFilterKeys);
            case EntitysSelectors.None:
                return null;
        }
    }
}