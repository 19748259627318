import { Injectable } from '@angular/core';
import { HDateHour } from '@shared/src/datatypes/HDateHour';
import { FileTypes } from '@shared/src/enums/FileTypes';
import { RouteDocumentTypes } from '@shared/src/enums/RouteDocumentTypes';
import { OpenDocumentDetail } from '../open/OpenDocumentDetail';

@Injectable()
export class DocumentListDto {
    public documentId: number;
    public fileName: string;
    public fileType: FileTypes;
    public createdAt: HDateHour;
    public documentType: RouteDocumentTypes;
    public documentGroup: string;
    public userId: number;
    public userDescription: string;

    public documentDetailList: OpenDocumentDetail[];
}
