<ng-container *ngIf="value && dataInfo">
    <div [ngClass]="dataInfo.action">
        <div class="block">
            <div class="title">{{getResourceKeyCaption() | translate}}</div>
            <div *ngIf="phaseSlotChangeReasonItem">
                <entity-selector class="selector-reason" [(ngModel)]="phaseSlotChangeReasonItem.slotChangeReasonId"
                    [entitySelector]="'SlotChangeReason'"
                    placeholder="{{'SHARED.SLOTCHANGEREASONMANAGE.SLOTCHANGEREASONID' | translate}}"
                    name="slotChangeReasonId" [fixedFilterKeys]="SLOTCHANGEREASON_FILTERKEYS" [readonly]="readonly"
                    (valuechangefunction)="slotChangeReasonChangedEvent($event)">
                </entity-selector>

                <textarea-component class="textarea" [readonly]="readonly"
                    [(ngModel)]="phaseSlotChangeReasonItem.comment" name="comment"
                    (ngModelChange)="changeComment($event)" placeholder="{{getResourceKeyComments() | translate}}">
                </textarea-component>

                <button (click)="doPreAction($event)" [disabled]="disabledButton"
                    class="button action right">{{getResourceButton() |
                    translate}}</button>
            </div>
        </div>

        <div class="block">
            <div class="subtitle line">{{'SHARED.PHASESLOTMANAGE.INFO.RESERVA.CAPTION' | translate}}</div>
            <div class="line">
                <div *ngIf="!value.realmentOcupaSlot">{{'ROUTEITEMCOMPONENT.NOSLOT.LABEL' | translate: objTranslate }}
                </div>
                <div *ngIf="value.realmentOcupaSlot">{{'SHARED.PHASESLOTMANAGE.INFO.RESERVEMESSAGE.CAPTION' | translate:
                    objTranslate }}</div>
                <div *ngIf="value.realmentOcupaSlot && value.mostrarInfoSlot24h" class="negreta">
                    {{'SHARED.PHASESLOTMANAGE.INFO.RESERVEMESSAGE.SUBCAPTION' | translate: objTranslate }}</div>

            </div>
        </div>

        <div class="block">
            <div class="subtitle line">{{'SHARED.SLOTCHANGEREASONMANAGE.RULES.CAPTION' | translate}}</div>
            <div *ngIf="value" class="line help"
                [innerHTML]="'SHARED.SLOTCHANGEREASONMANAGE.RULES.HELP' | translate">
            </div>
        </div>
    </div>
</ng-container>