<div class="container" [ngClass]="{'ionic': useIonic,  'selectedshipment' : selectedShipment!=null && selectedShipment.shipmentId > 0 }">
    <div class="cards list">
        <div class="heard">
            <div *ngIf="optionsShowMode" class="options">
                <options [optionstrip]="optionsShowMode" (clickOption)="onClickOption($event)"></options>
            </div>
            <shared-filter class="extrapadding" [filterstrip]="filter" (change)="filterUpdate()"></shared-filter>

            <div class="blocksearck">
                <input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text
                    [(ngModel)]="searchText" (ngModelChange)="this.searchTextUpdate.next($event)"
                    (focus)="onSearchFocus($event)" (click)="onSearchClick($event)">
                <span *ngIf="optionShowMode=='SHIPMENTS'" class="count">{{filterMetadataShipment.count}}/{{elementsCount}}</span>
                <span *ngIf="optionShowMode=='PACKAGES'" class="count">{{filterMetadataPackage.count}}/{{elementsCount}}</span>
            </div>
        </div>

        <div class="page">
            <div class="column">
                <div class="llista">
                    <div *ngIf="optionShowMode=='PACKAGES'" class="overflow">

                        <shared-trucking-package-item
                            *ngFor="let item of packagesExactKey | filterActiveRoutes:searchText2:filterMetadataPackageExactKey | orderbytruckingshipmentpackagedto:true; let i=index"
                            class="package-item"
                            [ngClass]="{'selected':selectedPackage!=null && item.packageId==selectedPackage.packageId}"
                            [package]="item"
                            [selectedRouteExpand]="selectedRouteExpand"
                            (click)="onClickPackage(item)" (refresh)="loadData(true, false)">
                        </shared-trucking-package-item>

                        <shared-trucking-package-item
                            *ngFor="let item of getPackages() | filterActiveRoutes:searchText2:filterMetadataPackage | filterRemoveRepeat:packagesExactKey:'packageId' | orderbytruckingshipmentpackagedto:true | slice:0:sliceCount; let i=index"
                            class="package-item"
                            [ngClass]="{'selected':selectedPackage!=null && item.packageId==selectedPackage.packageId}"
                            [package]="item"
                            [selectedRouteExpand]="selectedRouteExpand"
                            (click)="onClickPackage(item)" (refresh)="loadData(true, false)">
                        </shared-trucking-package-item>

                        <button class="slice-button" (click)="onSliceMore()" *ngIf="sliceCount<itemsCount">
                            {{'GENERAL.SLICE.BUTTON' | translate:{ sliceCount: sliceCount, itemsCount:itemsCount} }}
                        </button>
                    </div>

                    <div *ngIf="optionShowMode=='SHIPMENTS'" class="overflow">

                        <shared-trucking-shipment-card
                            *ngFor="let item of shipmentsExactKey | filterActiveRoutes:searchText2:filterMetadataShipmentExactKey | orderbytruckingshipmentcarddto:true; let i=index"
                            class="shipmentItem"
                            [ngClass]="{'selected':selectedShipment!=null && item.shipmentId==selectedShipment.shipmentId}"
                            [shipment]="item" [filterKeys]="filterSearchByText.filterKeys"
                            [showPackages]="selectedShipment!=null && item.shipmentId==selectedShipment.shipmentId"
                            [selectedRouteExpand]="selectedRouteExpand"
                            (click)="onClickShipment(item)" (clickBack)="onClickBack($event)">
                        </shared-trucking-shipment-card>

                        <shared-trucking-shipment-card
                            *ngFor="let item of getShipments() | filterActiveRoutes:searchText2:filterMetadataShipment | filterRemoveRepeat:shipmentsExactKey:'shipmentId' | orderbytruckingshipmentcarddto:true | slice:0:sliceCount; let i=index"
                            class="shipmentItem"
                            [ngClass]="{'selected':selectedShipment!=null && item.shipmentId==selectedShipment.shipmentId}"
                            [shipment]="item" [filterKeys]="filterSearchByText.filterKeys" 
                            [showPackages]="selectedShipment!=null && item.shipmentId==selectedShipment.shipmentId"
                            [selectedRouteExpand]="selectedRouteExpand"
                            (click)="onClickShipment(item)" (clickBack)="onClickBack($event)">
                        </shared-trucking-shipment-card>

                        <button class="slice-button" (click)="onSliceMore()" *ngIf="sliceCount<itemsCount">
                            {{'GENERAL.SLICE.BUTTON' | translate:{ sliceCount: sliceCount, itemsCount:itemsCount} }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>