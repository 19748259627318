export enum Languages {
    ca = "ca",
    es = "es",
    en = "en",
    pt = "pt",
    sv = "sv",
    fr = "fr",
    pl = "pl",
    ru = "ru",
    cs = "cs",
    lt = "lt",
    sk = "sk",
    de = "de",
    ar = "ar",
    it = "it",
    ro = "ro"

}