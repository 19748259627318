<div *ngIf="phase"
    [ngClass]="{'isSlotBlocked':phase!=null && phase.isSlotBlocked, 'incompatibleSlot':phase != null && phase.incompatibleSlot}">
    <shared-phase-item [phase]="phase" [phases]="phases" (callBack)="onRefreshPhase($event)" (refreshPhase)="onRefreshPhase($event)">
    </shared-phase-item>
    <div class="bar">
        <div class="baroptions">
            <options class="show-label" [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
        </div>
        <div class="baractions">
            <shared-state [(value)]="states" (stateChange)="onSelectorChangedState($event)" [readonly]="readonly">
            </shared-state>
            <actions *ngIf="!readonly" [actionstrip]="actions" (clickAction)="onClickAction($event)"></actions>
        </div>
    </div>
    <div class="info" *ngIf="'INFO'==selectedOptionId">
        <div class="block info">
            <button class="icon" (click)="onMarkerDoubleClick()">
                <i class="fa fa-map-marker"></i>
            </button>
            <div class="address">
                <div class="addressKey" *ngIf="phase.addressSkin && phase.addressSkin.length > 0">
                    {{phase.addressSkin}}<shared-copyclipboard [value]="phase.addressSkin"></shared-copyclipboard>
                </div>
                <div class="addressKey"
                    *ngIf="phase.showStrongAddress && phase.strongAddressSkin && phase.strongAddressSkin.length > 0">
                    {{phase.strongAddressSkin}}<shared-copyclipboard
                        [value]="phase.strongAddressSkin"></shared-copyclipboard>
                </div>
                <div class="dateSlot">
                    <shared-slot-viewer-component class="esquerra"
                        *ngIf="!phase.isChildOfGrouped && !phase.isDivided && phase.requiresSlot"
                        [phaseId]="phase.phaseId" [slotInfo]="phase.slotInfo" (callBack)="onRefreshPhase($event)">
                    </shared-slot-viewer-component>
                    <div class="dockInfo" *ngIf="phase.dockSkin != null && phase.dockSkin.length > 0"
                        [style.color]="getTextColor(phase.dockBackgroundColor)"
                        [style.background-color]="getBackColor(phase.dockBackgroundColor)">
                        {{ phase.dockSkin }}
                    </div>
                </div>
                <div class="internalIds">
                    <span>addressId:{{phase.addressId}} </span>
                    <span>strongAddressId:{{phase.strongAddressId}} </span>
                    <span>shipmentAddressId:{{phase.shipmentAddressId}} </span>
                </div>
                <div class="raddress">{{phase.addressDescriptionShort}}<shared-copyclipboard
                        [value]="phase.addressDescriptionShort"></shared-copyclipboard>
                </div>
                <div class="formattedAddress">{{phase.formattedAddress}}<shared-copyclipboard
                        [value]="phase.formattedAddress"></shared-copyclipboard></div>
            </div>
        </div>

        <div class="grup condicions">
            <div class="titol">
                {{'SHARED.SLOT.PICKER.CONDITIONS.CAPTION'| translate}}
            </div>
            <shared-route-shipment-condition class="contingut no-actors" [value]="elem"
                *ngFor="let elem of phase.conditions | sortBy:'datetimeValue'">
            </shared-route-shipment-condition>
        </div>
        <div class="grup firstlastmoment">
            <div class="titol">
                {{'SHARED.PHASE.ITEM.MAINTENANCE.FIRSTLASTMOMENT.CAPTION'| translate}}
            </div>
            <div class="contingut">
                <table class=" hardman">
                    <tr>
                        <th class="header">
                            {{'SHARED.PHASE.ITEM.MAINTENANCE.FASTCONDITIONFIRSTMOMENT.CAPTION' | translate}}
                        </th>
                        <td>{{phase.fastConditionFirstMoment | datehournotz}}</td>
                    </tr>
                    <tr>
                        <th class="header">
                            {{'SHARED.PHASE.ITEM.MAINTENANCE.FASTCONDITIONLASTMOMENT.CAPTION' | translate}}
                        </th>
                        <td>{{phase.fastConditionLastMoment | datehournotz}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="grup distanceduration">
            <div class="titol">
                {{'SHARED.SLOT.PICKER.DISTANCEDURATION.CAPTION'| translate}}
            </div>
            <div class="contingut">
                <table class="hardman" style="width:100%">
                    <tr>
                        <th class="header blank"></th>
                        <th class="header" colspan="3">{{'SHARED.SLOT.PICKER.COMPONENT.BEFORE' | translate}}</th>
                        <th class="header" colspan="3">{{'SHARED.SLOT.PICKER.COMPONENT.ORIGIN' | translate}}</th>
                    </tr>
                    <tr>
                        <th class="header"></th>
                        <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.THEORETICAL' | translate}}</th>
                        <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.REAL' | translate}}</th>
                        <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.DEVIATION' | translate}}</th>
                        <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.THEORETICAL' | translate}}</th>
                        <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.REAL' | translate}}</th>
                        <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.DEVIATION' | translate}}</th>
                    </tr>
                    <tr>
                        <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.DISTANCE' | translate}}</th>
                        <td class="right">{{phase.theoreticalDistance | price}}</td>
                        <td class="right">{{phase.realDistance| price}}</td>
                        <td class="right"></td>
                        <td class="right">{{phase.theoreticalDistanceOrigin| price}}</td>
                        <td class="right">{{phase.realDistanceOrigin| price}}</td>
                        <td class="right"></td>
                    </tr>
                    <tr>
                        <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.DURATION' | translate}}</th>
                        <td class="right">{{phase.theoreticalDuration | time}}</td>
                        <td class="right">{{phase.realDuration | time}}</td>
                        <td class="right"></td>
                        <td class="right">{{phase.theoreticalDurationOrigin | time}}</td>
                        <td class="right">{{phase.realDurationOrigin | time}}</td>
                        <td class="right"></td>
                    </tr>
                </table>
            </div>
        </div>


        <div class="grup" *ngIf="phase.verificationCode">
            <shared-secret [value]="phase.verificationCode" [slotInfo]="phase.slotInfo"></shared-secret>

            <div class="codi">
                <button class="icon verification-code" (click)="copy(phase.verificationCode)"
                    matTooltip="{{ 'PHASE.VERIFICATIONCODE.COPY.CAPTION' | translate}}">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                </button>
                <button class="icon verification-code" (click)="generateVerificationCode()"
                    matTooltip="{{ 'PHASE.VERIFICATIONCODE.GENERATE.CAPTION' | translate}}">
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                </button>
            </div>
        </div>
        <div class="grup">
            <shared-tags-strip-component class="col-x nomargin" [tags]="phase.userTags"
                placeholder="{{'ROUTE.PHASE.USERTAGS.CAPTION' | translate}}"
                [readonly]="true"></shared-tags-strip-component>
            <shared-tags-strip-component class="col-x nomargin" [tags]="phase.privateTags"
                placeholder="{{'ROUTE.PHASE.PRIVATETAGS.CAPTION' | translate}}"
                [readonly]="true"></shared-tags-strip-component>
            <tags-chips class="col-x" [(ngModel)]="phase.manualTags" name="tags"
                placeholder="{{'ROUTE.PHASE.MANUALTAGS.CAPTION' | translate}}" (ngModelChange)="tagsChange()">
            </tags-chips>
            <tags-chips class="col-x" [(ngModel)]="phase.internalTags" name="tags" [readonly]="true"
                placeholder="{{'ROUTE.PHASE.INTERNALTAGS.CAPTION' | translate}}"></tags-chips>
        </div>

        <!-- MOLLS COMPATIBLES -->
        <div class="grup">
            <div class="titol">
                {{'SHARED.PHASE.COMPATIBLEDOCKS.CAPTION'| translate}}
            </div>

            <input-component class="slim col-x" placeholder="{{'PHASE.DOCKSSYSTEM.CAPTION' | translate}}"
                autocomplete="off" [(ngModel)]="phase.docksSystem" [readonly]="true"
                (ngModelChange)="docksChangeSubject.next(true)"></input-component>

            <input-component class="slim col-x" placeholder="{{'PHASE.DOCKSUSER.CAPTION' | translate}}"
                autocomplete="off" [(ngModel)]="phase.docksUser" [readonly]="readonly"
                (ngModelChange)="docksChangeSubject.next(true)"></input-component>

            <tristate class="col-x docksUseSystemUser" [(ngModel)]="phase.docksUseSystemUser"
                placeholder="{{'PHASE.DOCKSUSESYSTEMUSER.CAPTION' | translate}}"
                (ngModelChange)="docksChangeSubject.next(true)" [readonly]="readonly">
            </tristate>
        </div>
    </div>
    <shared-slot-picker *ngIf="'SLOTS'==selectedOptionId" (valueChange)="onSlotsChanged()" (callBack)="onSlotsChanged()" [phase]="phase"
        [isActiveRoutes]="'true'">
    </shared-slot-picker>
    <shared-dock-picker *ngIf="'DOCKS'==selectedOptionId" (valueChange)="onDockChanged($event)" [phase]="phase"
        [modeByPhase]="true"></shared-dock-picker>

    <shared-phase-change-strongaddress *ngIf="'FORCEDSTRONGADDRESS'==selectedOptionId" class="component" [phase]="phase"
        (valueChanged)="onStrongAddressChanged()">
    </shared-phase-change-strongaddress>

    <shared-route-group-child-list-component *ngIf="'ROUTEGROUPSHIPMENT'==selectedOptionId" [phaseId]="phase.phaseId">
    </shared-route-group-child-list-component>

    <div class="shipments" *ngIf="'SHIPMENTS'==selectedOptionId">
        <shared-shipment-item *ngFor="let item of phase.shipments" class="shipmentItem" [shipment]="item"
            [isActiveRoutes]="'true'" (click)="selectShipment(item)"
            [ngClass]="{'selected': selectedShipment!=null && item.shipmentId==selectedShipment.shipmentId}">
        </shared-shipment-item>
    </div>
    <shared-chat class="chats-body-zonachat chat-without-header" *ngIf="'CHAT'==selectedOptionId" [notes]="true"
        [forcedOwnerParticipant]="forcedOwnerParticipant" (clickParticipant)="clickParticipant($event)">
    </shared-chat>

    <shared-phase-times-component *ngIf="'TIMES'==selectedOptionId" [phase]="phase" class="small">
    </shared-phase-times-component>
    <shared-statustime-component *ngIf="'TIMES'==selectedOptionId" [phase]="phase" class="small">
    </shared-statustime-component>

    <shared-blame-selector-component *ngIf="'CHANGEBLAME'==selectedOptionId" [phase]="phase" class="small">
    </shared-blame-selector-component>

    <shared-phase-cmr *ngIf="'CMR'==selectedOptionId" [phase]="phase">
    </shared-phase-cmr>
</div>