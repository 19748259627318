<shared-generalstatus-container class="card" *ngIf="route"
    [generalStatus]="route.generalStatus" (dragover)="dragover($event)" (drop)="dropAtZone($event)">
    <div class="internalcard" [ngClass]="{'broken': route.isBrokenForTrucking, 'trucked': route.isTrucked }">
        <div class="routeId">{{route.routeId}}</div>
        <div class="line">
            <div class="routeKey">
                <shared-copyclipboard [value]="route.routeKey">{{route.routeKey}} </shared-copyclipboard>
            </div>
        </div>

        <div class="line route-description ellipsis" *ngIf="route.line1">{{ route.line1 }}
        </div>
        <div class="line route-description ellipsis" *ngIf="route.line3">{{ route.line3 }}
        </div>

        <div class="bottom-zone">
            <shared-trucking-route-strip-card-component [route]="route"></shared-trucking-route-strip-card-component>
        </div>
    </div>
</shared-generalstatus-container>