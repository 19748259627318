import { TenantResourceListMaintenanceDto } from "./TenantResourceListMaintenanceDto";

export class TenantResourceItemMaintenanceDto extends TenantResourceListMaintenanceDto {

   public resourceCA: string;
   public resourceEN: string;
   public resourceES: string;
   public resourceSV: string;
   public resourcePT: string;
   public resourceRU: string;
   public resourceCS: string;
   public resourceLT: string;
   public resourceSK: string;
   public resourceDE: string;
   public resourceAR: string;
   public resourceIT: string;
   public resourceRO: string;

   public static getTranslation(item: TenantResourceItemMaintenanceDto, langCode: string): any {
      const key = `resource${langCode.toUpperCase()}` as keyof TenantResourceItemMaintenanceDto;
      return item[key] ?? item.resourceEN;
   }

}
