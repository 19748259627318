
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { LocalizableObjectTypes } from '@shared/src/controllers/maps/LocalizableObjectDto';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { AddressTimetableIntervalDto } from '@shared/src/dtos/address/AddressTimetableIntervalDto';
import { ChatDto } from '@shared/src/dtos/chat/ChatDto';
import { ChatsDto } from '@shared/src/dtos/chat/ChatsDto';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { StatusTransitionsDto } from '@shared/src/dtos/generalstatus/StatusTransitionsDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { OpenChangeStatusDto } from '@shared/src/dtos/open/OpenChangeStatusDto';
import { PhaseCardDto } from '@shared/src/dtos/phase/PhaseCardDto';
import { RouteCardDto } from '@shared/src/dtos/route/RouteCardDto';
import { RouteReturnInBoundOutBoundDelegatedDto } from '@shared/src/dtos/route/wizard/RouteReturnInBoundOutBoundDelegatedDto';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { GeneralStatus } from '@shared/src/enums/GeneralStatus';
import { RouteShipmentTypes } from '@shared/src/enums/RouteShipmentTypes';
import { environment } from '@shared/src/environments/environment';
import { AlertService, PhaseController } from '@shared/src/public-api';
import { MapMarcadorDto, MapPuntDto } from '@shared/src/services/map/map.service';
import { SharedAsideFactory } from '../../aside/shared.aside.factory';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';
import { RoutePartCMRDto } from '@shared/src/dtos/route/cmr/RoutePartCMRDto';
import { RouteCMRDto } from '@shared/src/dtos/route/cmr/RouteCMRDto';
import { DocumentaryReviewController } from '@shared/src/controllers/documentaryreview/documentaryreview.controller';

@Component({
    selector: 'shared-route-item-maintenance',
    templateUrl: './shared.route.item.maintenance.component.html',
    styleUrls: ['./shared.route.item.maintenance.component.scss']
})
export class SharedRouteItemMaintenanceComponent implements OnInit {

    public BASE_APP_URI = "";

    @Input() public readonly: boolean = false;
    constructor(public router: Router,
        public routeController: RouteController,
        public phaseController: PhaseController,
        public openRouteController: OpenRouteController,
        public routeCMRController: RouteCMRController,
        private documentaryReviewController: DocumentaryReviewController,
        @Inject('SecurityController') public securityController: SecurityController,
        @Inject('HomeController') protected homecontroller: HomeController,
        @Inject('SharedAsideFactory') public sharedAsideFactory: SharedAsideFactory,
        @Inject('AlertService') public alertService: AlertService) {
        this.securityController.getStoredUserDto().subscribe(data => {
            if (data)
                this.readonly = data.isReadOnly;
        });

        this.homecontroller.onConditionsUpdated$.subscribe(data => {
            if (this.route != null && data == this.route.routeId)
                this.loadData(true, false);
        });

        this.BASE_APP_URI = environment.BASE_APP_URI;
    }

    @Input() public fromActiveRoutes: boolean = false;
    @Input() public fromMaintenance: boolean = false;

    public _routeId: number;
    @Input()
    set routeId(value: number) {
        if (this._routeId === value)
            return;

        this._routeId = value;

        this.loadData(true, false);

    }
    get routeId(): number {
        return this._routeId;
    }

    public __route: RouteCardDto;
    @Input()
    set route(value: RouteCardDto) {
        if (this.__route === value)
            return;
        this.__route = value;

        if (value != null)
            this._routeId = value.routeId;

        this.loadData(false, false);

    }
    get route(): RouteCardDto {
        return this.__route;
    }

    @Output() routeChange: EventEmitter<RouteCardDto> = new EventEmitter();
    @Output() phaseCoordsChange: EventEmitter<boolean> = new EventEmitter();

    public forcedOwnerParticipant: ParticipantDto;

    public waypoints: Array<MapPuntDto>;
    public agrupationURI: string = "";

    @Output() routeUpdated: EventEmitter<RouteCardDto> = new EventEmitter();
    public loadData(loadRoute: boolean, inform: boolean) {
        if (this.routeId) {
            this.forcedOwnerParticipant = ParticipantDto.buildByParams(this.routeId, ChatParticipantTypes.Route);
            this.routeController.getRouteItemMaintenanceCtrl(this.routeId).subscribe(dataMaintenance => {
                if (dataMaintenance != null) {
                    if (dataMaintenance.options != null)
                        this.options = OptionsStripDto.buildFromOptionsStripDto(dataMaintenance.options);
                    if (dataMaintenance.actions != null)
                        this.actions = ActionsStripDto.buildFromActionsStripDto(dataMaintenance.actions);

                    this.states = dataMaintenance.states;
                    this.selectedOption = OptionsStripDto.arrange(this.options, this.selectedOption, "PHASES");

                    this.updateWaypoints();
                }
                if (loadRoute || this.fromMaintenance) {
                    this.routeController.getRoute(this.routeId).subscribe(dataRoute => {
                        this.__route = dataRoute;
                        this.validateSelectedShipment();
                        this.validateSelectedPhase();
                        this.updateWaypoints();
                        this.routeChange.next(dataRoute);
                        if (inform)
                            this.routeUpdated.next(dataRoute);

                        this.refreshDataSelectedOption();
                    });
                }else{
                    this.validateSelectedShipment();
                    this.validateSelectedPhase();
                    
                    this.refreshDataSelectedOption();
                }
            });
        }

        if (this.__route)
            this.agrupationURI = this.homecontroller.createRouteIdLinkToActiveRoutes(this.__route.routeParentGroupId);
        else
            this.agrupationURI = "";
    }


    refreshDataSelectedOption(){

        if(this.selectedOption != "DOCUMENTARYREVIEW"){
            this.showDocumentaryRevision = false;
        }
        if(this.selectedOption != "CMR"){
            this.selectRoutePartCMR(null);
        }
        if(this.selectedOption != "PHASES"){
            this.selectPhase(null);
        }
        if(this.selectedOption != "SHIPMENTS"){
            let resetshipment: boolean = true;
            if(this.selectedOption == "PHASES"){
                if(this.selectedPhase != null)
                    resetshipment = false;
            }
            if(resetshipment)
                this.selectShipment(null);
        }

        if(this.selectedOption == "CMR"){
            this.loadRouteCMR();
        }else if(this.selectedOption == "DOCUMENTARYREVIEW"){
            this.showDocumentaryRevision = true;
        }
    }

    public marcadorsExtres: Array<MapMarcadorDto> = new Array<MapMarcadorDto>();
    // Actualitza les dades per al shared-map-route
    public updateWaypoints() {
        if (!this.__route || !this.__route.phases)
            return;

        if (!this.waypoints || this.updateIsNeeded()) {
            this.waypoints = new Array<MapPuntDto>();
            this.__route.phases.forEach(p => {
                if (p.longitude && p.latitude)
                    this.waypoints.push(MapPuntDto.builder(p.longitude, p.latitude, p));
            });
            this.marcadorsExtres = new Array<MapMarcadorDto>();
            if (this.__route.lastTelemetry != null) {
                let marker = MapMarcadorDto.builder(this.__route.lastTelemetry.longitude, this.__route.lastTelemetry.latitude);
                marker.markerLabel = "";
                marker.type = LocalizableObjectTypes.route;
                this.marcadorsExtres.push(marker);
            }
        }
        if (this.__route)
            this.agrupationURI = this.homecontroller.createRouteIdLinkToActiveRoutes(this.__route.routeParentGroupId);
        else
            this.agrupationURI = "";
    };

    // Comprova si cal actualitzar els waypoints
    public updateIsNeeded(): boolean {
        let updateNeeded: boolean = false;
        if (this.waypoints) {
            if (this.route.phases.length != this.waypoints.length)
                updateNeeded = true;
            if (!updateNeeded)
                this.waypoints.forEach((e, index) => {
                    if (!this.route.phases[index] || e.phase.phaseId != this.route.phases[index].phaseId) {
                        updateNeeded = true;
                    }
                });
        }
        return updateNeeded;
    }

    validateSelectedShipment() {
        if (this.selectedShipment == null)
            return;
        if (this.route == null)
            this.selectedShipment = null;

        if (this.route != null && this.route.shipments != null) {
            let trobat = false;
            this.route.shipments.forEach(s => (trobat = (trobat || (this.selectShipment != null && s.shipmentId === this.selectedShipment.shipmentId))));
            if (!trobat) {
                this.selectedShipment = null;
                this.selectedShipmentChange.next(null);
            }
        }
    }

    private _selectedShipment: ShipmentCardDto = null;
    @Output() selectedShipmentChange = new EventEmitter<ShipmentCardDto>();
    @Input()
    public set selectedShipment(value: ShipmentCardDto) {
        if (this._selectedShipment === value)
            return;
        this._selectedShipment = value;

        if (this.route != null && this.route.shipments != null && value != null) {
            this.route.shipments.forEach((s, i) => {
                if (s.shipmentId === value.shipmentId)
                    this.route.shipments[i] = value;
            });
        }
    }
    public get selectedShipment(): ShipmentCardDto {
        return this._selectedShipment;
    }

    selectShipment(shipment: ShipmentCardDto) {
        this.selectedShipmentChange.next(shipment);
    }

    clickParticipant(participant: ParticipantDto) {
        if (participant == null)
            return;
        this.router.navigateByUrl("/chat/" + participant.participantType + "/" + participant.participantId);
    }

    public intervals: Array<AddressTimetableIntervalDto>;

    private _selectedPhase: PhaseCardDto = null;
    @Output() selectedPhaseChange = new EventEmitter<PhaseCardDto>();
    @Input()
    public set selectedPhase(value: PhaseCardDto) {
        if (this._selectedPhase === value)
            return;

        this._selectedPhase = value;
        if (this.route != null && this.route.phases != null && value != null) {
            this.route.phases.forEach((p, i) => {
                if (p.phaseId === value.phaseId)
                    this.route.phases[i] = value;
            });
        }
        this.intervals = null;
    }
    public get selectedPhase(): PhaseCardDto {
        return this._selectedPhase;
    }

    selectPhase(value: PhaseCardDto) {
        this._selectedPhase = value;
        this.selectedPhaseChange.next(value);
    }

    validateSelectedPhase() {
        if (this.selectedPhase == null)
            return;
        if (this.route == null)
            this.selectedPhase = null;

        if (this.route != null && this.route.phases != null) {
            let trobat = false;
            this.route.phases.forEach(p => (trobat = (trobat || (this.selectedPhase != null && p.phaseId === this.selectedPhase.phaseId))));
            if (!trobat) {
                this.selectedPhase = null;
                this.selectedPhaseChange.next(null);
            }
        }
    }

    @Input()
    public selectedRoutePartCMR: RoutePartCMRDto = null;

    @Output() selectedRoutePartCMRChange = new EventEmitter<RoutePartCMRDto>();
    selectRoutePartCMR(value: RoutePartCMRDto) {
        this.selectedRoutePartCMR = value;
        this.selectedRoutePartCMRChange.next(value);
    }

    public onValueChange(route: RouteCardDto) {
        this.route = route;
        this.loadData(true, true);
    }

    public onRefreshRoute(value) {
        this.loadData(true, true);
        this.selectedPhaseChange.next(this.selectedPhase);
    }

    getReloadMaintenance(actualroute: RouteCardDto, newroute: RouteCardDto): boolean {

        if (actualroute == null || newroute == null)
            return true;
        else if (actualroute.routeId != newroute.routeId || actualroute.generalStatus != newroute.generalStatus)
            return true;

        return false;
    }

    public states: StatusTransitionsDto;
    public actions: ActionsStripDto;
    public options: OptionsStripDto;

    public _selectedOption: string = "";
    @Output() selectedOptionChange: EventEmitter<String> = new EventEmitter();
    @Input()
    public set selectedOption(value: string) {
        if (this._selectedOption === value)
            return;
        this._selectedOption = value;
    }
    public get selectedOption(): string {
        return this._selectedOption;
    }

    ngOnInit(): void {

    }

    onGoParentGrouped() {
        if (this.route && this.route.isChildOfGrouped) {
            this.routeController.getRouteParentGroupIdByRouteId(this.route.routeId).subscribe(data => {
                if (data > 0)
                    this.routeId = data;
            });
        }
    }

    onClickAction(action: ActionDto) {
        if (action != null && action.actionId === "ROUTE.CALCULATETHEORETICAL")
            this.routeController.calculateTheoretical(this.route.routeId).subscribe(data => {
                this.onValueChange(data);
            });
        else if (action != null && action.actionId === "ROUTE.CREATE_AS_OBSERVER")
            this.routeController.setasobserver(this.route.routeId).subscribe(data => {
                this.onValueChange(data);
            });
        else if (action != null && action.actionId === "ROUTE.DELETE_AS_OBSERVER")
            this.routeController.unsetasobserver(this.route.routeId).subscribe(data => {
                this.onValueChange(data);
            });
        else if (action != null && action.actionId === "ROUTE.DELAYED_ROUTE")
            this.routeController.delayed(this.route.routeId).subscribe(data => {
                this.onValueChange(data);
            });
        else if (action != null && action.actionId === "ROUTE.NOT_DELAYED_ROUTE")
            this.routeController.nondelayed(this.route.routeId).subscribe(data => {
                this.onValueChange(data);
            });
        else if (action != null && action.actionId === "ROUTE.NOTIFYTODRIVER")
            this.routeController.notifyToDriver(this.route.routeId).subscribe(data => {
            });
        else if (action != null && action.actionId === "ROUTE.NOTIFYTOCARRIER")
            this.routeController.notifyToCarrier(this.route.routeId).subscribe(data => {
            });
        else if (action != null && action.actionId === "ROUTE.COMPUTEPHASES")
            this.routeController.computePhases(this.route.routeId).subscribe(data => {
                this.onValueChange(data);
            });
        else if (action != null && action.actionId === "ROUTE.COMPUTESTRONGADDRESS")
            this.routeController.computeStrongAddress(this.route.routeId).subscribe(data => {
                this.onValueChange(data);
            });
        else if (action != null && action.actionId === "ROUTE.GROUPACTUALIZEORIGINDESTINATIONMAXLASTMOMENT")
            this.routeController.groupActualizeOriginDestinationMaxLastMoment(this.route.routeId).subscribe(data => {
                this.onValueChange(data);
            });
        else if (action != null && action.actionId === "ROUTE.CALCULATEPHASECOORDS")
            this.routeController.formatAddressesAndUpdatePhasesByRouteId(this.routeId).subscribe(data => {
                if (data)
                    this.phaseCoordsChange.next(true);
            });
        else if (action != null && action.actionId === "ROUTE.COMPUTEROUTECMR")
            this.routeCMRController.recalculate(this.routeId).subscribe(data => {
                if (data && this.route){
                    this.routeCMR = data;
                }
            });
        else if (action != null && action.actionId === "ROUTE.SORTPHASES")
            this.routeController.sortPhases(this.route.routeId).subscribe(data => {
                this.onValueChange(data);
            });
        else if (action != null && action.actionId === "ROUTE.CREATENONACCEPTANCE")
            this.sharedAsideFactory.invokeCreateNonacceptance(RouteReturnInBoundOutBoundDelegatedDto.buildFromRoute(RouteShipmentTypes.Nonacceptance, this.route)).then(value => { });
        else if (action != null && action.actionId === "ROUTE.CREATERETURN")
            this.sharedAsideFactory.invokeCreateReturn(RouteReturnInBoundOutBoundDelegatedDto.buildFromRoute(RouteShipmentTypes.Return, this.route)).then(value => { });
        else if (action != null && action.actionId == "LINKREADUUID")
            this.openLink(this.route.readUuid);
        else if (action != null && action.actionId == "LINKWRITEUUID")
            this.openLink(this.route.writeUuid);
        else if (action != null && action.actionId === "ASSIGNMENT")
            this.routeController.printpdf(this.route.routeId).subscribe(data => {
                FileUpDownloadController.resultDownLoad(data);
            });
        else if (action != null)
            this.routeController.executeAction(this.routeId, action.actionId).subscribe(data => { });
    }
    openLink(uuid: string) {
        let url = this.BASE_APP_URI + "/route/uuid/" + uuid;
        this.alertService.copyToClipboard(url).subscribe(x => {
            //window.open(url, "_blank"); 
        });
    }

    onClickOption(option: OptionDto) {
        if (option != null)
            this.selectedOption = option.optionId;
        this.selectedOptionChange.next(this.selectedOption);

        if (this.selectedOption == "CHAT") {
            let chatsDto: ChatsDto = new ChatsDto();
            let chatDto: ChatDto = new ChatDto();
            chatDto.pendingMessagesRead = 0;
            chatDto.ownerParticipantType = ChatParticipantTypes.Route;
            chatDto.ownerParticipantId = this.route.routeId;
            chatsDto.chats = new Array<ChatDto>();
            chatsDto.chats.push(chatDto);
            this.homecontroller.chatsChanged$.next(chatsDto);
        }

        this.refreshDataSelectedOption();
    }

    onSelectorChangedState(state: GeneralStatus) {
        if (this.route == null)
            return;
        this.route.generalStatus = state;
        this.openRouteController.routeSetStatus(this.route.routeId, OpenChangeStatusDto.build(state, 0)).subscribe(data => {
            this.loadData(true, true);
        });
    }

    onActionDivision(value: RouteCardDto) {
        this.routeUpdated.next(value);
    }

    movePhaseUp(value: PhaseCardDto) {
        if (!value)
            return;
        this.phaseController.pujarPhase(value.phaseId).subscribe(e => {
            if (e)
                this.loadData(true, true);
        })
    }


    movePhaseDown(value: PhaseCardDto) {
        if (!value)
            return;
        this.phaseController.baixarPhase(value.phaseId).subscribe(e => {
            if (e)
                this.loadData(true, true);
        })
    }

    

    public _routeCMR: RouteCMRDto;
    @Input()
    set routeCMR(value: RouteCMRDto) {
        if (this._routeCMR === value)
            return;

        this._routeCMR = value;

    }
    get routeCMR(): RouteCMRDto {
        return this._routeCMR;
    }
     
    loadRouteCMR() {
        if(this.__route == null){
            this.routeCMR = null;
            return;
        }
        this.routeCMRController.getRouteCMR(this.__route.routeId).subscribe(data => {
            this.routeCMR = data;
        });
    }

    @Output() showDocumentaryRevisionChange: EventEmitter<boolean> = new EventEmitter();
    public __showDocumentaryRevision: boolean;
    set showDocumentaryRevision(value: boolean) {
      if (this.__showDocumentaryRevision === value)
        return;
      this.__showDocumentaryRevision = value;
      this.showDocumentaryRevisionChange.next(value);
    }
    get showDocumentaryRevision(): boolean {
      return this.__showDocumentaryRevision;
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}
