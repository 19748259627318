import { Component, Input, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { TelemetryMaintenanceController } from '@shared/src/controllers/maintenance/device/telemetry/TelemetryMaintenanceController';
import { TelemetryFilterDto } from '@shared/src/controllers/maintenance/device/telemetry/TelemetryFilterDto';
import { HDateHour } from '../../../datatypes/HDateHour';
import * as moment from 'moment';
import { MapMarcadorDto, MapPuntDto, MapRutaDto } from '@shared/src/services/map/map.service';
import { TelemetryProvider } from '@shared/src/enums/TelemetryProvider';
import { TelemetryModel } from '@shared/src/enums/TelemetryModel';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { FilterDto } from '@shared/src/components/core/maintenance/filter/FilterDtos';
import { TelemetryT } from '@shared/src/controllers/maintenance/device/telemetry/TelemetryT';
import { TelemetryD } from '@shared/src/controllers/maintenance/device/telemetry/TelemetryD';
import { MultiModelTelemetryDto } from '@shared/src/controllers/maintenance/device/telemetry/MultiModelTelemetryDto';
import { ChartDto } from '@shared/src/components/core/canvas/ChartDto';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { FilterStripDto } from '@shared/src/components/core/maintenance/filter/FilterStripDtos';
import { DeviceDto } from '@shared/src/dtos/device/DeviceDto';
import { TelemetryDto } from '@shared/src/controllers/maintenance/device/telemetry/TelemetryDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';

export default moment;


@Component({
    selector: 'shared-telemetry',
    templateUrl: './shared.telemetry.html',
    styleUrls: ['./shared.telemetry.scss']
})

export class SharedTelemetry implements OnInit {
    rForm: FormGroup;
    public filter: TelemetryFilterDto;
    public providersFilterStrip: FilterStripDto = new FilterStripDto();
    public devicesFilterStrip: FilterStripDto = new FilterStripDto();

    public activeFilterStrip: FilterStripDto = new FilterStripDto();
    public activeProviderFilterStrip: FilterStripDto = new FilterStripDto();
    public activeDeviceFilterStrip: FilterStripDto = new FilterStripDto();

    public telemetryProvider: TelemetryProvider = null;
    public devices: Array<DeviceDto> = null;

    public data: MultiModelTelemetryDto;
    public marcadors: Array<MapMarcadorDto> = new Array<MapMarcadorDto>();
    public rutes: Array<MapRutaDto>;
    public filteredListTelemetry: TelemetryT;
    public filteredListTelemetryD: TelemetryD;
    public positionChart: Array<ChartDto> = [];

    constructor(protected TelemetryMaintenanceController: TelemetryMaintenanceController,
        @Inject('SecurityController') protected securityController: SecurityController,
        protected telemetryController: TelemetryMaintenanceController,
        protected router: Router,
        protected route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.loadData();
    }

    public __model: TelemetryModel;
    @Input()
    public set model(model: TelemetryModel) {
        if (this.__model === model)
            return;
        this.__model = model;
        this.wellcome();
    }
    public get model(): TelemetryModel {
        return this.__model;
    }

    public __modelId: number;
    @Input()
    public set modelId(modelId: number) {
        if (this.__modelId === modelId)
            return;
        this.__modelId = modelId;
        this.wellcome();
    }
    public get modelId(): number {
        return this.__modelId;
    }

    @Input()
    public dataInici: HDateHour;
    @Input()
    public dataFi: HDateHour;
    @Input()
    public isActiveRoutes: boolean = false;

    public actualDateHour: HDateHour;

    public isExpanded: boolean = false;
    public onClick() {
        this.isExpanded = !this.isExpanded;
    }
    // public onExpandedClick() {
    //     this.isExpanded = false;
    // }

    private _dateStart: HDateHour = null;
    public set dateStart(value: HDateHour) {
        if (HDateHour.equals(this._dateStart, value))
            return;
        this._dateStart = value;
    }
    public get dateStart(): HDateHour {
        return this._dateStart;
    }

    private _dateEnd: HDateHour = null;
    public set dateEnd(value: HDateHour) {
        if (HDateHour.equals(this._dateEnd, value))
            return;
        this._dateEnd = value;
        this.loadData();
    }
    public get dateEnd(): HDateHour {
        return this._dateEnd;
    }

    public filterUpdate(filter: FilterDto) {
        this.filter.filterStrip.list.forEach(element => {
            if (filter.filterId == element.filterId) {
                console.log(filter.filterId, filter, filter.filterActive);
                if (filter.filterActive == true)
                    element.setActive()
                else
                    element.setInactive()
            }
        });
        this.loadData();
    }

    public selectedIndex: number;
    private _selectedTelemetry: TelemetryDto;
    public set selectedTelemetry(value: TelemetryDto) {
        if (value === this._selectedTelemetry)
            return;
        this._selectedTelemetry = value;
        if (this._selectedTelemetry != null) {
            this.marcadors = [MapMarcadorDto.builder(this.selectedTelemetry.longitude, this.selectedTelemetry.latitude)];
            this.selectedIndex = this.selectedTelemetry.indexOfList;
        } else {
            this.selectedIndex = null;
        }
    }
    public get selectedTelemetry(): TelemetryDto {
        return this._selectedTelemetry;
    }

    public generalClass: string = "";
    public wellcome() {

        this.generalClass = "";
        if (this.model != null)
            this.generalClass = this.model;
    }

    public loadingData: boolean;
    public haveData: boolean = false;
    public loadData() {
        if (this.filter == null)
            this.filter = TelemetryFilterDto.build(this.model, this.modelId, this.dateStart, this.dateEnd, null, this.telemetryProvider, this.devices);
        else
            this.filter = TelemetryFilterDto.build(this.model, this.modelId, this.dateStart, this.dateEnd, this.filter.filterStrip, this.telemetryProvider, this.devices);

        if (this.filter != null && this.filter.isValid()) {
            if (this.loadingData)
                return;
            this.loadingData = true;
            this.TelemetryMaintenanceController.getTelemetryBusy(this.filter).subscribe((data: MultiModelTelemetryDto) => {
                this.loadingData = false;
                this.filter = TelemetryFilterDto.buildByFilter(data.filter);
                if (!this.dataInici)
                    this._dateStart = this.filter.DateStart;
                else
                    this._dateStart = this.dataInici;
                if (!this.dataFi)
                    this._dateEnd = this.filter.DateEnd;
                else
                    this._dateEnd = this.dataFi;
                this.data = data;

                if (this.data.listTelemetry != null && this.data.listTelemetry.length > 0)
                    this.haveData = true;
                else
                    this.haveData = false;

                if (this.filter.filterStrip != null && this.filter.filterStrip.list != null)
                    this.activeFilterStrip.list = this.filter.filterStrip.list.filter(element => element.filterActive == true);
                this.providersFilterStrip.list = data.providers;
                this.devicesFilterStrip.list = this.buildDevicesFilterStripList();

                if (this.data.actions != null)
                    this.actions = ActionsStripDto.buildFromActionsStripDto(this.data.actions);
                this.options = OptionsStripDto.buildFromOptionsStripDto(this.data.options);

                this.buildMarcadorsMap();
                this.calculateRutes();

                this.selectedTelemetry = null;

                this.positionChart = [];
                if (this.data.listTelemetry != null)
                    this.positionChart.push(ChartDto.build('line', this.data.tablePosition).noLegend().noRatio().noAxisLabel().setTitle("telemetry"));

                if (this.data.tableGraph)
                    this.data.tableGraph.forEach(item => {
                        this.positionChart.push(ChartDto.build('line', item).noLegend().noRatio().noAxisLabel().setTitle(item.title));
                    });
            });
        }
    }

    public buildMarcadorsMap() {
        //NOMES CARREGUEM EL ULTIM MARCADOR JA QUE ES EL ACTUAL
        this.marcadors = [];
        if (this.data == null || this.data.listTelemetry == null || this.data.listTelemetry.length == 0)
            return;

        this.data.listTelemetry = this.data.listTelemetry.reverse();
        let lat = this.data.listTelemetry[0].latitude;
        let lon = this.data.listTelemetry[0].longitude;
        //this.marcadors.push(MapMarcadorDto.builder(lon, lat));
    }
    public onTableClick(element: TelemetryDto) {
        this.selectedTelemetry = element;
    }

    public calculateRutes() {
        var rutes: Array<MapPuntDto> = [];
        this.filteredListTelemetryD = null;

        if (this.data.listTelemetry && this.data.listTelemetry.length > 0)
            this.filteredListTelemetryD = TelemetryD.build(this.data.listTelemetry, 23);

        if (this.filteredListTelemetryD && this.filteredListTelemetryD.items) {
            this.filteredListTelemetryD.items.forEach(element => {
                rutes.push(MapPuntDto.builder(element.longitude, element.latitude))
            });
            this.rutes = [MapRutaDto.builderWaypoints(rutes)];
        } else {
            this.rutes = null;
        }
    }

    onChartClick($event) {
        if ($event != null) {
            if ($event.active != null) {
                if ($event.active[0] != null) {
                    if (this.data != null) {
                        if (this.data.listTelemetry != null) {
                            if (this.data.tablePosition != null && this.data.tablePosition.indexsOfTableList != null) {
                                let indexoftablelist = this.data.tablePosition.indexsOfTableList[$event.active[0]._index];
                                if (indexoftablelist != null && this.data.listTelemetry.length > indexoftablelist) {
                                    this.selectedTelemetry = this.data.listTelemetry[indexoftablelist];
                                }
                            }
                        } else {

                        }
                    }
                }
            }
        }
    }

    onMapClick(m: MapMarcadorDto) {
        if (m == null)
            return;
        this.filteredListTelemetryD.items.forEach(element => {
            if (element.longitude == m.longitude && element.latitude == m.latitude) {
                this.selectedTelemetry = element.telemetry;
                return;
            }
        });
    }

    public tableCollapsed: boolean = false;
    public mapCollapsed: boolean = false;
    onCollapsableLeftClick() {
        this.tableCollapsed = !this.tableCollapsed;
        if (this.tableCollapsed && this.mapCollapsed) {
            this.tableCollapsed = this.mapCollapsed = false;
        }
    }
    onCollapsableRightClick() {
        this.mapCollapsed = !this.mapCollapsed;
        if (this.tableCollapsed && this.mapCollapsed) {
            this.tableCollapsed = this.mapCollapsed = false;
        }
    }
    public pretty(value: any): string {
        return JSON.stringify(value);

    }

    public options: OptionsStripDto;
    public option: string = "TELEMETRY";
    onClickOption(option: OptionDto) {
        this.option = "";
        if (option != null)
            this.option = option.optionId;

        if (option != null && option.optionId === "ROUTE")
            this.wellComeDeviceRoute();
        else
            this.loadData();
    }
    public actions: ActionsStripDto;
    onClickAction(action: ActionDto) {
        if (action != null && action.actionId === "EXPORTPDF") {
            this.telemetryController.getDocumentTelemetryPdfBusy(this.filter).subscribe(data => {
                FileUpDownloadController.resultDownLoad(data);
            });
        } else if (action != null && action.actionId === "EXPORTEXCEL") {
            this.telemetryController.getDocumentTelemetryExcelBusy(this.filter).subscribe(data => {
                FileUpDownloadController.resultDownLoad(data);
            });
        } else if (action != null && action.actionId === "CALCULATEDIMENSIONS") {
            this.telemetryController.calculateDimensionsBusy(this.filter).subscribe(data => {
                this.filter.filterStrip = FilterStripDto.buildFromFilterStripDto(data);
            });
        } else if (action != null && action.actionId === "REFRESH") {
            this.loadData();
        }
    }

    buildDevicesFilterStripList(): Array<FilterDto> {
        let devicesFilterStripList: Array<FilterDto> = Array<FilterDto>();

        if (this.data && this.data.devices)
            for (let i = 0; i < this.data.devices.length; i++) {
                let d: DeviceDto = this.data.devices[i];
                let device = this.activeDeviceFilterStrip.list.filter(item => item.filterCaption == d.deviceKey && item.filterActive);
                devicesFilterStripList.push(FilterDto.build(i.toString(), d.deviceKey, d.deviceKey, i, device && device.length > 0, null));
            }

        return devicesFilterStripList;
    }

    providersFilterUpdate($event) {
        this.providersFilterStrip.list.forEach(provider => {
            if (provider.filterActive && provider.filterCaption != $event.filterCaption) {
                provider.filterActive = false;
                return;
            }
        });

        let telemetryProvider: TelemetryProvider;
        switch ($event.filterCaption) {
            case TelemetryProvider.Cargobull.toString():
                telemetryProvider = TelemetryProvider.Cargobull;
                break;
            case TelemetryProvider.Carrier.toString():
                telemetryProvider = TelemetryProvider.Carrier;
                break;
            case TelemetryProvider.HardmanT.toString():
                telemetryProvider = TelemetryProvider.HardmanT;
                break;
            case TelemetryProvider.Sensitech.toString():
                telemetryProvider = TelemetryProvider.Sensitech;
                break;
            case TelemetryProvider.HardmanTZ.toString():
                telemetryProvider = TelemetryProvider.HardmanTZ;
                break;
            case TelemetryProvider.User.toString():
                telemetryProvider = TelemetryProvider.User;
                break;
            case TelemetryProvider.Manual.toString():
                telemetryProvider = TelemetryProvider.Manual;
                break;
            default:
                telemetryProvider = TelemetryProvider.None;
                break;
        }

        console.log(telemetryProvider);

        this.telemetryProvider = $event.filterActive ? telemetryProvider : null;
        this.activeProviderFilterStrip.list = this.providersFilterStrip.list.filter(element => element.filterActive == true);
        this.loadData();
    }

    devicesFilterUpdate($event) {
        this.devicesFilterStrip.list.forEach(device => {
            if (device.filterActive && device.filterCaption != $event.filterCaption) {
                device.filterActive = false;
                return;
            }
        });

        this.devices = $event.filterActive ? this.data.devices.filter(element => element.deviceKey == $event.filterCaption) : null;
        this.activeDeviceFilterStrip.list = this.devicesFilterStrip.list.filter(element => element.filterActive == true);
        console.log("filtre devices", this.devices);
        this.loadData();
    }

    wellComeDeviceRoute() {
        this.telemetryController.getDeviceByRoute(this.modelId).subscribe(d => {
            this.deviceId = d.deviceId;
        });
    }
    deviceRouteChanged($e: any) {
        this.telemetryController.setDeviceByRoute(this.modelId, this.deviceId).subscribe(d => {
        });
    }
    public mostrarMap: boolean = false;
    selectedIndexChange($event: any) {
        this.mostrarMap = $event == 2;
    }
    public deviceId: number;
}
