import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { HString, TranslateInPipe } from '@shared/src/public-api';
import { TotemController } from '@shared/src/controllers/totem/totem.controller';
import { TotemDto } from '@shared/src/controllers/totem/TotemDto';
import { TotemSteps } from '@shared/src/controllers/totem/TotemSteps';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'shared-totem-estadins',
    templateUrl: './shared.totem.estadins.component.html',
    styleUrls: ['./shared.totem.estadins.component.scss']
})
export class SharedTotemEstaDinsComponent implements OnInit, OnDestroy {

    constructor(
        protected totemService: TotemController,
        private translateIn: TranslateInPipe,
        private sanitizer: DomSanitizer) {

    }

    public totemSteps = TotemSteps;

    ngOnInit(): void {
    }
    ngOnDestroy() {
    }

    private _totem: TotemDto;
    @Input()
    public set totem(value: TotemDto) {
        if (this._totem === value)
            return;
        this._totem = value;
        this.loadData();
    }
    public get totem(): TotemDto {
        return this._totem;
    }

    loadData() {
    }

    public marxar() {
        this.onClickAction.next("MARXAR");
    }

    @Output() onClickAction = new EventEmitter<string>();

    public getButtonActionCode(): string {
        return "TOTEMPAGE.STEP." + HString.toUpperCase(this.totem.step) + ".CANMARXAR.BUTTON.CODE";
    }

    public getButtonActionName(): string {
        return "TOTEMPAGE.STEP." + HString.toUpperCase(this.totem.step) + ".CANMARXAR.BUTTON.NAME";
    }

    public getHelp(): string {
        if (!this.totem.canMarxar)
            return "TOTEMPAGE.STEP.ESTADINS.EXITBLOCKED.HELP";
        else
            return "TOTEMPAGE.STEP.ESTADINS.CANMARXAR.HELP";
        return "";
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}


